import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { Button, CardActionArea, CardActions, CircularProgress } from "@mui/material";
import { useHistory } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Paper from "@material-ui/core/Paper";
import CircularProgressWithLabel from "Components/CircularProgressBar";
import { getColors } from "Utils/Funzioni/Grafici";
import { Line, Bar, Pie } from "react-chartjs-2";
import {
  PieIncassoAggregatoOptions,
  PieIncassoAggregatoData,
} from "../Utils/Grafici/Obiettivi/ObiettiviReparti/IncassoAggregato";

// import {
//   IncassoGiornalieroData,
//   IncassoGiornalieroOptions,
// } from "../Utils/Grafici/Obiettivi/ObiettiviReparti/IncassoGiornaliero";

// import {
//   TenderGiornalieriData,
//   TenderGiornalieriOptions,
// } from "../Utils/Grafici/Home/TenderGiornalieri";

export default function CardObiettivoReparto(props) {
  let history = useHistory();
  const [presenzeRepartiAggregatoChart, setPresenzeRepartiAggregato] =
    React.useState({});

  React.useEffect(() => {
    // let rows= [];
    // let colori=[];
    // for (const [codiceTender, informazioni] of Object.entries(tenders)){
    //     rows.push({id:codiceTender,...informazioni, incidenza:parseFloat(informazioni.Totale)/parseFloat(importoTotale)*100});
    //     colori.push();
    // }
    // PresenzeRepartiAggregatoData.labels = rows.map((tender) => { return tender.descrizione});
    // PresenzeRepartiAggregatoData.datasets[0].data = rows.map((tender) => { return tender.Totale});
    // PresenzeRepartiAggregatoData.datasets[0].backgroundColor = colori;
    // PieIncassoAggregatoData.labels = ["Pippo"];
    // PieIncassoAggregatoData.datasets[0].data = [10];
    // PieIncassoAggregatoData.datasets[0].backgroundColor = [getRandomColor()];
    setPresenzeRepartiAggregato(PieIncassoAggregatoData);
  }, []);

  return (
    <Card elevation={5}>
      <CardActionArea>
        <CardMedia>
          <Typography variant="h4" component="div" textAlign="center" color="primary">
                     Salumeria
                    </Typography>
        </CardMedia>
        <CardContent>
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
          >
            <Grid item container xl={12}>
                <Grid container item xl={12}>
                  <CircularProgressWithLabel value={10} sizePixel={200} pathColor="primary" textColor="primary"/>
                  
                </Grid>
                <Grid
                  container
                  item
                  xl={12}
                  direction="row"
                  alignItems="center"
                >
                  <Grid item xl={12}>
                    <Typography variant="h6" component="div" textAlign="end" color="primary">
                      Goal: 10.000{" "}
                    </Typography>
                  </Grid>
                  <Grid item xl={12}>
                    <Typography variant="h6" component="div" textAlign="end" color="secondary">
                      Raggiunto: 10.000
                    </Typography>
                  </Grid>
                  <Grid item xl={12}>
                    <Typography gutterBottom variant="h6" component="div" textAlign="end" color="warning">
                      Restante: 0
                    </Typography>
                  </Grid>
                </Grid>
            </Grid>
            {/* <Grid item xl={6} >
                <Line
                      data={IncassoGiornalieroData} 
                    />
            </Grid> */}
          </Grid>
        </CardContent>
      </CardActionArea>
      <CardActions></CardActions>
    </Card>
  );
}
