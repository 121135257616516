import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import React, { useState, useEffect } from "react";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import axios from "axios";

import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import { useParams } from "react-router";

import NavBar from "Components/NavBar";
import DateRangePicker from "Components/DateRangePicker";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import clsx from "clsx";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Moment from "moment";
import { extendMoment } from "moment-range";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { itIT } from "@material-ui/data-grid";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
  gridClasses,
} from "@mui/x-data-grid";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import KitchenIcon from "@mui/icons-material/Kitchen";
import HomeIcon from "@material-ui/icons/Home";
import MultipleSelectCheckmarks from "Components/CheckmarkSelect";
import Button from "@mui/material/Button";
import configData from "CONFIGURAZIONE.json";
import columnsOperatoreFasceOrario from "Utils/Datagrid/Fasce Orario/Operatore/OperatoreFasceOrario";
import { createContext, useContext } from "react";
import { LicenzaContext } from "Utils/LicenzaContext";

import {
  getSliceOrari,
  getIntervalliOrari,
  getOrarioDa,
} from "Utils/Funzioni/Tempo";
import CustomToolbar from "Utils/Datagrid/CustomToolbar";
import LoadingSpinner from "Components/LoadingSpinner";
import { da, el } from "date-fns/locale";
import { TextField } from "@material-ui/core";
// Configurazione app
var server = configData.api_end_point;
const moment = extendMoment(Moment);

// Stile Pagina
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  container: {
    margin: theme.spacing(1),
    marginTop: "10vh",
  },
  paper: {
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(2),
    },
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(1),
    },
    flexGrow: 1,
  },
  filterExpansionBar: {
    marginBottom: theme.spacing(1),
  },
  searchbar: {
    padding: theme.spacing(1),
  },
  scontriniGrid: {
    height: "80vh",
  },
  card: {
    [theme.breakpoints.up("md")]: {
      width: "20%",
    },
    [theme.breakpoints.down("md")]: {
      width: "100vw",
      height: "100%",
    },
  },
}));

function OperatoreFasceOrario() {
  // Gestione layout
  const classes = useStyles();
  const theme = useTheme();
  const desktop = useMediaQuery(theme.breakpoints.up("lg"));
  const tablet = useMediaQuery(theme.breakpoints.up("sm"));
  const mobile = useMediaQuery(theme.breakpoints.up("xs"));

  // Gestione dati da back-end
  const [idNegozi, setIdNegozi] = useState([]);
  const [giorniSettimana, setGiorniSettimana] = useState([
                                        {
                                            "chiave": "0",
                                            "valore": "Lunedì"
                                        },
                                        {
                                            "chiave": "1",
                                            "valore": "Martedì"
                                        },
                                        {
                                            "chiave": "2",
                                            "valore": "Mercoledì"
                                        },
                                        {
                                            "chiave": "3",
                                            "valore": "Giovedì"
                                        },
                                        {
                                            "chiave": "4",
                                            "valore": "Venerdì"
                                        },
                                        {
                                            "chiave": "5",
                                            "valore": "Sabato"
                                        },
                                        {
                                            "chiave": "6",
                                            "valore": "Domenica"
                                        }]);
  const [configurazionePdv, setConfigurazionePdv] = useState({});
  const [indicePerformance, setIndicePerformance] = useState(1);
  const [columns, _] = React.useState(columnsOperatoreFasceOrario);
  const [data, setData] = React.useState([]);
  const [caricamentoDati, aggiornaCaricamento] = React.useState(false);
  const [apriSpinner, setApriSpinner] = React.useState(false);
  const [precisione, setPrecisione] = React.useState("60");
  // Stati //
  const licenzaContext = useContext(LicenzaContext);
  // Storia
  const idCliente = licenzaContext.licenza.id_cliente;
  const utente = licenzaContext.licenza.utente;
  const configurazioneReport = licenzaContext.licenza.configurazione.fasceOrarioOperatore;
  console.log(configurazioneReport)
  const BreadcrumbsReference = (idCliente) => {
    return [
      {
        label: "HOME",
        href: `/${idCliente}`,
        icon: <HomeIcon fontSize="small" />,
      },
      {
        label: "Fasce Orarie",
        href: `/${idCliente}/Report/FasceOrarie`,
        icon: <AccessTimeIcon fontSize="small" />,
      },
      {
        label: "Operatore",
        href: `/${idCliente}/Report/FasceOrarie/Operatori`,
        icon: <KitchenIcon fontSize="small" />,
      },
    ];
  };
  // FILTRI
  const [filtroGiorniSettimana, setFiltroGiorniSettimana] = useState([]);
  const [filtroNegozi, setFiltroNegozi] = useState([]);
  const [intervalloGiorni, setIntervalloGiorni] = useState([
    {
      startDate: new Date().setDate(new Date().getDate() - 1),
      endDate: new Date().setDate(new Date().getDate() - 1),
      key: "selection",
    },
  ]);

  const [filtroIntervalloData, setFiltroIntervalloData] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  const [filtriModalita, setFiltriModalita] = useState([
    {
      chiave: "scontrini",
      valore: "scontrini",
    },
    {
      chiave: "totale",
      valore: "totale",
    },
    {
      chiave: "medio",
      valore: "medio",
    },
  ]);

  //  Popolamento campi di selezione
  const [intervalli, setIntervalli] = useState(() => getIntervalliOrari());
  const [informazioniRaw, setInformazioniRaw] = useState([]);
  const [visualizzaMod, setVisualizzaMod] = useState("scontrini");

  useEffect(() => {
    let negozi = [];
    axios.get(`${server}/${idCliente}/${utente}/negozi`).then((response) => {
      let configurazioni = {};
      if (response.status == 200) {
        response.data.forEach((negozio) => {
          if (configurazioneReport.negoziSelectBox.showOnlyNegozi.includes(negozio.id_negozio) || configurazioneReport.negoziSelectBox.showOnlyNegozi.length === 0){
            configurazioni[negozio.id_negozio]=negozio.configurazione
            negozi.push({
              chiave: negozio.id_negozio,
              valore: negozio.nome_negozio,
          });
          } 
        });
        setConfigurazionePdv(configurazioni)
        setIdNegozi(negozi);
        setFiltroNegozi(negozi[0].chiave);
      }
    });
  }, []);

  useEffect( () => {
    console.log(filtroNegozi)
    console.log(configurazionePdv)
    if (Object.keys(configurazionePdv).length !== 0) {
      if (!Array.isArray(filtroNegozi) || filtroNegozi.length === 0 || filtroNegozi[0] === undefined) {
        setIndicePerformance(1);
      } else if (filtroNegozi.length === 1) {
        const negozio = filtroNegozi[0];
        setIndicePerformance(configurazionePdv[negozio]["parametri"]["fasce_orarie"]["operatore"]["indice_performance"]);
      } else {
        const totalIndicePerformance = filtroNegozi.reduce((acc, negozio) => {
          return acc + configurazionePdv[negozio]["parametri"]["fasce_orarie"]["operatore"]["indice_performance"];
        }, 0);
        setIndicePerformance(totalIndicePerformance / filtroNegozi.length);
      }
    }
  }, [filtroNegozi]);

  const changeData = (data, visualizza) => {
    aggiornaCaricamento(true);
    setApriSpinner(true);
    setData([]);
    let _data_temp = data;
    let giorni = {
      dati: {},
      columns: {},
    };
    let columns = {};
    console.log(data);
    for (const [giorno, datiGiorno] of Object.entries(_data_temp)) {
      giorni["dati"][giorno] = [];
      giorni["columns"][giorno] = [
        {
          field: "operatore",
          headerName: "Operatore",
          editable: false,
          width: 170,
          headerAlign: "center",
          align: "center",
        },
      ];
      for (const [operatore, datiOperatore] of Object.entries(datiGiorno)) {
        let dict_op_intervalli = {
          operatore: operatore,
        };
        let intervalli = [];
        let counter = 1;
        for (const [intervallo, datiIntervallo] of Object.entries(
          datiOperatore
        )) {
          console.log(intervallo);
          dict_op_intervalli[
            `${intervallo}`
          ] = `${datiIntervallo["nr_scontrini"]}-${datiIntervallo["importo_totale"]}-${datiIntervallo["media_importo_scontrino"]}-${datiIntervallo["normalization_val"]}`;
          giorni["columns"][giorno][`${counter}`] = {
            field: `${intervallo}`,
            headerName: intervallo != 999 ? `${intervallo}` : "Totale",
            editable: false,
            headerAlign: "center",
            type: "number",
            align: "center",
            sortComparator: (value1, value2, params1, params2) => {
              console.log(params1, params2);
              console.log(value1, value2);
              if (params1.id === "Totale" || params2.id === "Totale") {
                return 0;
              }
              const [
                nr_scontrini_1,
                importo_totale_1,
                media_importo_scontrino_1,
                normalizzation_val_1,
              ] = value1.split("-");
              const [
                nr_scontrini_2,
                importo_totale_2,
                media_importo_scontrino_2,
                normalizzation_val_2,
              ] = value2.split("-");

              if (visualizza == "scontrini") {
                return parseInt(nr_scontrini_1) - parseInt(nr_scontrini_2);
              } else if (visualizza == "totale") {
                return (
                  parseFloat(importo_totale_1) - parseFloat(importo_totale_2)
                );
              } else if (visualizza == "medio") {
                return (
                  parseFloat(media_importo_scontrino_1) -
                  parseFloat(media_importo_scontrino_2)
                );
              }
            },
            width: (intervallo != 999) & (precisione != 30) ? 75 : 100,
            disableReorder: true,
            renderCell: (props) => {
              const { hasFocus, value } = props;
              if (value == null) {
                return <div>{value}</div>;
              } else {
                const [
                  nr_scontrini,
                  importo_totale,
                  media_importo_scontrino,
                  normalizzation_val,
                ] = value.split("-");
                return (
                  <Grid container xl={12} direction="row">
                    {visualizza == "scontrini" && nr_scontrini != 0
                      ? nr_scontrini
                      : ""}
                    {visualizza == "totale" && importo_totale != 0
                      ? `${importo_totale}`
                      : ""}
                    {visualizza == "medio" && media_importo_scontrino != 0
                      ? `${media_importo_scontrino}`
                      : ""}
                  </Grid>
                );
              }
            },
          };
          counter++;
        }
        giorni["dati"][giorno].push(dict_op_intervalli);
      }
    }
    setData(giorni);
    console.log(giorni);
    aggiornaCaricamento(false);
    setApriSpinner(false);
  };

  //"${server}/scontrini/0255/cercaPerData",
  const requestDati = () => {
    setApriSpinner(true);
    aggiornaCaricamento(true);
    setData([]);
    axios
      .post(
        `${server}/${idCliente}/scontrini/aggregati/intervalli/operatori`,
        getAllFilters()
      )
      .then(function (response) {
        console.log(response.data);

        setInformazioniRaw(response.data);
        changeData(response.data, visualizzaMod);
        setApriSpinner(false);
        aggiornaCaricamento(false);
      })
      .catch(function (error) {
        aggiornaCaricamento(false);
        console.log(error);
        setApriSpinner(false);
      });
  };

  const scaricaExcel = () => {
    aggiornaCaricamento(true);
    axios({
      url: `${server}/${idCliente}/scontrini/aggregati/intervalli/operatori/excel`, //your url
      method: "POST",
      responseType: "blob", // important
      data: getAllFilters(),
    })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        aggiornaCaricamento(false);
        link.setAttribute("download", "report.xlsx"); //or any other extension
        document.body.appendChild(link);
        link.click();
      })
      .catch(function (error) {
        aggiornaCaricamento(false);
        console.log(error);
      });
  };

  const getAllFilters = () => {
    let filter = {};
    try {
      filter = {
        dataDa: `${moment(intervalloGiorni[0].startDate).format("DD/MM/YYYY")}`,
        dataA: `${moment(intervalloGiorni[0].endDate).format("DD/MM/YYYY")}`,
        puntiVendita: filtroNegozi,
        precisione: precisione,
        giorniSettimana: filtroGiorniSettimana,
        indicePerformance: indicePerformance
      };
    } catch {}
    console.log(filter);
    return filter;
  };

  const OperatoreFasceOrarioRendered = () => {
    if (desktop || tablet)
      return (
        <div className={classes.root}>
          <LoadingSpinner open={caricamentoDati} />
          <NavBar
            idCliente={idCliente}
            breadcrumbsReference={BreadcrumbsReference(idCliente)}
          />
          <Container className={classes.container} maxWidth="xl">
            <Accordion style={{ marginTop: "4vh" }} defaultExpanded>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography variant="h6">Barra di Ricerca</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Paper
                  elevation={6}
                  style={{
                    padding: 11,
                    margin: "auto",
                    marginTop: 13,
                    marginBottom: 13,
                    width: "100%",
                  }}
                >
                  <Grid
                    container
                    direction="row"
                    className={classes.searchbar}
                    spacing={1}
                  >
                    <Grid item xl={4} md={4} direction="row">
                      <DateRangePicker
                        state={intervalloGiorni}
                        setState={setIntervalloGiorni}
                      />
                    </Grid>
                    <Grid container item xl={8} md={8}>
                      <Grid item xl={6} md={6}>
                        <MultipleSelectCheckmarks
                          label={"Selezione Negozio"}
                          selezioneMultipla={configurazioneReport.negoziSelectBox.selezioneMultipla}
                          items={idNegozi}
                          setState={setFiltroNegozi}
                        />
                      </Grid>
                      <Grid item xl={6} md={6}>
                        <MultipleSelectCheckmarks
                        label={"Giorno della Settimana"}
                        selezioneMultipla={configurazioneReport.giornoSettimanaSelectBox.selezioneMultipla}
                          items={giorniSettimana}
                          setState={setFiltroGiorniSettimana}
                        />
                      </Grid>
                      <Grid item xl={3} md={3}>
                        <FormControl>
                          <FormLabel id="demo-radio-buttons-group-label">
                            Visualizza
                          </FormLabel>
                          <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            defaultValue="scontrini"
                            name="radio-buttons-group"
                            value={visualizzaMod}
                            onChange={(event) => {
                              setVisualizzaMod(event.target.value);
                              changeData(informazioniRaw, event.target.value);
                            }}
                          >
                            <FormControlLabel
                              value="scontrini"
                              control={<Radio />}
                              label="Nr. Scontrini"
                            />
                            <FormControlLabel
                              value="totale"
                              control={<Radio />}
                              label="Importo Totale"
                            />
                            <FormControlLabel
                              value="medio"
                              control={<Radio />}
                              label="Scontrino Medio"
                            />
                          </RadioGroup>
                        </FormControl>
                      </Grid>
                      <Grid item xl={3} md={3}>
                        <TextField placeholder={indicePerformance} 
                        id="outlined-basic"
                        label="Indice Performance"
                        variant="outlined"
                        disabled={!configurazioneReport.indicePerformanceTextField.attivo}
                        inputProps={{ type: "number" }}
                        value={indicePerformance}
                        onChange={(event) => {
                          setIndicePerformance(event.target.value);
                        }}></TextField>
                      </Grid>
                      <Grid item xl={3} md={3}>
                        <FormControl>
                          <FormLabel id="demo-radio-buttons-group-label">
                            Intervallo
                          </FormLabel>
                          <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            defaultValue="60"
                            name="radio-buttons-group"
                            value={precisione}
                            onChange={(event) => {
                              setPrecisione(event.target.value);
                            }}
                          >
                            <FormControlLabel
                              value="60"
                              control={<Radio />}
                              label="60 minuti"
                            />
                            <FormControlLabel
                              value="30"
                              control={<Radio />}
                              label="30 minuti"
                            />
                          </RadioGroup>
                        </FormControl>
                      </Grid>
                      <Grid
                        container
                        item
                        xl={12}
                        md={12}
                        justifyContent="center"
                      >
                        <Button
                          fullWidth
                          variant="contained"
                          onClick={() => {
                            requestDati();
                          }}
                          disabled={!configurazioneReport.visualizzaReportButton.attivo}
                        >
                          {configurazioneReport.visualizzaReportButton.buttonLabel}
                        </Button>
                        <Grid
                          container
                          item
                          xl={12}
                          md={12}
                          justifyContent="center"
                          spacing={2}
                          style={{ marginTop: 2 }}
                        >
                          <Grid item xl={12} md={12} justifyContent="center">
                            <Button
                              fullWidth
                              variant="contained"
                              onClick={() => {
                                scaricaExcel();
                              }}
                              style={{
                                margin: "auto",
                              }}
                              disabled={!configurazioneReport.visualizzaReportButton.attivo}
                              >
                                {configurazioneReport.scaricaExcelButton.buttonLabel}
                            </Button>
                          </Grid>
                          <Grid
                            item
                            xl={6}
                            md={6}
                            justifyContent="center"
                          ></Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Paper>
              </AccordionDetails>
            </Accordion>
            <Paper>
              {data.length != 0 ? (
                Object.entries(data["dati"]).map((datiGiorno, index) => {
                  const datiTabellaGiorno = datiGiorno[1];
                  const colonneGiorno = data["columns"][datiGiorno[0]];
                  // if (!filtroReparti.includes(reparto[0].cod_reparto)){return (<div></div>)};
                  return (
                    <Accordion style={{ marginTop: "4vh" }} defaultExpanded>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography variant="h6">{datiGiorno[0]}</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Paper
                          elevation={6}
                          style={{
                            padding: 11,
                            margin: "auto",
                            marginTop: 13,
                            marginBottom: 13,
                            width: "100%",
                          }}
                        >
                          <Box
                            sx={{
                              "& .x": {
                                backgroundColor: "#e65100",
                                color: "#1a3e72",
                              },
                              "& .ml": {
                                backgroundColor: "#f57c00",
                                color: "#1a3e72",
                              },
                              "& .l": {
                                backgroundColor: "#ff9800",
                                color: "#1a3e72",
                              },
                              "& .md": {
                                backgroundColor: "#ffb74d",
                                color: "#1a3e72",
                              },
                              "& .down": {
                                backgroundColor: "#ffe0b2",
                                color: "#1a3e72",
                              },
                            }}
                          >
                            <DataGrid
                              style={{ flexGrow: 1, height: "58vh" }}
                              density="compact"
                              getRowId={(row) => row.operatore}
                              rows={datiTabellaGiorno}
                              columns={colonneGiorno}
                              disableSelectionOnClick
                              localeText={itIT.props.MuiDataGrid.localeText}
                              loading={caricamentoDati}
                              disableColumnMenu
                              disableReorder
                              disableColumnFilter
                              disableColumnSelector
                              getCellClassName={(params) => {
                                if (
                                  params.field === "operatore" ||
                                  params.field === "999" ||
                                  params.value === "0-0-0-0"
                                ) {
                                  return "";
                                }
                                if (params.value == null) {
                                  return "";
                                }

                                const [
                                  nr_scontrini,
                                  importo_totale,
                                  media_importo_scontrino,
                                  normalization_val,
                                ] = params.value.split("-");

                                if (normalization_val <= 0.2) {
                                  return "down";
                                } else if (normalization_val <= 0.4) {
                                  return "md";
                                } else if (normalization_val <= 0.6) {
                                  return "l";
                                } else if (normalization_val <= 0.8) {
                                  return "ml";
                                } else if (normalization_val < 1) {
                                  return "x";
                                }
                              }}
                            />
                          </Box>
                        </Paper>
                      </AccordionDetails>
                    </Accordion>
                  );
                })
              ) : (
                <Paper
                  elevation={6}
                  style={{
                    padding: 11,
                    margin: "auto",
                    marginTop: 13,
                    marginBottom: 13,
                    width: "100%",
                    justifyItems: "center",
                  }}
                >
                  <Typography variant="h6" style={{ margin: "auto" }}>
                    {" "}
                    Nessun record{" "}
                  </Typography>
                </Paper>
              )}
            </Paper>
          </Container>
        </div>
      );
    if (tablet || mobile) return <div className={classes.root}> </div>;
  };

  return <> {OperatoreFasceOrarioRendered()} </>;
}

export default OperatoreFasceOrario;
