export let TenderGiornalieriData = {
    datasets: [],
  };
  var delayed;
export let TenderGiornalieriOptions = {
  responsive: true, 
  maintainAspectRatio: false,  
    scales: {
        xAxes: [{
          stacked: true,
          ticks: {
            beginAtZero: true,
            maxRotation: 0,
            minRotation: 0
          }
        }],
        yAxes: [{
          stacked: true,
        }]
      },
  animation: {
    onComplete: () => {
      delayed = true;
    },
    delay: (context) => {
      let delay = 0;
      if (context.type === 'data' && context.mode === 'default' && !delayed) {
        delay = context.dataIndex * 100 + context.datasetIndex * 50;
      }
      return delay;
    },
  },
}
