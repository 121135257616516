const columnsDettaglioIncassoGiornaliero = [
    
    {
      field: 'negozio',
      headerName: 'Negozio',
      width: 150,
      editable: false,
      headerAlign: 'center',
      align: 'center'
    },
    {
      field: 'importo',
      headerName: 'Totale',
      width: 130,
      editable: false,
      type:"number",
      headerAlign: 'center',
      align: 'right',
      valueFormatter: ({ value }) => Number(value).toLocaleString("it-IT", {minimumFractionDigits: 2}),
    },
    {
      field: 'importo_medio_scontrino',
      headerName: 'Scontrino Medio',
      width: 200,
      editable: false,
      type:"number",
      headerAlign: 'center',
      align: 'right',
      valueFormatter: ({ value }) => Number(value).toLocaleString("it-IT", {minimumFractionDigits: 2,maximumFractionDigits: 2}),
    },
    {
      field: 'importo_fidelity',
      headerName: 'Totale Fidelity',
      width: 200,
      editable: false,
      type:"number",
      headerAlign: 'center',
      align: 'right',
      valueFormatter: ({ value }) => Number(value).toLocaleString("it-IT", {minimumFractionDigits: 2}),
    },
    {
      field: 'importo_nonfidelity',
      headerName: 'Totale NON Fidelity',
      width: 200,
      editable: false,
      type:"number",
      headerAlign: 'center',
      align: 'right',
      valueFormatter: ({ value }) => Number(value).toLocaleString("it-IT", {minimumFractionDigits: 2}),
    },
    {
      field: 'nr_scontrini',
      headerName: 'Scontrini',
      width: 200,
      editable: false,
      type:"number",
      valueFormatter: ({ value }) => parseInt(value),
      headerAlign: 'center',
      align: 'right'
    },
    {
      field: 'nr_scontrini_fidelity',
      headerName: 'Scontrini Fidelity',
      width: 200,
      editable: false,
      type:"number",
      valueFormatter: ({ value }) => parseInt(value),
      headerAlign: 'center',
      align: 'right'
    },
  ];



export default columnsDettaglioIncassoGiornaliero