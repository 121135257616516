import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";

import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import HomeIcon from "@material-ui/icons/Home";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { useHistory } from "react-router-dom";
import { createBrowserHistory } from "history";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import Collapse from "@material-ui/core/Collapse";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import EuroIcon from "@material-ui/icons/Euro";
import PieChartIcon from "@material-ui/icons/PieChart";
import CustomizedBreadcrumbs from "./BreadCrumbs.js";
import KitchenIcon from "@mui/icons-material/Kitchen";
import Tooltip from "@material-ui/core/Tooltip";
import ContactSupportIcon from "@mui/icons-material/ContactSupport";
import Diversity1Icon from "@mui/icons-material/Diversity1";
import WarningIcon from "@mui/icons-material/Warning";
import PointOfSaleIcon from "@mui/icons-material/PointOfSale";
import Diversity3Icon from "@mui/icons-material/Diversity3";
import SettingsIcon from "@mui/icons-material/Settings";
import PersonSearchIcon from "@mui/icons-material/PersonSearch";
import TravelExploreIcon from '@mui/icons-material/TravelExplore';
import RoomIcon from '@mui/icons-material/Room';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import ExtensionIcon from '@mui/icons-material/Extension';
import { createContext, useContext } from 'react';
import { LicenzaContext } from '../Utils/LicenzaContext';
import MyDialog from "./MyDialog.js"

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    backgroundColor: '#004d40',
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: '#004d40',
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

const routes = {
  Home: "/",
  Scontrini: "/Scontrini",
  Users: "/users",
};

function NavBar(props) {
  const classes = useStyles();
  const theme = useTheme();
  const licenzaContext = useContext(LicenzaContext);
  const [open, setOpen] = React.useState(false);
  const [openFinanziario, setOpenFinanziario] = React.useState(false);
  const [openFasceOrarie, setOpenFasceOrarie] = React.useState(false);
  const [openFidelity, setOpenFidelity] = React.useState(false);
  const [openIncassi, setOpenIncassi] = React.useState(false);
  const [openOperatori, setOpenOperatori] = React.useState(false);
  const [openGeoPos, setOpenGeoPos] = React.useState(false);
  const [openObiettivi, setOpenObiettivi] = React.useState(false);
  const [homeButtonSelected, SetHomeButtonSelected] = React.useState(false);
  const [scontriniButtonSelected, SetScontriniButtonSelected] =
    React.useState(false);
  const [fasceOrarieButtonSelected, SetFasceOrarieButtonSelected] =
    React.useState(false);

  const [dialogModuloDisattivato, setDialogModuloDisattivato] = React.useState(false);
  const [messaggioModuloDisattivato, setMessaggioModuloDisattivato] = React.useState("");

  const [openArticoli, setOpenArticoli] = React.useState(false);
    
  const [InfoLabel, setInfoLabel] = React.useState("");
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const colorMenu = {
    "Home": "#e1f5fe",
    "Scontrini": "#311b92",
    "Incassi": "#1a237e",
    "Fasce Orarie": "#006064",
    "Finanziario": "#004d40",
    "Reparti": "#1b5e20",
    "Fidelity": "#33691e",
    "GeoPos": "#f57f17",
    "Obiettivi": "#0277bd",
    "Articoli": "#01579b",
    "Operatore": "#01579b",
    "Reparto": "#01579b",
  }

  let history = useHistory();

  const redirectHome = () => {
    history.push(`/${props.idCliente}`);
  };

  const redirectIncassi = () => {
    if (!licenzaContext.licenza.moduli.Incassi) {
      setDialogModuloDisattivato(true);
      setMessaggioModuloDisattivato("Il modulo Incassi non è attivo. \n Contattare l'assistenza.");
    }
    else{
      history.push(`/${props.idCliente}/Incassi`);
    }
  };

  const redirectScontrini = () => {
    if (!licenzaContext.licenza.moduli.Scontrini) {
      setDialogModuloDisattivato(true);
      setMessaggioModuloDisattivato("Il modulo Incassi non è attivo. \n Contattare l'assistenza.");
    }
    else{
      history.push(`/${props.idCliente}/Scontrini`);
    }
  };

  const redirectReportFasceOrarie = () => {
    if (!licenzaContext.licenza.moduli.FasceOrarie) {
      setDialogModuloDisattivato(true);
      setMessaggioModuloDisattivato("Il modulo Fasce Orarie non è attivo. \n Contattare l'assistenza.");
    }
    else{
      history.push(`/${props.idCliente}/Report/FasceOrarie`);
    }
  };

  const redirectReportFasceOrarieReparto = () => {
    if (!licenzaContext.licenza.moduli.FasceOrarie) {
      setDialogModuloDisattivato(true);
      setMessaggioModuloDisattivato("Il modulo Fasce Orarie non è attivo. \n Contattare l'assistenza.");
    }
    else{
      history.push(`/${props.idCliente}/Report/FasceOrarie/Reparti`);
    }
  };

  const redirectReportFasceOrarieOperatore = () => {
    if (!licenzaContext.licenza.moduli.FasceOrarie) {
      setDialogModuloDisattivato(true);
      setMessaggioModuloDisattivato("Il modulo Fasce Orarie non è attivo. \n Contattare l'assistenza.");
    }
    else{
      history.push(`/${props.idCliente}/Report/FasceOrarie/Operatori`);
    }
  };

  const redirectFinanziarioIva = () => {
    if (!licenzaContext.licenza.moduli.Finanziario) {
      setDialogModuloDisattivato(true);
      setMessaggioModuloDisattivato("Il modulo Finanziario non è attivo. \n Contattare l'assistenza.");
    }
    else{
      history.push(`/${props.idCliente}/Report/Finanziario/Iva`);
    }
  };

  const redirectFinanziarioTenderPagamento = () => {
    if (!licenzaContext.licenza.moduli.Finanziario) {
      setDialogModuloDisattivato(true);
      setMessaggioModuloDisattivato("Il modulo Finanziario non è attivo. \n Contattare l'assistenza.");
    }
    else{
      history.push(`/${props.idCliente}/Report/Finanziario/TenderPagamento`);
    }
  };

  const redirectReparti = () => {
    if (!licenzaContext.licenza.moduli.Reparti) {
      setDialogModuloDisattivato(true);
      setMessaggioModuloDisattivato("Il modulo Reparti non è attivo. \n Contattare l'assistenza.");
    }
    else{
      history.push(`/${props.idCliente}/Report/Reparti`);
    }
  };

  const redirectFidelityAnomalie = () => {
    if (!licenzaContext.licenza.moduli.Fidelity) {
      setDialogModuloDisattivato(true);
      setMessaggioModuloDisattivato("Il modulo Fidelity non è attivo. \n Contattare l'assistenza.");
    }
    else{
      history.push(`/${props.idCliente}/Report/Fidelity/Anomalie`);
    }
  };

  const redirectIncassiOperatore = () => {
    if (!licenzaContext.licenza.moduli.Operatori) {
      setDialogModuloDisattivato(true);
      setMessaggioModuloDisattivato("Il modulo Operatore non è attivo. \n Contattare l'assistenza.");
    }
    else{
      history.push(`/${props.idCliente}/Operatori/Incassi`);
    }
  };

  const redirectImpostazioniOperatore = () => {
    if (!licenzaContext.licenza.moduli.Operatori) {
      setDialogModuloDisattivato(true);
      setMessaggioModuloDisattivato("Il modulo Operatore non è attivo. \n Contattare l'assistenza.");
    }
    else{
      history.push(`/${props.idCliente}/Operatori/Impostazioni`);
    }
  };

  const redirectGeoPosPresenze = () => {
    if (!licenzaContext.licenza.moduli.GeoPosizione) {
      setDialogModuloDisattivato(true);
      setMessaggioModuloDisattivato("Il modulo Geo Posizione non è attivo. \n Contattare l'assistenza.");
    }
    else{
      history.push(`/${props.idCliente}/GeoPos/Presenze`);
    }
  };

  const redirectObiettiviSettori = () => {
    if (!licenzaContext.licenza.moduli.Obiettivi) {
      setDialogModuloDisattivato(true);
      setMessaggioModuloDisattivato("Il modulo Obiettivi non è attivo. \n Contattare l'assistenza.");
    }
    else{
      history.push(`/${props.idCliente}/Report/Obiettivi/Settori`);
    }
  };

  const redirectArticoliVendutoGiornaliero = () => {
    if (!licenzaContext.licenza.moduli.Articoli) {
      setDialogModuloDisattivato(true);
      setMessaggioModuloDisattivato("Il modulo Articoli non è attivo. \n Contattare l'assistenza.");
    }
    else{
      history.push(`/${props.idCliente}/Report/Articoli/VendutoGiornaliero`);
    }
  };

  const redirectArticoliVendutoGiornalieroOperatore = () => {
    if (!licenzaContext.licenza.moduli.Articoli) {
      setDialogModuloDisattivato(true);
      setMessaggioModuloDisattivato("Il modulo Articoli non è attivo. \n Contattare l'assistenza.");
    }
    else{
      history.push(`/${props.idCliente}/Report/Articoli/VendutoGiornaliero/Operatore`);
    }
  };

  const redirectArticoliVendutoGiornalieroOperatoreScontrino = () => {
    if (!licenzaContext.licenza.moduli.Articoli) {
      setDialogModuloDisattivato(true);
      setMessaggioModuloDisattivato("Il modulo Articoli non è attivo. \n Contattare l'assistenza.");
    }
    else{
      history.push(`/${props.idCliente}/Report/Articoli/VendutoGiornaliero/Operatore/Scontrino`);
    }
  };

  return (
    <div className={classes.root}>
      <MyDialog open={dialogModuloDisattivato} message={messaggioModuloDisattivato} onClose={() => setDialogModuloDisattivato(false)}/>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar style={{ width: "100vw", backgroundColor: '#004d40'}}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, {
              [classes.hide]: open,
            })}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h4">MyPosWeb</Typography>
          <Typography variant="h8">by SistemiPos</Typography>
          <div style={{ width: "100vw" }}>
            <CustomizedBreadcrumbs items={props.breadcrumbsReference} />
          </div>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <div className={classes.toolbar}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon style={{color: "white"}}/>
            ) : (
              <ChevronLeftIcon style={{color: "white"}}/>
            )}
          </IconButton>
        </div>
        <Divider />
        <List>
          <Tooltip title="Home">
            <ListItem
              selected={homeButtonSelected}
              button
              key={"Home"}
              onClick={redirectHome}
            >
              <ListItemIcon>
                <HomeIcon style={{color: "white"}}/>
              </ListItemIcon>
              <ListItemText primary={"Home"} style={{color: "white"}}/>
            </ListItem>
          </Tooltip>
          <Divider variant="middle" component="li"/>
          <Tooltip title="Incassi">
            <ListItem button key={"Incassi"} onClick={redirectIncassi}>
              <ListItemIcon>
                <PointOfSaleIcon style={{color: "white"}}/>
              </ListItemIcon>
              <ListItemText primary={"Incassi"} style={{color: "white"}}/>
            </ListItem>
          </Tooltip>
          <Divider variant="middle" component="li"/>
          <Tooltip title="Scontrini">
            <ListItem
              selected={scontriniButtonSelected}
              button
              key={"Scontrini"}
              onClick={redirectScontrini}
            >
              <ListItemIcon>
                <ReceiptLongIcon style={{color: "white"}}/>
              </ListItemIcon>
              <ListItemText primary={"Scontrini"} style={{color: "white"}} />
            </ListItem>
          </Tooltip>
          <Divider variant="middle" component="li"/>
          <Tooltip title="Operatori" >
            <ListItem
              button
              key={"Operatori"}
              onClick={() => setOpenOperatori(!openOperatori)}
            >
              <ListItemIcon>
                <Diversity3Icon style={{color: "white"}}/>
              </ListItemIcon>
              <ListItemText primary={"Operatori"} style={{color: "white"}}/>
              {openOperatori ? <ExpandLess style={{color: "white"}}/> : <ExpandMore style={{color: "white"}}/>}
            </ListItem>
          </Tooltip>
          <Collapse in={openOperatori} timeout="auto" unmountOnExit>
            <List component="div" disablePadding style={{ paddingLeft: 20 }}>
              <Tooltip title="Impostazioni">
                <ListItem
                  selected={fasceOrarieButtonSelected}
                  button
                  key={"Impostazioni"}
                  onClick={redirectImpostazioniOperatore}
                >
                  <ListItemIcon>
                    <SettingsIcon style={{color: "white"}}/>
                  </ListItemIcon>
                  <ListItemText primary={"Impostazioni"} style={{color: "white"}}/>
                </ListItem>
              </Tooltip>
              <Tooltip title="Incassi">
                <ListItem
                  selected={fasceOrarieButtonSelected}
                  button
                  key={"Incassi"}
                  onClick={redirectIncassiOperatore}
                >
                  <ListItemIcon>
                    <PersonSearchIcon style={{color: "white"}}/>
                  </ListItemIcon>
                  <ListItemText primary={"Incassi"} style={{color: "white"}}/>
                </ListItem>
              </Tooltip>
            </List>
          </Collapse>
          <Divider variant="middle" component="li"/>
          <Tooltip title="Fasce Orarie" >
            <ListItem
              selected={scontriniButtonSelected}
              button
              key={"Finanziario"}
              onClick={() => setOpenFasceOrarie(!openFasceOrarie)}
            >
              <ListItemIcon>
                <AccessTimeIcon style={{color: "white"}}/>
              </ListItemIcon>
              <ListItemText primary={"Fasce Orarie"} style={{color: "white"}}/>
              {openFasceOrarie ? <ExpandLess style={{color: "white"}}/> : <ExpandMore style={{color: "white"}}/>}
            </ListItem>
          </Tooltip>
          <Collapse in={openFasceOrarie} timeout="auto" unmountOnExit>
            <List component="div" disablePadding style={{ paddingLeft: 20 }}>
              <Tooltip title="Andamento Vendita">
                <ListItem
                  selected={fasceOrarieButtonSelected}
                  button
                  key={"ReportFasceOrarie"}
                  onClick={redirectReportFasceOrarie}
                >
                  <ListItemIcon>
                    <AccessTimeIcon style={{color: "white"}}/>
                  </ListItemIcon>
                  <ListItemText primary={"Andamento Vendita"} style={{color: "white"}}/>
                </ListItem>
              </Tooltip>
              <Tooltip title="Andamento Reparti">
                <ListItem
                  selected={fasceOrarieButtonSelected}
                  button
                  key={"Tender Pagamento"}
                  onClick={redirectReportFasceOrarieReparto}
                >
                  <ListItemIcon>
                    <PieChartIcon style={{color: "white"}}/>
                  </ListItemIcon>
                  <ListItemText primary={"Andamento Reparti"} style={{color: "white"}}/>
                </ListItem>
              </Tooltip>
              <Tooltip title="Andamento Operatori">
                <ListItem
                  selected={fasceOrarieButtonSelected}
                  button
                  key={"Operatori"}
                  onClick={redirectReportFasceOrarieOperatore}
                >
                  <ListItemIcon>
                    <PieChartIcon style={{color: "white"}}/>
                  </ListItemIcon>
                  <ListItemText primary={"Andamento Operatori"} style={{color: "white"}}/>
                </ListItem>
              </Tooltip>
            </List>
          </Collapse>
          <Divider variant="middle" component="li"/>
          <Tooltip title="Finanziario" >
            <ListItem
              selected={scontriniButtonSelected}
              button
              key={"Finanziario"}
              onClick={() => setOpenFinanziario(!openFinanziario)}
            >
              <ListItemIcon>
                <EuroIcon style={{color: "white"}}/>
              </ListItemIcon>
              <ListItemText primary={"Finanziario"} style={{color: "white"}}/>
              {openFinanziario ? <ExpandLess style={{color: "white"}}/> : <ExpandMore style={{color: "white"}}/>}
            </ListItem>
          </Tooltip>
          <Collapse in={openFinanziario} timeout="auto" unmountOnExit>
            <List component="div" disablePadding style={{ paddingLeft: 20 }}>
              <Tooltip title="Analisi Iva">
                <ListItem
                  selected={fasceOrarieButtonSelected}
                  button
                  key={"Analisi Iva"}
                  onClick={redirectFinanziarioIva}
                >
                  <ListItemIcon>
                    <PieChartIcon style={{color: "white"}}/>
                  </ListItemIcon>
                  <ListItemText primary={"Analisi Iva"} style={{color: "white"}}/>
                </ListItem>
              </Tooltip>
              <Tooltip title="Tender Pagamento">
                <ListItem
                  selected={fasceOrarieButtonSelected}
                  button
                  key={"Tender Pagamento"}
                  onClick={redirectFinanziarioTenderPagamento}
                >
                  <ListItemIcon>
                    <PieChartIcon style={{color: "white"}} />
                  </ListItemIcon>
                  <ListItemText primary={"Tender Pagamento"} style={{color: "white"}}/>
                </ListItem>
              </Tooltip>
            </List>
          </Collapse>
          <Divider variant="middle" component="li"/>
          <Tooltip title="Reparti" >
            <ListItem
              selected={homeButtonSelected}
              button
              key={"Reparti"}
              onClick={redirectReparti}
            >
              <ListItemIcon>
                <KitchenIcon style={{color: "white"}}/>
              </ListItemIcon>
              <ListItemText primary={"Reparti"} style={{color: "white"}}/>
            </ListItem>
          </Tooltip>
          <Divider variant="middle" component="li"/>
          <Tooltip title="Fidelity" >
            <ListItem
              selected={scontriniButtonSelected}
              button
              key={"Finanziario"}
              onClick={() => setOpenFidelity(!openFidelity)}
            >
              <ListItemIcon>
                <Diversity1Icon style={{color: "white"}}/>
              </ListItemIcon>
              <ListItemText primary={"Fidelity"} style={{color: "white"}}/>
              {openFinanziario ? <ExpandLess style={{color: "white"}}/> : <ExpandMore style={{color: "white"}}/>}
            </ListItem>
          </Tooltip>
          <Collapse in={openFidelity} timeout="auto" unmountOnExit>
            <List component="div" disablePadding style={{ paddingLeft: 20 }}>
              <Tooltip title="Anomalie">
                <ListItem
                  selected={fasceOrarieButtonSelected}
                  button
                  key={"Anomalie"}
                  onClick={redirectFidelityAnomalie}
                >
                  <ListItemIcon>
                    <WarningIcon style={{color: "white"}}/>
                  </ListItemIcon>
                  <ListItemText primary={"Anomalie"} style={{color: "white"}}/>
                </ListItem>
              </Tooltip>
            </List>
          </Collapse>
          <Divider variant="middle" component="li"/>
          <Tooltip title="Informazioni Geografiche" >
            <ListItem
              button
              key={"GeoPos"}
              onClick={() => setOpenGeoPos(!openGeoPos)}
            >
              <ListItemIcon>
                <TravelExploreIcon style={{color: "white"}}/>
              </ListItemIcon>
              <ListItemText primary={"GeoPos"} style={{color: "white"}}/>
              {openGeoPos ? <ExpandLess style={{color: "white"}}/> : <ExpandMore style={{color: "white"}}/>}
            </ListItem>
          </Tooltip>
          <Collapse in={openGeoPos} timeout="auto" unmountOnExit>
            <List component="div" disablePadding style={{ paddingLeft: 20 }}>
              <Tooltip title="Presenze">
                <ListItem
                  button
                  key={"Presenze"}
                  onClick={redirectGeoPosPresenze}
                >
                  <ListItemIcon>
                    <RoomIcon style={{color: "white"}} />
                  </ListItemIcon>
                  <ListItemText primary={"Presenze"} style={{color: "white"}}/>
                </ListItem>
              </Tooltip>
            </List>
          </Collapse>
          <Divider variant="middle" component="li"/>
          <Tooltip title="Obiettivi" >
            <ListItem
              button
              key={"Obiettivi"}
              onClick={() => setOpenObiettivi(!openObiettivi)}
            >
              <ListItemIcon>
                <EmojiEventsIcon style={{color: "white"}}/>
              </ListItemIcon>
              <ListItemText primary={"Obiettivi"} style={{color: "white"}}/>
              {openObiettivi ? <ExpandLess style={{color: "white"}}/> : <ExpandMore style={{color: "white"}}/>}
            </ListItem>
          </Tooltip>
          <Collapse in={openObiettivi} timeout="auto" unmountOnExit>
            <List component="div" disablePadding style={{ paddingLeft: 20 }}>
              <Tooltip title="Settori">
                <ListItem
                  button
                  key={"Settori"}
                  onClick={redirectObiettiviSettori}
                >
                  <ListItemIcon>
                    <EmojiEventsIcon style={{color: "white"}} />
                  </ListItemIcon>
                  <ListItemText primary={"Settori"} style={{color: "white"}}/>
                </ListItem>
              </Tooltip>
            </List>
          </Collapse>
          <Divider variant="middle" component="li"/>
          <Tooltip title="Articoli" >
            <ListItem
              button
              key={"Articoli"}
              onClick={() => setOpenArticoli(!openArticoli)}
            >
              <ListItemIcon>
                <ExtensionIcon style={{color: "white"}}/>
              </ListItemIcon>
              <ListItemText primary={"Articoli"} style={{color: "white"}}/>
              {openArticoli ? <ExpandLess style={{color: "white"}}/> : <ExpandMore style={{color: "white"}}/>}
            </ListItem>
          </Tooltip>
          <Collapse in={openArticoli} timeout="auto" unmountOnExit>
            <List component="div" disablePadding style={{ paddingLeft: 20 }}>
              <Tooltip title="Giornaliero">
                <ListItem
                  button
                  key={"Giornaliero"}
                  onClick={redirectArticoliVendutoGiornaliero}
                >
                  <ListItemIcon>
                    <ExtensionIcon style={{color: "white"}} />
                  </ListItemIcon>
                  <ListItemText primary={"Giornaliero"} style={{color: "white"}}/>
                </ListItem>
              </Tooltip>
              <Tooltip title="Operatori">
                <ListItem
                  button
                  key={"Operatori"}
                  onClick={redirectArticoliVendutoGiornalieroOperatore}
                >
                  <ListItemIcon>
                    <ExtensionIcon style={{color: "white"}}/>
                  </ListItemIcon>
                  <ListItemText primary={"Operatori"} style={{color: "white"}}/>
                </ListItem>
              </Tooltip>
              <Tooltip title="Scontrino">
                <ListItem
                  button
                  key={"Scontrino"}
                  onClick={redirectArticoliVendutoGiornalieroOperatoreScontrino}
                >
                  <ListItemIcon>
                    <ExtensionIcon style={{color: "white"}} />
                  </ListItemIcon>
                  <ListItemText primary={"Scontrino"} style={{color: "white"}}/>
                </ListItem>
              </Tooltip>
            </List>
          </Collapse>
        </List>
      </Drawer>
    </div>
  );
}

export default NavBar;
