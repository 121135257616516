import 'chartjs-plugin-zoom';

export let PresenzeRepartiAggregatoData = {
    labels: [],
    datasets: [
        {
            label: "Presenze",
            data: [],
            backgroundColor: []
        }
    ]
  };

export let PresenzeRepartiAggregatoOptions = {
  responsive: true, 
  maintainAspectRatio: false,  
  
}
