import Moment from "moment";
import { extendMoment } from "moment-range";
const moment = extendMoment(Moment);
export const getSliceOrari = (inizio, stepOre, stepMinuti) => {
    const day_start = moment().startOf("day").hours(inizio); // 7 am
    const day_end = moment().endOf("day"); // 10 pm
    const day = moment.range(day_start, day_end);
    const minutes = stepOre * 60 + stepMinuti;
    let myTimes = Array.from(day.by("minutes", { step: minutes, excludeEnd: false }));
    if (stepOre === 2 || stepOre === 1 || stepMinuti === 30) {
      myTimes.push(moment().endOf("day"));
    }
    return myTimes
  };
  
export const getIntervalliOrari = () => {
    let intervalli = [];
    intervalli[0] = { chiave: "30min", stepOre: 0, stepMinuti: 30 };
    for (let i = 1; i <= 4; i++) {
      intervalli.push({ chiave: `${i}h`, stepOre: i, stepMinuti: 0 });
    }
    return intervalli;
  };
  
export const getOrarioDa = () => {
    let orariDisponibiliDa = [];
    for (let i = 1; i < 24; i++) {
      orariDisponibiliDa.push({ chiave: `${i}:00`, valore: i });
    }
    return orariDisponibiliDa;
  };