import Moment from 'moment';

const columnsDettaglioTenderPagamento = [
    {
      field: 'Data',
      headerName: 'Data',
      width: 200,
      editable: false,
      headerAlign: 'center',
      align: 'center',
      type:"date",
      valueFormatter: ({ value }) => Moment(value).format("DD/MM/YYYY"),
    },
    {
      field: 'Totale',
      headerName: 'Totale',
      width: 300,
      editable: false,
      type:"number",
      headerAlign: 'center',
      align: 'right',
      valueFormatter: ({ value }) => Number(value).toLocaleString("it-IT", {minimumFractionDigits: 2}),
    },
    {
      field: 'Incidenza',
      headerName: 'Incidenza',
      width: 300,
      type:"number",
      editable: false,
      headerAlign: 'center',
      align: 'right',
      valueGetter: (params) => {
        return `${parseFloat(params.row.Incidenza).toFixed(2)}%`;
      }
    }
  ];



export default columnsDettaglioTenderPagamento