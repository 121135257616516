import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import React, { useState, useEffect } from "react";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import axios from "axios";
import LoadingSpinner from "Components/LoadingSpinner";

import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import { useParams } from "react-router";

import NavBar from "Components/NavBar";
import DateRangePicker from "Components/DateRangePicker";
import Typography from "@mui/material/Typography";

import Moment from "moment";
import { extendMoment } from "moment-range";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { itIT } from "@material-ui/data-grid";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
  gridClasses,
} from "@mui/x-data-grid";
import columnsReparto from "Utils/Datagrid/Reparto/Reparto";
import CustomToolbar from "Utils/Datagrid/CustomToolbar";
// Filtri
import MultipleSelectCheckmarks from "Components/CheckmarkSelect";
import Button from "@mui/material/Button";
import configData from "CONFIGURAZIONE.json";
import { createContext, useContext } from 'react';
import { LicenzaContext } from 'Utils/LicenzaContext';

import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import TocIcon from "@material-ui/icons/Toc";
import TimelineIcon from "@material-ui/icons/Timeline";
import { Pie } from "react-chartjs-2";
import HomeIcon from "@mui/icons-material/Home";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import KitchenIcon from '@mui/icons-material/Kitchen';
import {
  PieIncassoAggregatoData
} from "Utils/Grafici/Reparto/IncassoAggregato";
import {getColors} from "Utils/Funzioni/Grafici";

import CardObiettivoReparto from "Components/CardObiettivoReparto";
ChartJS.register(ArcElement, Tooltip, Legend);

const BreadcrumbsReference = (idCliente) => {
  return [
    {
      label: "HOME",
      href: `/${idCliente}`,
      icon: <HomeIcon fontSize="small" />,
    },
    {
      label: "Obiettivi",
      href: `/${idCliente}/Obiettivi`,
      icon: <KitchenIcon fontSize="small" />,
    },
    {
      label: "Reparto",
      href: `/${idCliente}/Settore`,
      icon: <KitchenIcon fontSize="small" />,
    },
    
  ];
};

var server = configData.api_end_point;
const moment = extendMoment(Moment);

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  container: {
    margin: theme.spacing(1),
    marginTop: "10vh",
  },
  paper: {
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(2),
    },
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(1),
    },
    flexGrow: 1,
  },
  filterExpansionBar: {
    marginBottom: theme.spacing(1),
  },
  searchbar: {
    padding: theme.spacing(1),
  },
  scontriniGrid: {
    height: "80vh",
  },
  card: {
    [theme.breakpoints.up("md")]: {
      width: "20%",
    },
    [theme.breakpoints.down("md")]: {
      width: "100vw",
      height: "100%",
    },
  },
}));

function ObiettiviReparto() {
  const classes = useStyles();
  const theme = useTheme();
  const desktop = useMediaQuery(theme.breakpoints.up("lg"));
  const tablet = useMediaQuery(theme.breakpoints.up("sm"));
  const mobile = useMediaQuery(theme.breakpoints.up("xs"));

  const firstIndex = 0;
  const [columns, setColumns] = React.useState(columnsReparto);
  const [tabSelezionato, setTabSelezionato] = React.useState(0);
  const [data, setData] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [repartiGiornalieri, setRepartiGiornalieri] = React.useState([]);
  const [repartoGiornalieri, setRepartoGiornalieri] = React.useState([]);
  const [repartiPerNegozio, setRepartiPerNegozio] = React.useState([]);
  const [caricamentoDati, aggiornaCaricamento] = React.useState(false);
  // Stati //
  const licenzaContext = useContext(LicenzaContext);
  // Storia
  let idCliente = licenzaContext.licenza.id_cliente;
  let utente = licenzaContext.licenza.utente;

  ////////////////////////////////////////////////////
  // FILTRI

  // Negozio
  const [filtroNegozi, setFiltroNegozi] = useState([]);

  const [intervalloGiorni, setIntervalloGiorni] = useState([
    {
      startDate: new Date().setDate(new Date().getDate() - 1),
      endDate: new Date().setDate(new Date().getDate() - 1),
      key: "selection",
    },
  ]);

  // Intervallo date
  const [filtroIntervalloData, setFiltroIntervalloData] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  ////////////////////////////////////////////////////

  const [idNegozi, setIdNegozi] = useState([]);
  const [pieIncassoAggregatoData, setPieIncassoAggregatoData] = useState([])
  const [lineIncassoAggregatoData, setLineIncassoAggregatoData] = useState([])
  useEffect(() => {
    axios.get(`${server}/${idCliente}/${utente}/negozi`).then((response) => {
      let negozi = [];
      if (response.status == 200) {
        response.data.forEach((negozio) => {
          negozi.push({"chiave":negozio.id_negozio, "valore":negozio.nome_negozio});
        });
        setIdNegozi(negozi);
        setFiltroNegozi([negozi[0].id_negozio]);
      }
    });
  }, []);

  //"${server}/scontrini/0255/cercaPerData",
  const requestDati = () => {
    aggiornaCaricamento(true);
    setData([]);
    axios
      .post(
        `${server}/${idCliente}/negozi/aggregati/reparti`,
        getAllFilters()
      )
      .then(function (response) {
        let reparti = {};
        let importoTotale = 0
        let repartiGiornalieri = {};
        let key = 0;
        console.log(response.data);
        let repartiPerGiorno = response.data.repartiPerGiorno
        for (let giorno in repartiPerGiorno){
          key += 1;
          importoTotale += repartiPerGiorno[giorno].importo; 
          for (const [codiceReparto, informazioni] of Object.entries(repartiPerGiorno[giorno].importi_presenze_reparti)) {
            if (!(codiceReparto in repartiGiornalieri)){
              repartiGiornalieri[codiceReparto] = []
            }
            repartiGiornalieri[codiceReparto].push({data:repartiPerGiorno[giorno].data,...informazioni})
            if (!(codiceReparto in reparti)) {
              reparti[codiceReparto] = {descrizione:informazioni.Descrizione, totale:informazioni.Totale, pezzi: informazioni.Pezzi, presenze: informazioni.Presenze, articoli: informazioni.Articoli};
            }else{
              reparti[codiceReparto] = {descrizione:informazioni.Descrizione, totale:reparti[codiceReparto].totale+informazioni.Totale, pezzi: reparti[codiceReparto].pezzi+informazioni.Pezzi, presenze: reparti[codiceReparto].presenze+informazioni.Presenze, articoli: reparti[codiceReparto].articoli+informazioni.Articoli};
            }
          }
        }
        let rows= [];
        console.log(reparti);
        let colori=[];
        for (const [codiceReparto, informazioni] of Object.entries(reparti)){
          rows.push({codice:codiceReparto,descrizione:informazioni.descrizione, totale:informazioni.totale, presenze: parseInt(informazioni.presenze/key),pezzi: informazioni.pezzi, articoli: parseInt(informazioni.articoli/key), incidenza:parseFloat(informazioni.totale)/parseFloat(importoTotale)*100});
         
        }
        PieIncassoAggregatoData.labels = rows.map((reparto) => { return reparto.descrizione});
        PieIncassoAggregatoData.datasets[0].data = rows.map((reparto) => { return reparto.totale});
        PieIncassoAggregatoData.datasets[0].backgroundColor = getColors(rows.length);
        console.log(repartiGiornalieri)
        setRepartiGiornalieri(repartiGiornalieri)
        console.log(response.data)
        setRepartiPerNegozio(response.data.repartiPerNegozio)
        setPieIncassoAggregatoData(PieIncassoAggregatoData)
        setData(rows);
        aggiornaCaricamento(false);
      })
      .catch(function (error) {
        aggiornaCaricamento(false);
        console.log(error);
      });
  };

  const scaricaExcel = () => {
    console.log("")
  };

  const getAllFilters = () => {
    let filter = {};
    try {
      filter = {
        dataDa: `${moment(intervalloGiorni[0].startDate).format("DD/MM/YYYY")}`,
        dataA: `${moment(intervalloGiorni[0].endDate).format("DD/MM/YYYY")}`,
        puntiVendita: filtroNegozi,
      };
    } catch {}

    console.log(filter);
    return filter;
  };

  const ObiettiviRepartoRendered = () => {
    if (desktop || tablet)
      return (
        <div className={classes.root}>
          <NavBar idCliente={idCliente} breadcrumbsReference={BreadcrumbsReference(idCliente)}/>
          <LoadingSpinner open={caricamentoDati} />
          <Container className={classes.container} maxWidth="xl">
            <Accordion style={{ marginTop: "4vh" }} defaultExpanded>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography variant="h6" >Barra di Ricerca</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Paper
                  elevation={6}
                  style={{
                    padding: 11,
                    margin: "auto",
                    marginTop: 13,
                    marginBottom: 13,
                    width: "100%",
                  }}
                >
                  <Grid
                    container
                    direction="row"
                    className={classes.searchbar}
                    spacing={1}
                  >
                    <Grid item xl={4} md={4} direction="row">
                      <DateRangePicker
                        state={intervalloGiorni}
                        setState={setIntervalloGiorni}
                      />
                    </Grid>
                    <Grid container item xl={8} md={8}>
                      <Grid item xl={12} md={12}>
                        <MultipleSelectCheckmarks
                        label={"Selezione Negozi"}
                        selezioneMultipla={true}
                          items={idNegozi}
                          setState={setFiltroNegozi}
                        />
                      </Grid>
                      <Grid
                        container
                        item
                        xl={12}
                        md={12}
                        justifyContent="center"
                      >
                        <Button
                          fullWidth
                          variant="contained"
                          onClick={() => {
                            requestDati();
                          }}
                          style={{
                            margin: 5,
                          }}
                        >
                          Visualizza
                        </Button>
                        <Button
                          disabled
                          fullWidth
                          variant="contained"
                          onClick={() => {
                            scaricaExcel();
                          }}
                          style={{
                            margin: 5,
                          }}
                          
                        >
                          Scarica Excel
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Paper>
              </AccordionDetails>
            </Accordion>
            <Paper style={{ marginBottom: "2vh" }}>
              <Tabs
                value={tabSelezionato}
                onChange={(event, newValue) => {
                  setTabSelezionato(newValue);
                }}
                variant="fullWidth"
                indicatorColor="[primary]"
                textColor="primary"
                aria-label="icon label tabs example"
              >
                <Tab icon={<TocIcon />} label="Obiettivi Mensili" />
                <Tab icon={<TimelineIcon />} label="Obiettivi Annuali" />
              </Tabs>
            </Paper>
            <React.Fragment>
              {tabSelezionato === 0 ? (
                
                <Grid container direction="row" spacing={2}>
                  <Grid item xl={3}>
                  <CardObiettivoReparto></CardObiettivoReparto>
                  </Grid>
                  <Grid item xl={3}>
                  <CardObiettivoReparto></CardObiettivoReparto>
                  </Grid>
                  <Grid item xl={3}>
                  <CardObiettivoReparto></CardObiettivoReparto>
                  </Grid>
                
                </Grid>
              ) : (
                <Grid container direction="column" spacing={2}>
                  <Grid item>
                  <CardObiettivoReparto></CardObiettivoReparto>
                  </Grid>
                  <Grid item>
                  <CardObiettivoReparto></CardObiettivoReparto>
                  </Grid>
                  <Grid item>
                  <CardObiettivoReparto></CardObiettivoReparto>
                  </Grid>
                </Grid>
              )}
            </React.Fragment>
          </Container>
        </div>
      );
    if (tablet || mobile) return <div className={classes.root}> </div>;
  };
  return <> {ObiettiviRepartoRendered()} </>;
}

export default ObiettiviReparto;
