const columnsReparto = [
    
    {
      field: 'codice',
      headerName: 'Codice Reparto',
      width: 200,
      editable: false,
      headerAlign: 'center',
      align: 'center'
    },
    {
      field: 'descrizione',
      headerName: 'Descrizione',
      width: 500,
      editable: false,
      type:"number",
      headerAlign: 'center',
      align: 'center'
    },
    {
      field: 'articoli',
      headerName: 'Articoli in Media',
      width: 200,
      editable: false,
      type:"number",
      valueFormatter: ({ value }) => parseInt(value),
      headerAlign: 'center',
      align: 'right'
    },
    {
      field: 'pezzi',
      headerName: 'Pezzi',
      width: 200,
      editable: false,
      type:"number",
      headerAlign: 'center',
      align: 'right'
    },
    {
      field: 'presenze',
      headerName: 'Presenze in Media',
      width: 200,
      valueFormatter: ({ value }) => parseInt(value),
      editable: false,
      type:"number",
      headerAlign: 'center',
      align: 'right'
    },
    {
      field: 'totale',
      headerName: 'Totale',
      width: 200,
      editable: false,
      type:"number",
      headerAlign: 'center',
      align: 'right',
      valueFormatter: ({ value }) => Number(value).toLocaleString("it-IT", {minimumFractionDigits: 2}),
    },
    {
      field: 'incidenza',
      headerName: 'Incidenza',
      width: 200,
      type:"number",
      editable: false,
      headerAlign: 'center',
      align: 'right',
      valueGetter: (params) => {
        return `${parseFloat(params.row.incidenza).toFixed(2)}%`;
      }
    }
  ];



export default columnsReparto