import * as React from 'react';
import itLocale from 'date-fns/locale/it';
import TextField from '@mui/material/TextField';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import TimePicker from '@mui/lab/TimePicker';

const localeMap = {
  it: itLocale
};


// Selettore Data
// PROPS
// time: stringa che rappresenta la data iniziale del componente
// handleOnChange: funzione callback quando la data del componente cambia 

export default function LocalizedTimePicker(props) {
  const [locale, ] = React.useState('it');
  const [value, setValue] = React.useState(props.time);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} locale={localeMap[locale]}>
        <TimePicker
            value={value}
            onChange={(newValue) => {props.handleOnChange(newValue); setValue(newValue)}}
            renderInput={(params) => <TextField {...params} />}
        />
    </LocalizationProvider>
  );
}
