import * as React from "react";
import PropTypes from "prop-types";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

export default function CircularProgressWithLabel(props) {
  return (
    <div style={{ width: props.sizePixel, height: props.sizePixel }}>
      <CircularProgressbar
        value={props.value}
        text={`${props.value}%`}
        styles={buildStyles({
          textColor: props.textColor,
          pathColor: props.pathColor,
          trailColor: "",
        })}
      />
      ;
    </div>
  );
}
