

const columnsDettaglioVenditeFasceOrario = [
    
    {
      field: 'id_negozio',
      headerName: 'Punto Vendita',
      width: 200,
      editable: false,
      headerAlign: 'center',
      align: 'center'
    },
    {
      field: 'nr_scontrini',
      headerName: 'Numero di Scontrini',
      width: 200,
      editable: false,
      type:"number",
      headerAlign: 'center',
      align: 'right'
    },
    {
      field: 'nr_fidelity',
      headerName: 'Numero di Fidelity',
      width: 200,
      editable: false,
      type:"number",
      headerAlign: 'center',
      align: 'right'
    },
    {
      field: 'valore_medio_scontrini',
      headerName: 'Importo medio Scontrino',
      width: 200,
      editable: false,
      type:"number",
      headerAlign: 'center',
      align: 'right',
      valueFormatter: ({ value }) => Number(value).toLocaleString("it-IT", {minimumFractionDigits: 2}),
    },
    {
      field: 'importo_totale',
      headerName: 'Importo Totale',
      width: 200,
      type:"number",
      editable: false,
      headerAlign: 'center',
      align: 'right',
      valueFormatter: ({ value }) => Number(value).toLocaleString("it-IT", {minimumFractionDigits: 2}),
    },
    {
      field: 'nr_medio_articoli',
      headerName: 'Numero medio Articoli per Scontrino',
      width: 300,
      type:"number",
      valueFormatter: ({ value }) => parseInt(value),
      editable: false,
      headerAlign: 'center',
      align: 'right'
    },
    {
      field: 'nr_medio_scontrini',
      headerName: 'Numero medio Scontrini',
      width: 200,
      editable: false,
      type:"number",
      valueFormatter: ({ value }) => parseInt(value),
      headerAlign: 'center',
      align: 'right'
    },
    {
      field: 'nr_medio_fidelity',
      headerName: 'Numero medio Fidelity',
      width: 200,
      valueFormatter: ({ value }) => parseInt(value),
      editable: false,
      type:"number",
      headerAlign: 'center',
      align: 'right'
    }
  ];



export default columnsDettaglioVenditeFasceOrario