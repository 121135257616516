import Moment from 'moment';

const columnsAnomalieFidelity = [
    
    {
      field: 'id',
      headerName: "id",
      width: 400,
      hide:true,
      editable: false,
      headerAlign: 'center',
      align: 'center'
    },
    {
      field: 'data_vendita',
      headerName: 'Data',
      type:"date",
      valueFormatter: ({ value }) => Moment(value).format("DD/MM/YYYY"),
      width: 300,
      editable: false,
      headerAlign: 'center',
      align: 'center'
    },
    {
      field: 'fidelity',
      headerName: 'Fidelity',
      width: 300,
      editable: false,
      headerAlign: 'center',
    },
    {
      field: 'nr_passaggi',
      headerName: 'Numero Passaggi',
      width: 300,
      type:"number",
      editable: false,
      headerAlign: 'center'
    },
    {
      field: 'importo_vendita',
      headerName: 'Importo Totale',
      width: 300,
      editable: false,
      type:"number",
      headerAlign: 'center',
      align: 'right',
      valueFormatter: ({ value }) => Number(value).toLocaleString("it-IT", {minimumFractionDigits: 2})
    }
  ];



export default columnsAnomalieFidelity