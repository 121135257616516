import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import React, { useState, useEffect } from "react";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import axios from "axios";
import { createContext, useContext } from 'react';
import { LicenzaContext } from 'Utils/LicenzaContext';
import LoadingSpinner from "Components/LoadingSpinner";

import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import { useParams } from "react-router";

import NavBar from "../../../Components/NavBar";
import DateRangePicker from "../../../Components/DateRangePicker";
import Typography from "@mui/material/Typography";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Moment from "moment";
import { extendMoment } from "moment-range";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { itIT } from "@material-ui/data-grid";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
  gridClasses,
} from "@mui/x-data-grid";
import columnsFinanziarioIva from "Utils/Datagrid/Finanziario/Iva/Iva";

// Filtri
import MultipleSelectCheckmarks from "../../../Components/CheckmarkSelect";
import Button from "@mui/material/Button";
import configData from "../../../CONFIGURAZIONE.json";

import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import PhoneIcon from "@material-ui/icons/Phone";
import FavoriteIcon from "@material-ui/icons/Favorite";
import PersonPinIcon from "@material-ui/icons/PersonPin";
import TocIcon from "@material-ui/icons/Toc";
import TimelineIcon from "@material-ui/icons/Timeline";
import { Line, Bar, Pie } from "react-chartjs-2";
import HomeIcon from "@mui/icons-material/Home";
import EuroIcon from "@material-ui/icons/Euro";
import PieChartIcon from "@material-ui/icons/PieChart";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { RestorePageOutlined } from "@material-ui/icons";
import { RouteSharp } from "@mui/icons-material";
import CustomToolbar from "Utils/Datagrid/CustomToolbar";
import {
  PieIncassoAggregatoOptions,
  PieIncassoAggregatoData,
  LineIncassoAggregatoOptions,
  LineIncassoAggregatoData,
} from "Utils/Grafici/Finanziario/Iva/IncassoAggregato";

import { getColors } from "Utils/Funzioni/Grafici";
import DettaglioIva from "Pages/Finanziario/Iva/PopUp/DettaglioIva";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";

ChartJS.register(ArcElement, Tooltip, Legend);

const BreadcrumbsReference = (idCliente) => {
  return [
    {
      label: "HOME",
      href: `/${idCliente}`,
      icon: <HomeIcon fontSize="small" />,
    },
    {
      label: "Finanziario",
      href: `#`,
      icon: <EuroIcon fontSize="small" />,
    },
    {
      label: "Iva",
      href: `/${idCliente}/Report/Finanziario/Iva`,
      icon: <PieChartIcon fontSize="small" />,
    },
  ];
};

var server = configData.api_end_point;

const moment = extendMoment(Moment);

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  container: {
    margin: theme.spacing(1),
    marginTop: "10vh",
  },
  paper: {
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(2),
    },
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(1),
    },
    flexGrow: 1,
  },
  filterExpansionBar: {
    marginBottom: theme.spacing(1),
  },
  searchbar: {
    padding: theme.spacing(1),
  },
  scontriniGrid: {
    height: "80vh",
  },
  card: {
    [theme.breakpoints.up("md")]: {
      width: "20%",
    },
    [theme.breakpoints.down("md")]: {
      width: "100vw",
      height: "100%",
    },
  },
}));

function FinanziarioIva() {
  const classes = useStyles();
  const theme = useTheme();
  const desktop = useMediaQuery(theme.breakpoints.up("lg"));
  const tablet = useMediaQuery(theme.breakpoints.up("sm"));
  const mobile = useMediaQuery(theme.breakpoints.up("xs"));

  const [columns, _] = React.useState(columnsFinanziarioIva);
  const [tabSelezionato, setTabSelezionato] = React.useState(0);
  const [open, setOpen] = React.useState(false);
  const [data, setData] = React.useState([]);
  const [caricamentoDati, aggiornaCaricamento] = React.useState(false);
  // Stati //
  const licenzaContext = useContext(LicenzaContext);
  // Storia
  let idCliente = licenzaContext.licenza.id_cliente;
  let utente = licenzaContext.licenza.utente;

  const [ivaGiornalieri, setIvaGiornalieri] = React.useState([]);
  const [ivaSpecificaGiornalieri, setIvaSpecificaGiornalieri] = React.useState(
    []
  );

  ////////////////////////////////////////////////////
  // FILTRI

  // Negozio
  const [filtroNegozi, setFiltroNegozi] = useState([]);

  const [intervalloGiorni, setIntervalloGiorni] = useState([
    {
      startDate: new Date().setDate(new Date().getDate() - 1),
      endDate: new Date().setDate(new Date().getDate() - 1),
      key: "selection",
    },
  ]);

  // Intervallo date
  const [filtroIntervalloData, setFiltroIntervalloData] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  ////////////////////////////////////////////////////

  const [idNegozi, setIdNegozi] = useState([]);
  const [pieIncassoAggregatoData, setPieIncassoAggregatoData] = useState([]);
  const [lineIncassoAggregatoData, setLineIncassoAggregatoData] = useState([]);

  const [tipoAggregazione, setTipoAggregazione] = useState("Codice");

  useEffect(() => {
    axios.get(`${server}/${idCliente}/${utente}/negozi`).then((response) => {
      let negozi = [];
      if (response.status == 200) {
        response.data.forEach((negozio) => {
          negozi.push({"chiave":negozio.id_negozio, "valore":negozio.nome_negozio});
        });
        setIdNegozi(negozi);
        setFiltroNegozi([negozi[0].id_negozio]);
      }
    });
  }, []);

  const requestDati = () => {
    aggiornaCaricamento(true);
    setData([]);
    axios
      .post(`${server}/${idCliente}/negozi/aggregati/iva`, getAllFilters())
      .then(function (response) {
        let ive = {};
        let importoTotale = 0;
        let castellettoIvaGiornaliero = {};
        let key = 0;
        console.log(response.data);
        for (let giorno in response.data) {
          importoTotale += response.data[giorno].importo;
          castellettoIvaGiornaliero[response.data[giorno].data] = [];
          for (const [codiceIva, informazioni] of Object.entries(
            response.data[giorno].iva
          )) {
            if (!(codiceIva in castellettoIvaGiornaliero)) {
              castellettoIvaGiornaliero[codiceIva] = [];
            }
            castellettoIvaGiornaliero[codiceIva].push({
              data: response.data[giorno].data,
              ...informazioni,
            });
            if (!(codiceIva in ive)) {
              ive[codiceIva] = {
                codice: codiceIva,
                aliquota: informazioni.Aliquota,
                imponibile: informazioni.Imponibile,
                imposta: informazioni.Imposta,
                percentuale: informazioni.Percentuale,
                descrizione: informazioni.Descrizione,
                totale: informazioni.Venduto,
              };
            } else {
              ive[codiceIva] = {
                codice: codiceIva,
                aliquota: informazioni.Aliquota,
                totale: ive[codiceIva].totale + informazioni.Venduto,
                imponibile: ive[codiceIva].imponibile + informazioni.Imponibile,
                imposta: ive[codiceIva].imposta + informazioni.Imposta,
                percentuale: informazioni.Percentuale,
                descrizione: informazioni.Descrizione,
              };
            }
          }
        }

        let rows = [];
        let colori = [];
        for (const [codiceIva, informazioni] of Object.entries(ive)) {
          rows.push({
            ...informazioni,
            incidenza:
              (parseFloat(informazioni.totale) / parseFloat(importoTotale)) *
              100,
          });
        }
        PieIncassoAggregatoData.labels = rows.map((tender) => {
          return tender.descrizione;
        });
        PieIncassoAggregatoData.datasets[0].data = rows.map((tender) => {
          return tender.totale;
        });
        PieIncassoAggregatoData.datasets[0].backgroundColor = getColors(rows.length);
        setIvaGiornalieri(castellettoIvaGiornaliero);
        setPieIncassoAggregatoData(PieIncassoAggregatoData);
        setData(rows);
        aggiornaCaricamento(false);
      })
      .catch(function (error) {
        aggiornaCaricamento(false);
        console.log(error);
      });
  };

  const scaricaExcel = () => {
    aggiornaCaricamento(true);
    axios({
      url: `${server}/${idCliente}/negozi/aggregati/iva/excel`, //your url
      method: "POST",
      responseType: "blob", // important
      data: getAllFilters(),
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      aggiornaCaricamento(false);
      link.setAttribute("download", "report.xlsx"); //or any other extension
      document.body.appendChild(link);
      link.click();
    }).catch(function (error) {
      aggiornaCaricamento(false);
      console.log(error);
    });
  };

  const getAllFilters = () => {
    let filter = {};
    try {
      filter = {
        dataDa: `${moment(intervalloGiorni[0].startDate).format(
          "DD/MM/YYYY"
        )}`,
        dataA: `${moment(intervalloGiorni[0].endDate).format("DD/MM/YYYY")}`,
        puntiVendita: filtroNegozi,
        tipoReport: tipoAggregazione,
      };
    } catch {}

    console.log(filter);
    return filter;
  };

  const ScontriniRendered = () => {
    if (desktop || tablet)
      return (
        <div className={classes.root}>
          <NavBar
            idCliente={idCliente}
            breadcrumbsReference={BreadcrumbsReference(idCliente)}
          />
          <LoadingSpinner open={caricamentoDati}/>
          <Container className={classes.container} maxWidth="xl">
            <Accordion style={{ marginTop: "4vh" }} defaultExpanded>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography variant="h6">Barra di Ricerca</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Paper
                  elevation={6}
                  style={{
                    padding: 11,
                    margin: "auto",
                    marginTop: 13,
                    marginBottom: 13,
                    width: "100%",
                  }}
                >
                  <Grid
                    container
                    direction="row"
                    className={classes.searchbar}
                    spacing={1}
                  >
                    <Grid item xl={4} md={4} direction="row">
                      <DateRangePicker
                        state={intervalloGiorni}
                        setState={setIntervalloGiorni}
                      />
                    </Grid>
                    <Grid container item xl={8} md={8}>
                      <Grid item xl={12} md={12}>
                        <MultipleSelectCheckmarks
                        label={"Selezione Negozi"}
                        selezioneMultipla={true}
                          items={idNegozi}
                          setState={setFiltroNegozi}
                        />
                      </Grid>
                      <Grid item xl={12} md={12}>
                        <FormControl>
                          <FormLabel id="demo-radio-buttons-group-label">
                            Aggrega Dati per:
                          </FormLabel>
                          <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            defaultValue="Codice"
                            name="radio-buttons-group"
                            onChange={(event) =>
                              setTipoAggregazione(event.target.value)
                            }
                          >
                            <FormControlLabel
                              value="Codice"
                              control={<Radio />}
                              label="Codice Iva"
                            />
                            <FormControlLabel
                              value="Aliquota"
                              control={<Radio />}
                              label="Aliquota Iva"
                            />
                          </RadioGroup>
                        </FormControl>
                      </Grid>
                      <Grid
                        container
                        item
                        xl={12}
                        md={12}
                        justifyContent="center"
                      >
                        <Button
                          fullWidth
                          variant="contained"
                          onClick={() => {
                            requestDati();
                          }}
                          style={{
                            margin: 5,
                          }}
                        >
                          Visualizza
                        </Button>
                        <Button
                          fullWidth
                          variant="contained"
                          onClick={() => {
                            scaricaExcel();
                          }}
                          style={{
                            margin: 5,
                          }}
                        >
                          Scarica Excel
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Paper>
              </AccordionDetails>
            </Accordion>
            <Paper style={{ marginBottom: "2vh" }}>
              <Tabs
                value={tabSelezionato}
                onChange={(event, newValue) => {
                  setTabSelezionato(newValue);
                }}
                variant="fullWidth"
                indicatorColor="[primary]"
                textColor="primary"
                aria-label="icon label tabs example"
              >
                <Tab icon={<TocIcon />} label="Analitico" />
                <Tab icon={<TimelineIcon />} label="Grafici" />
              </Tabs>
            </Paper>
            <React.Fragment>
              {tabSelezionato === 0 ? (
                <Paper>
                  <DataGrid
                    style={{ flexGrow: 1, height: "75vh" }}
                    components={{
                      Toolbar: CustomToolbar,
                    }}
                    getRowId={(row) => row.codice}
                    rows={data}
                    columns={columns}
                    disableSelectionOnClick
                    localeText={itIT.props.MuiDataGrid.localeText}
                    loading={caricamentoDati}
                    onRowDoubleClick={(event) => {setOpen(true); setIvaSpecificaGiornalieri(ivaGiornalieri[event.row.codice])}}
                  />
                </Paper>
              ) : (
                <Paper>
                  <Grid
                    style={{ flexGrow: 1 }}
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                  >
                    <Grid item xl={5} md={8}>
                      <Pie data={pieIncassoAggregatoData} />
                    </Grid>
                  </Grid>
                </Paper>
              )}
            </React.Fragment>
          </Container>
          <React.Fragment>
            <Dialog open={open} onClose={() => setOpen(false)} maxWidth="xl">
              <DialogContent>
                <DettaglioIva
                  data={ivaSpecificaGiornalieri}
                  totale={ivaSpecificaGiornalieri.reduce(
                    (previous, current) => previous.Totale + current.Totale,
                    0
                  )}
                />
              </DialogContent>
            </Dialog>
          </React.Fragment>
        </div>
      );
    if (tablet || mobile) return <div className={classes.root}> </div>;
  };
  return <> {ScontriniRendered()} </>;
}

export default FinanziarioIva;
