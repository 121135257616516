import * as React from "react";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Checkbox from "@material-ui/core/Checkbox";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    width: 300,
  },
  indeterminateColor: {
    color: "#f50057",
  },
  selectAllText: {
    fontWeight: 500,
  },
  selectedAll: {
    backgroundColor: "rgba(0, 0, 0, 0.08)",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.08)",
    },
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
  getContentAnchorEl: null,
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "center",
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "center",
  },
  variant: "menu",
};

// export default function MultipleSelectCheckmarks(props) {
//   const [items, setItems] = React.useState([]);
//   const handleChange = (event) => {
//     console.log(event)
//     const {
//       target: { value },
//     } = event;
//     setItems(
//       On autofill we get a stringified value.
//       typeof value === 'string' ? value.split(',') : value,
//     );
//     props.setState(event.target.value)
//   };

//   return (
//     <div>
//       <FormControl sx={{ m: 1, width: 300 }}>
//         <InputLabel id="demo-multiple-checkbox-label">Negozi</InputLabel>
//         <Select
//           labelId="demo-multiple-checkbox-label"
//           id="demo-multiple-checkbox"
//           multiple={props.selezioneMultipla}
//           value={items}
//           onChange={handleChange}
//           input={<OutlinedInput label="Negozi" />}
//           MenuProps={MenuProps}
//         >
//           {props.items.map((item) => (
//             <MenuItem key={item.chiave} value={item.chiave}>
//               {item.chiave} - {item.valore}
//             </MenuItem>
//           ))}
//         </Select>
//       </FormControl>
//     </div>
//   );
// }

export default function MultipleSelectCheckmarks(props) {
  const classes = useStyles();
  const [selected, setSelected] = React.useState([]);
  const isAllSelected =
    props.items.length > 0 && selected.length === props.items.length;

  const handleChange = (event) => {
    const value = event.target.value;
    console.log(value);
    if (value[value.length - 1] === "all") {
      setSelected(selected.length === props.items.length ? [] : props.items);
      props.setState(
        selected.length === props.items.length
          ? []
          : props.items.map((item) => item.chiave)
      );
      return;
    }
    if (props.selezioneMultipla === true){
      setSelected(
        // On autofill we get a stringified value.
        typeof value === "string" ? value.split(",") : value
      );
    }
    else{
      setSelected(value);
    }
    props.setState(props.selezioneMultipla === true ? value.map((item) => item.chiave) : value.chiave);
  };

  return (
    <FormControl className={classes.formControl}>
      <InputLabel id="mutiple-select-label">{props.label}</InputLabel>
      <Select
        labelId="mutiple-select-label"
        multiple={props.selezioneMultipla}
        value={selected}
        onChange={handleChange}
        renderValue={(selected) =>
          {
            return props.selezioneMultipla 
              ? selected.map(
            (item, index) => `${item.chiave}-${item.valore}; `
          )
          : `${selected.chiave}-${selected.valore}`
        }}
        MenuProps={MenuProps}
      >
        {props.selezioneMultipla ? (
          <MenuItem
            value="all"
            classes={{
              root: isAllSelected ? classes.selectedAll : "",
            }}
          >
            <ListItemIcon>
              <Checkbox
                classes={{ indeterminate: classes.indeterminateColor }}
                checked={isAllSelected}
                indeterminate={
                  selected.length > 0 && selected.length < props.items.length
                }
              />
            </ListItemIcon>
            <ListItemText
              classes={{ primary: classes.selectAllText }}
              primary="Tutti"
            />
          </MenuItem>
        ) : (
          <div></div>
        )}

        {props.items.map((item) => (
          <MenuItem key={item.chiave} value={item}>
            {props.selezioneMultipla ? (
              <ListItemIcon>
                <Checkbox checked={selected.indexOf(item) > -1} />
              </ListItemIcon>
            ) : (
              <div></div>
            )}
            <ListItemText
              primary={`${item.chiave} - ${item.valore}`}
            />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
