const columnsRepartoFasceOrario = [
    
    {
      field: 'operatore',
      headerName: 'Operatore',
      editable: false,
      width: 170,
      headerAlign: 'center',
      align: 'center',
    },

    
  ];



export default columnsRepartoFasceOrario