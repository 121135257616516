import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import {
  Button,
  CardActionArea,
  CardActions,
  CircularProgress,
} from "@mui/material";
import { useHistory } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Paper from "@material-ui/core/Paper";
import CircularProgressWithLabel from "Components/CircularProgressBar";
import { getColor, getColors } from "Utils/Funzioni/Grafici";
import { Line, Bar, Pie } from "react-chartjs-2";
import {
  PieIncassoAggregatoOptions,
  PieIncassoAggregatoData,
} from "../Utils/Grafici/Obiettivi/ObiettiviSettore/IncassoAggregato";

// import {
//   IncassoGiornalieroData,
//   IncassoGiornalieroOptions,
// } from "../Utils/Grafici/Obiettivi/ObiettiviReparti/IncassoGiornaliero";

// import {
//   TenderGiornalieriData,
//   TenderGiornalieriOptions,
// } from "../Utils/Grafici/Home/TenderGiornalieri";

function CardObiettivoSettoreExtended(props) {
  const [show, setShow] = React.useState(false);
  const [reparti, setReparti] = React.useState([]);
  const [presenzeRepartiAggregatoChart, setPresenzeRepartiAggregato] =
    React.useState({});

  const formatter = new Intl.NumberFormat("it-IT", {
    style: "currency",
    currency: "EUR",

    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
  });

  React.useEffect(() => {
    let i = 0;
    let _reparti = [];

    let descrizione_reparti = [];
    let importo_reparti = [];
    for (let [index, _reparto] of Object.entries(props.datiSettore.reparti)) {
      _reparti.push({ ..._reparto, colore: getColor(i) });
      descrizione_reparti.push(_reparto.descrizione);
      importo_reparti.push(_reparto.importo);
      i++;
    }
    console.log(_reparti);
    setReparti(_reparti);
    let tempPie = {
      labels: [""],
      datasets: [
        {
          label: "",
          data: [],
          backgroundColor: [],
        },
      ],
    };
    tempPie.labels = descrizione_reparti;
    tempPie.datasets[0].data = importo_reparti;
    tempPie.datasets[0].backgroundColor = getColors(_reparti.length);
    setPresenzeRepartiAggregato(tempPie);
    setShow(true);
  }, []);

  return (
    <Card elevation={5}>
      <CardActionArea>
        <CardMedia>
          <Typography
            sx={{ fontSize: 40 }}
            color="text.secondary"
            gutterBottom
            style={{ textAlign: "center", marginTop: "2vh" }}
          >
            {props.datiSettore.descrizione}
          </Typography>
        </CardMedia>
        <CardContent>
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
          >
            <Grid item container xl={6} direction="row" spacing={7}>
              <Grid container item xl={12} justifyContent="center">
                <CircularProgressWithLabel
                  value={Math.trunc(
                    (props.datiSettore.valore_corrente * 100) /
                      props.datiSettore.valore_obiettivo
                  )}
                  sizePixel={200}
                  pathColor="primary"
                  textColor="primary"
                />
              </Grid>
              <Grid container item xl={12} direction="row" alignItems="center">
                <Grid item xl={9}>
                  <Typography
                    variant="h6"
                    component="div"
                    textAlign="end"
                    color="primary"
                  >
                    Goal: {formatter.format(props.datiSettore.valore_obiettivo)}
                  </Typography>
                </Grid>
                <Grid item xl={9}>
                  <Typography
                    variant="h6"
                    component="div"
                    textAlign="end"
                    color="secondary"
                  >
                    Raggiunto:{" "}
                    {formatter.format(props.datiSettore.valore_corrente)}
                  </Typography>
                </Grid>
                <Grid item xl={9}>
                  <Typography
                    gutterBottom
                    variant="h6"
                    component="div"
                    textAlign="end"
                    color="warning"
                  >
                    Restante:{" "}
                    {formatter.format(
                      props.datiSettore.valore_obiettivo -
                        props.datiSettore.valore_corrente
                    )}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item container xl={6}>
              <Grid container item xl={12}>
                {show ? <Pie data={presenzeRepartiAggregatoChart} /> : <></>}
              </Grid>
            </Grid>
            <Grid item container xl={12}>
              <Grid container item xl={12} spacing={1}>
                {Object.entries(reparti).map((reparto, index) => {
                  console.log(reparto);
                  const _reparto = reparto[1];
                  return (
                    <Grid item xl={3} spacing={1} container  justifyContent="space-evenly">
                      <Grid item xl={12}>
                        <Typography
                          sx={{ fontSize: 10 }}
                          color={_reparto.colore}
                          gutterBottom
                          style={{ marginTop: "1vh", textAlign: "center"}}
                        >
                          {_reparto.descrizione}
                        </Typography>
                      </Grid>
                      <Grid item xl={8}>
                        <CircularProgressWithLabel
                          value={Math.trunc(
                            (_reparto.importo * 100) / _reparto.valore
                          )}
                          sizePixel={120}
                          pathColor={_reparto.colore}
                          textColor={_reparto.colore}
                        />
                      </Grid>
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </CardActionArea>
      <CardActions></CardActions>
    </Card>
  );
}

function CardObiettivoSettoreReduced(props) {
  const [show, setShow] = React.useState(false);
  const [reparti, setReparti] = React.useState([]);
  const [presenzeRepartiAggregatoChart, setPresenzeRepartiAggregato] =
    React.useState({});

  const formatter = new Intl.NumberFormat("it-IT", {
    maximumFractionDigits: 0,
    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
  });
  React.useEffect(() => {
    let i = 0;
    let _reparti = [];

    let descrizione_reparti = [];
    let importo_reparti = [];
    for (let [index, _reparto] of Object.entries(props.datiSettore.reparti)) {
      _reparti.push({ ..._reparto, colore: getColor(i) });
      descrizione_reparti.push(_reparto.descrizione);
      importo_reparti.push(_reparto.importo);
      i++;
    }
    console.log(_reparti);
    setReparti(_reparti);
    PieIncassoAggregatoData.labels = descrizione_reparti;
    PieIncassoAggregatoData.datasets[0].data = importo_reparti;
    PieIncassoAggregatoData.datasets[0].backgroundColor = getColors(
      _reparti.length
    );
    setPresenzeRepartiAggregato(PieIncassoAggregatoData);
    setShow(true);
  }, []);

  return (
    <Card elevation={5} onClick={props.onClick}>
      <CardActionArea>
        <CardMedia>
          <Typography
            sx={{ fontSize: 18 }}
            color="text.secondary"
            gutterBottom
            style={{ textAlign: "center", marginTop: "2vh" }}
          >
            {props.datiSettore.descrizione}
          </Typography>
        </CardMedia>
        <CardContent style={{ marginTop: "1vh" }}>
          <Grid item container xl={12} direction="row">
            <Grid container item xl={4}>
              <CircularProgressWithLabel
                value={Math.trunc(
                  (props.datiSettore.valore_corrente * 100) /
                    props.datiSettore.valore_obiettivo
                )}
                sizePixel={100}
                pathColor="primary"
                textColor="primary"
              />
            </Grid>
            <Grid container item xl={8}>
              <Grid item xl={12}>
                <Typography
                  variant="h7"
                  component="div"
                  textAlign="end"
                  color="primary"
                >
                  Goal: {formatter.format(props.datiSettore.valore_obiettivo)}
                </Typography>
              </Grid>
              <Grid item xl={12}>
                <Typography
                  variant="h7"
                  component="div"
                  textAlign="end"
                  color="secondary"
                >
                  Raggiunto:{" "}
                  {formatter.format(props.datiSettore.valore_corrente)}
                </Typography>
              </Grid>
              <Grid item xl={12}>
                <Typography
                  gutterBottom
                  variant="h7"
                  component="div"
                  textAlign="end"
                  color="warning"
                >
                  Restante:{" "}
                  {formatter.format(
                    props.datiSettore.valore_obiettivo -
                      props.datiSettore.valore_corrente
                  )}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </CardActionArea>
      <CardActions></CardActions>
    </Card>
  );
}

export default function CardObiettivoSettore(props) {
  if (props.extended) {
    return (
      <CardObiettivoSettoreExtended
        datiSettore={props.datiSettore}
        onClick={props.onClick}
      />
    );
  } else {
    return (
      <CardObiettivoSettoreReduced
        datiSettore={props.datiSettore}
        onClick={props.onClick}
      />
    );
  }
}
