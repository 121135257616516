import "leaflet/dist/leaflet.css";

import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import React, { useState, useEffect } from "react";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import axios from "axios";
import { createContext, useContext } from 'react';
import { LicenzaContext } from 'Utils/LicenzaContext';

import Grid from "@mui/material/Grid";
import { useParams } from "react-router";
import LoadingSpinner from "Components/LoadingSpinner";
import NavBar from "Components/NavBar";
import DateRangePicker from "Components/DateRangePicker";
import Typography from "@mui/material/Typography";
import Moment from "moment";
import { extendMoment } from "moment-range";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MultipleSelectCheckmarks from "Components/CheckmarkSelect";
import Button from "@mui/material/Button";
import configData from "CONFIGURAZIONE.json";
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';

import {
  MapContainer,
  TileLayer,
  useMap,
  Marker,
  Popup,
  LayersControl,
} from "react-leaflet";
import L from "leaflet";
import greenIconMarker from "Utils/Mappe/StiliMarker/GeoPosPresenze.js"
import HomeIcon from "@mui/icons-material/Home";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import TravelExploreIcon from "@mui/icons-material/TravelExplore";
import RoomIcon from "@mui/icons-material/Room";
import {useMapEvents} from "react-leaflet";
ChartJS.register(ArcElement, Tooltip, Legend);

const position = [40.966667, 14.2];
const position2 = [41.002, 14.2];
const position3 = [41.014, 14.2];
const BreadcrumbsReference = (idCliente) => {
  return [
    {
      label: "HOME",
      href: `/${idCliente}`,
      icon: <HomeIcon fontSize="small" />,
    },
    {
      label: "GeoPos",
      href: `#`,
      icon: <TravelExploreIcon fontSize="small" />,
    },
    {
      label: "Presenze",
      href: `/${idCliente}/GeoPos/Presenze`,
      icon: <RoomIcon fontSize="small" />,
    },
  ];
};

function PassaggiFidelity(props) {
  return (
    <div>
      Nr. Passaggi Fidelity: {props.passaggi} <br/>
      Importo Fidelity: {props.totale} euro
    </div>
  )
}

function PassaggiSurvey(props) {
  return (
    <div>
      Nr. Passaggi Survey: {props.passaggi} <br/>
      Importo Survey: {props.totale} euro
    </div>
  )
}

var server = configData.api_end_point;

const moment = extendMoment(Moment);

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  container: {
    margin: theme.spacing(1),
    marginTop: "5vh",
    marginLeft: "3vw",
    zIndex: 1,
    width: "97vw",
    position: "absolute",
  },
  paper: {
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(2),
    },
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(1),
    },
    flexGrow: 1,
  },
  filterExpansionBar: {
    marginBottom: theme.spacing(1),
  },
  searchbar: {
    padding: theme.spacing(1),
  },
  scontriniGrid: {
    height: "80vh",
  },
  card: {
    [theme.breakpoints.up("md")]: {
      width: "20%",
    },
    [theme.breakpoints.down("md")]: {
      width: "100vw",
      height: "100%",
    },
  },
}));

function GeoPosPresenze() {
  delete L.Icon.Default.prototype._getIconUrl;
  const { BaseLayer, Overlay } = LayersControl;
  L.Icon.Default.mergeOptions({
    iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png").default,
    iconUrl: require("leaflet/dist/images/marker-icon.png").default,
    shadowUrl: require("leaflet/dist/images/marker-shadow.png").default,
  });

  const LeafIcon = L.Icon.extend({
    options: {}
  });

  
  const greenIconMarker = new LeafIcon({
    iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-green.png',
      shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
      iconSize: [25, 41],
      iconAnchor: [12, 41],
      popupAnchor: [1, -34],
      shadowSize: [41, 41]
  });

  const redIconMarker = new LeafIcon({
    iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-red.png',
    shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41]
  });

  const orangeIconMarker = new LeafIcon({
          iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-orange.png',
          shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
          iconSize: [25, 41],
          iconAnchor: [12, 41],
          popupAnchor: [1, -34],
          shadowSize: [41, 41]
  });

  const classes = useStyles();
  const theme = useTheme();
  const desktop = useMediaQuery(theme.breakpoints.up("lg"));
  const tablet = useMediaQuery(theme.breakpoints.up("sm"));
  const mobile = useMediaQuery(theme.breakpoints.up("xs"));

  const [markersComuni, setMarkersComuni] = useState([]);
  const [markersRegioni, setMarkersRegioni] = useState([]);
  const [markersProvince, setMarkersProvince] = useState([]);
  const [alertFidelity, setAlertFidelity] = useState(false);
  const [visualizzaDaFidelity, setVisualizzaDaFidelity] = useState(true);
  const [visualizzaDaSurvey, setVisualizzaDaSurvey] = useState(false);

  const [caricamentoDati, aggiornaCaricamento] = useState(false);
  // Stati //
  const licenzaContext = useContext(LicenzaContext);
  // Storia
  let idCliente = licenzaContext.licenza.id_cliente;
  let utente = licenzaContext.licenza.utente;


  

  //  Use the state hook:
  const [greenIcon, setGreenIcon] = useState(greenIconMarker);
  const [redIcon, setRedIcon] = useState(redIconMarker);
  const [orangeIcon, setOrangeIcon] = useState(orangeIconMarker);
  ////////////////////////////////////////////////////
  // FILTRI

  // Negozio
  const [filtroNegozi, setFiltroNegozi] = useState([]);

  const [intervalloGiorni, setIntervalloGiorni] = useState([
    {
      startDate: new Date().setDate(new Date().getDate() - 1),
      endDate: new Date().setDate(new Date().getDate() - 1),
      key: "selection",
    },
  ]);

  ////////////////////////////////////////////////////

  const [idNegozi, setIdNegozi] = useState([]);
  useEffect(() => {
    axios.get(`${server}/${idCliente}/${utente}/negozi`).then((response) => {
      let negozi = [];
      if (response.status == 200) {
        response.data.forEach((negozio) => {
          negozi.push({
            chiave: negozio.id_negozio,
            valore: negozio.nome_negozio,
          });
        });
        setIdNegozi(negozi);
        setFiltroNegozi([negozi[0].id_negozio]);
      }
    });
  }, []);

  const requestDati = () => {
    setMarkersComuni([]);
    setMarkersProvince([]);
    setMarkersRegioni([]);
    aggiornaCaricamento(true);
    axios
      .post(`${server}/${idCliente}/negozi/scontrini/aggregati/localizzazione`, getAllFilters())
      .then(function (response) {
        console.log(response.data)
        setMarkersComuni(response.data["aggregato"]["comune"]);
        setMarkersProvince(response.data["aggregato"]["provincia"]);
        setMarkersRegioni(response.data["aggregato"]["regione"]);
        aggiornaCaricamento(false);
      })
      .catch(function (error) {
        aggiornaCaricamento(false);
        console.log(error);
      });

    axios
      .post(`${server}/${idCliente}/fidelity/anomalie/localizzazione`, getAllFilters())
      .then(function (response) {
        if(response.data.length > 0){
          setAlertFidelity(true);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
      
  };

  const scaricaExcelFidelityNonLocalizzate = () => {
    aggiornaCaricamento(true);
    axios({
      url: `${server}/${idCliente}/fidelity/anomalie/localizzazione/excel`, //your url
      method: "POST",
      responseType: "blob", // important
      data: getAllFilters(),
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "fidelity_non_localizzate.xlsx"); //or any other extension
      document.body.appendChild(link);
      link.click();
      aggiornaCaricamento(false);
    }).catch(function (error) {
      aggiornaCaricamento(false);
      console.log(error);
    });
  };

  const getAllFilters = () => {
    let filter = {};
    try {
      filter = {
        dataDa: `${moment(intervalloGiorni[0].startDate).format("DD/MM/YYYY")}`,
        puntiVendita: filtroNegozi,
        dataA: `${moment(intervalloGiorni[0].endDate).format("DD/MM/YYYY")}`,
      };
    } catch {}

    console.log(filter);
    return filter;
  };

  function MarkerChangerFunction() {
    const [zoomLevel, setZoomLevel] = useState(9); // initial zoom level provided for MapContainer
    
    const mapEvents = useMapEvents({
        zoomend: () => {
            setZoomLevel(mapEvents.getZoom());
        },
    });
    console.log(zoomLevel);



    if (zoomLevel >= 12) {
      return (Object.entries(markersComuni).map((marker, index) => {
        const _marker = marker[1];
        return (
          <Marker
            key={index}
            position={[_marker.latitudine, _marker.longitudine]}
            icon={greenIconMarker}
          >
            <Popup>
              {
                visualizzaDaFidelity ?
                <PassaggiFidelity passaggi={_marker.nr_passaggi_fidelity} totale={_marker.importo_vendita_fidelity} />
                :
                <></>
              }
              {
                visualizzaDaSurvey ?
                  <PassaggiSurvey passaggi={_marker.nr_passaggi_survey} totale={_marker.importo_vendita_survey} />
                :
                <></>
              }
            </Popup>
          </Marker>
        );
      }))
    }
    if (zoomLevel >= 9 && zoomLevel <= 11) {	
      return (Object.entries(markersProvince).map((marker, index) => {
        const _marker = marker[1];
        return (
          <Marker
            key={index}
            position={[_marker.latitudine, _marker.longitudine]}
            icon={greenIconMarker}
          >
            <Popup>
              {
                visualizzaDaFidelity ?
                <PassaggiFidelity passaggi={_marker.nr_passaggi_fidelity} totale={_marker.importo_vendita_fidelity} />
                :
                <></>
              }
              {
                visualizzaDaSurvey ?
                  <PassaggiSurvey passaggi={_marker.nr_passaggi_survey} totale={_marker.importo_vendita_survey} />
                :
                <></>
              }
            </Popup>
          </Marker>
        );
      }))
    }
    if (zoomLevel <= 8) {	
      return (Object.entries(markersRegioni).map((marker, index) => {
        const _marker = marker[1];
        console.log(_marker)
        return (
          <Marker
            key={index}
            position={[_marker.latitudine, _marker.longitudine]}
            icon={greenIconMarker}
          >
            <Popup>
              {
                visualizzaDaFidelity ?
                <PassaggiFidelity passaggi={_marker.nr_passaggi_fidelity} totale={_marker.importo_vendita_fidelity} />
                :
                <></>
              }
              {
                visualizzaDaSurvey ?
                  <PassaggiSurvey passaggi={_marker.nr_passaggi_survey} totale={_marker.importo_vendita_survey} />
                :
                <></>
              }
            </Popup>
          </Marker>
        );
      }))
    }
    
  }

  const GeoPosPresenzeRendered = () => {
    if (desktop || tablet)
      return (
        <div className={classes.root}>
          <NavBar
            idCliente={idCliente}
            breadcrumbsReference={BreadcrumbsReference(idCliente)}
          />
          <LoadingSpinner open={caricamentoDati} />
          {
            alertFidelity ?
            <Alert severity="error" style={{marginTop:"85vh", opacity:"0.93"}} action={
              <Button color="inherit" size="small" onClick={() => (setAlertFidelity(false))}>
                Chiudi
              </Button>
            }>
              <AlertTitle>Attenzione</AlertTitle>
              Sono presenti fidelity senza localizzazione, per dettaglio scaricare Excel associato.
            </Alert>
            :
            <></>
          }
          <Container className={classes.container} maxWidth="xl">
            <Accordion style={{ marginTop: "4vh", opacity:"0.93"}}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography variant="h6">Barra di Ricerca</Typography>
              </AccordionSummary>
              <AccordionDetails >
                <Paper
                  elevation={6}
                  style={{
                    padding: 11,
                    margin: "auto",
                    marginTop: 13,
                    marginBottom: 13,
                    width: "100%"
                    , opacity:"2"
                  }}
                >
                  <Grid
                    container
                    direction="row"
                    className={classes.searchbar}
                    spacing={1}
                  >
                    <Grid item xl={4} md={4} direction="row">
                      <DateRangePicker
                        state={intervalloGiorni}
                        setState={setIntervalloGiorni}
                      />
                    </Grid>
                    <Grid container item xl={8} md={8} style={{opacity:"1"}}>
                      <Grid item container xl={8} md={12}>
                        <Grid item xl={6} md={6} style={{opacity:"1"}}>
                          <MultipleSelectCheckmarks
                            label={"Selezione Negozi"}
                            selezioneMultipla={true}
                            items={idNegozi}
                            setState={setFiltroNegozi}
                          />
                        </Grid>
                        <Grid item xl={3} md={6} style={{opacity:"1"}}>
                          <FormControlLabel control={<Switch checked={visualizzaDaFidelity} onChange={() => {setVisualizzaDaFidelity(!visualizzaDaFidelity)}} />} label="Visualizza da Fidelity" />
                        </Grid>
                        <Grid item xl={3} md={6} style={{opacity:"1"}}>
                          <FormControlLabel control={<Switch checked={visualizzaDaSurvey} onChange={() => {setVisualizzaDaSurvey(!visualizzaDaSurvey)}} />} label="Visualizza da Survey" />
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        item
                        xl={12}
                        md={12}
                        justifyContent="center"
                      >
                        <Button
                        style={{opacity:"1"}}
                          fullWidth
                          variant="contained"
                          onClick={() => {
                            requestDati();
                          }}
                        >
                          Visualizza
                        </Button>
                        <Button
                          disabled
                          fullWidth
                          variant="contained"
                          // onClick={() => {
                          //   scaricaExcel();
                          // }}
                          style={{
                            margin: 5,
                          }}
                        >
                          Scarica Excel
                        </Button>
                        <Button
                          
                          fullWidth
                          variant="contained"
                          onClick={() => {
                            scaricaExcelFidelityNonLocalizzate();
                          }}
                          style={{
                            margin: 5,
                          }}
                        >
                          Scarica Fidelity non localizzate
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Paper>
              </AccordionDetails>
            </Accordion>
          </Container>
          <Container
            style={{
              height: "91vh",
              width: "97vw",
              zIndex: "-100",
              position: "absolute",
            }}
            maxWidth="xl"
          >
            <MapContainer
              center={position}
              zoom={9}
              style={{
                height: "91vh",
                marginTop: "8vh",
                width: "98vw",
                zIndex: "-100",
                position: "absolute",
              }}
            >
              <LayersControl position="bottomright">
                <BaseLayer checked name="Base">
                  <TileLayer
                    attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                  />
                </BaseLayer>
                <BaseLayer name="Satellite">
                  <TileLayer
                    attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                    url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
                  />
                </BaseLayer>
              </LayersControl>
              <MarkerChangerFunction />
            </MapContainer>
          </Container>
        </div>
      );
    if (tablet || mobile) return <div className={classes.root}> </div>;
  };
  return <> {GeoPosPresenzeRendered()} </>;
}

export default GeoPosPresenze;
