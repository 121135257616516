const columnsIva = [
    
    {
      field: 'codice',
      headerName: 'Codice Iva',
      width: 200,
      editable: false,
      headerAlign: 'center',
      align: 'center'
    },
    {
      field: 'percentuale',
      headerName: 'Aliquota',
      width: 200,
      editable: false,
      type:"number",
      headerAlign: 'center',
      align: 'center'
    },
    {
      field: 'descrizione',
      headerName: 'Descrizione',
      width: 500,
      editable: false,
      type:"number",
      headerAlign: 'center',
      align: 'center'
    },
    {
      field: 'totale',
      headerName: 'Valore di Vendita',
      width: 200,
      editable: false,
      type:"number",
      headerAlign: 'center',
      align: 'right',
      valueFormatter: ({ value }) => Number(value).toLocaleString("it-IT", {minimumFractionDigits: 2}),
    },
    {
      field: 'imponibile',
      headerName: 'Imponibile',
      width: 200,
      editable: false,
      type:"number",
      headerAlign: 'center',
      aalign: 'right',
      valueFormatter: ({ value }) => Number(value).toLocaleString("it-IT", {minimumFractionDigits: 2}),
    },
    {
      field: 'imposta',
      headerName: 'Imposta',
      width: 200,
      editable: false,
      type:"number",
      headerAlign: 'center',
      align: 'right',
      valueFormatter: ({ value }) => Number(value).toLocaleString("it-IT", {minimumFractionDigits: 2}),
    },
    {
      field: 'incidenza',
      headerName: 'Incidenza',
      width: 200,
      type:"number",
      editable: false,
      headerAlign: 'center',
      align: 'right',
      valueGetter: (params) => {
        return `${parseFloat(params.row.incidenza).toFixed(2)}%`;
      }
    }
  ];



export default columnsIva