import React, { useState } from 'react';
import { MenuItem, Select } from '@material-ui/core';
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from '@material-ui/core/OutlinedInput';

function DateMonthPicker(props) {
  const [selectedMonth, setSelectedMonth] = useState('');
  const [selectedDate, setSelectedDate] = useState(new Date());


  const handleChange = (event) => {
    setSelectedMonth(event.target.value);
    // Get the first and last day of the selected month
    const month = event.target.value;
    const firstDay = new Date(selectedDate.getFullYear(), month, 1).toLocaleDateString("it-IT", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric"
  });
    const lastDay = new Date(selectedDate.getFullYear(), month + 1, 0).toLocaleDateString("it-IT", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric"
      });
    // Pass the first and last day to the setState method
    props.setState([firstDay, lastDay]);
  };

  const months = [
    { value: 0, label: 'Gennaio' },
    { value: 1, label: 'Febbraio' },
    { value: 2, label: 'Marzo' },
    { value: 3, label: 'Aprile' },
    { value: 4, label: 'Maggio' },
    { value: 5, label: 'Giugno' },
    { value: 6, label: 'Luglio' },
    { value: 7, label: 'Agosto' },
    { value: 8, label: 'Settembre' },
    { value: 9, label: 'Ottobre' },
    { value: 10, label: 'Novembre' },
    { value: 11, label: 'Dicembre' },
  ];

  return (
    <FormControl variant="outlined" fullWidth>
      <Select
        value={selectedMonth}
        onChange={handleChange}
        displayEmpty
        input={
            <OutlinedInput
              labelWidth={200}
              name="Selezionare il mese"
              id="outlined-age-simple"
            />}
      >
        <MenuItem value="" disabled>
          Seleziona il mese
        </MenuItem>
        {months.map((month) => (
          <MenuItem key={month.value} value={month.value}>
            {month.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export default DateMonthPicker;