const colors = [
    "#F44336",
    "#E91E63",
    "#9C27B0",
    "#673AB7",
    "#3F51B5",
    "#2196F3",
    "#03A9F4",
    "#00BCD4",
    "#009688",
    "#4CAF50",
    "#8BC34A",
    "#CDDC39",
    "#FFEB3B",
    "#FFC107",
    "#FF9800",
    "#FF5722",
    "#795548",
    "#9E9E9E",
    "#607D8B",
    "#000000",
    "#FFFFFF",
    "#CFD8DC",
    "#263238",
    "#37474F",
    "#455A64",
    "#546E7A",
    "#616161",
    "#9E9E9E",
    "#BDBDBD",
    "#E0E0E0",
    "#F5F5F5",
    "#3F51B5",
    "#5677FC",
    "#03A9F4",
    "#4FC3F7",
    "#00BCD4",
    "#81D4FA",
    "#009688",
    "#4DD0E1",
    "#4CAF50",
    "#A5D6A7",
    "#8BC34A",
    "#CDDC39",
    "#DCE775",
    "#FFEB3B",
    "#FFF176",
    "#FFC107",
    "#FFCA28",
    "#FF9800",
    "#FFB74D",
    "#FF5722",
    "#FF8A65",
    "#795548",
    "#A1887F",
    "#E91E63",
    "#F06292",
    "#9C27B0",
    "#BA68C8",
    "#673AB7",
    "#9575CD",
    "#3F51B5",
    "#7986CB",
    "#2196F3",
    "#64B5F6",
    "#F44336",
    "#EF5350",
    "#C62828",
    "#AD1457",
    "#6A1B9A",
    "#4527A0",
    "#283593",
    "#1565C0",
    "#0277BD",
    "#00838F",
    "#2E7D32",
    "#558B2F",
    "#9E9D24",
    "#F9A825",
    "#FF8F00",
    "#EF6C00",
    "#D84315",
    "#4E342E",
    "#424242",
    "#37474F",
    "#263238",
    "#B0BEC5",
    "#78909C",
    "#212121",
    "#FFFFFF",
    "#E0E0E0",
    "#BDBDBD",
    "#9E9E9E",
    "#757575",
    "#3F51B5",
    "#536DFE",
    "#1565C0",
  "#448AFF",
  "#03A9F4",
  "#40C4FF",
  "#00BCD4",
  "#00E5FF",
  "#009688",
  "#1DE9B6",
  "#4CAF50",
  "#76FF03",
  "#8BC34A",
  "#C6FF00",
  "#CDDC39",
  "#E6EE9C",
  "#FFEB3B",
  "#FFF59D",
  "#FFC107",
  "#FFCA28",
  "#FF9800",
  "#FFB74D",
  "#FF5722",
  "#FF6E40",
  "#795548",
  "#A1887F",
  "#E91E63",
  "#F06292",
  "#9C27B0",
  "#BA68C8",
  "#673AB7",
  "#9575CD",
  "#3F51B5",
  "#7986CB"
];

function hexToRGBA(hex, opacity) {
    // Remove the hash character (#) if it exists
    hex = hex.replace("#", "");
  
    // Parse the hexadecimal color values
    const r = parseInt(hex.slice(0, 2), 16);
    const g = parseInt(hex.slice(2, 4), 16);
    const b = parseInt(hex.slice(4, 6), 16);
  
    // Return the RGBA color string
    return `rgba(${r}, ${g}, ${b}, ${opacity})`;
  }

export function getColors(len) {
    return colors.slice(0, len);
}

export function getColor(index, opacity=1) {
    const color = colors[index];
    return hexToRGBA(color, opacity);
}