import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom'
import * as serviceWorker from './serviceWorker';
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { LicenzaProvider } from './Utils/LicenzaContext';
import { purple } from '@mui/material/colors';

const theme = createTheme({
  palette: {
    primary: {
      main: "#00695f",
    },
    secondary: {
      main: '#b26500',
    },
  },
});
ReactDOM.render(
  <BrowserRouter>
    <LicenzaProvider>
    <ThemeProvider theme={theme}>
      <App />
    </ThemeProvider>
    </LicenzaProvider>
  </BrowserRouter>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
serviceWorker.register();
