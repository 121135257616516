import React, { useEffect } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import { itIT } from "@material-ui/data-grid";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
  gridClasses,
} from "@mui/x-data-grid";
import axios from "axios";
import Grid from "@mui/material/Grid";
import "date-fns";
import CustomToolbar from "Utils/Datagrid/CustomToolbar";
import {columnsDettaglioReparto, columnsDettaglioRepartoPerNegozio, columnsDettaglioRepartoPerNegozioArticoli} from "Utils/Datagrid/Reparto/DettaglioReparto";
import NavBar from "Components/NavBar";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Container from "@material-ui/core/Container";
import { useParams } from "react-router";
import { Typography } from "@mui/material";
import { darken, lighten } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import PhoneIcon from '@mui/icons-material/Phone';
import InsertInvitationIcon from '@mui/icons-material/InsertInvitation';
import HouseSidingIcon from '@mui/icons-material/HouseSiding';
import { createContext, useContext } from "react";
import { LicenzaContext } from "Utils/LicenzaContext";



import configData from "CONFIGURAZIONE.json";
var server = configData.api_end_point;

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  container: {
    [theme.breakpoints.up("xl")]: {
      margin: theme.spacing(1),
      padding: theme.spacing(2),
      width: "100vw",
    },
  },
  paperTable: {
    padding: theme.spacing(1),
  },
  dataGrid: {
    height: "59vh",
    width: "70vw",
  },
}));

function DettaglioReparto(props) {
  const classes = useStyles();
  const theme = useTheme();
  const desktop = useMediaQuery(theme.breakpoints.up("lg"));
  const tablet = useMediaQuery(theme.breakpoints.up("sm"));
  const mobile = useMediaQuery(theme.breakpoints.up("xs"));
  const [data, setData] = React.useState(props.data);
  const [dataRepartiNegozio, setDataRepartiNegozio] = React.useState([])
  const [dataRepartiNegozioArticoli, setDataRepartiNegozioArticoli] = React.useState([]);
  const [columnsPerGiorno] = React.useState(columnsDettaglioReparto);
  const [columnsPerNegozio] = React.useState(columnsDettaglioRepartoPerNegozio);
  const [columnsPerNegozioArticoli] = React.useState(columnsDettaglioRepartoPerNegozioArticoli);
  const licenzaContext = useContext(LicenzaContext);
  const [showSpinner, setShowSpinner] = React.useState(false);
  // Storia
  let idCliente = licenzaContext.licenza.id_cliente;
  let utente = licenzaContext.licenza.utente;

  const [tab, setTab] = React.useState(0);

  function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  const handleTabChange = (event, newValue) => {
    setTab(newValue);
  };

  useEffect(()  => {
      // Calcolo incidenza
      console.log(props)
      const discriminanteReparto = props.data[0].Descrizione;
      console.log(discriminanteReparto)
      let informazioniRepartoPerNegozi = [];
      for (const [negozio, informazioniRepartiNegozio] of Object.entries(props.repartiPerNegozio)){
        for (const [reparto, informazioniRepartoNegozio] of Object.entries(informazioniRepartiNegozio)){
          if (informazioniRepartoNegozio.Descrizione == discriminanteReparto){
            console.log(informazioniRepartoNegozio)
            informazioniRepartoPerNegozi.push({
              ...informazioniRepartoNegozio,negozio:negozio
            })
          }
        }
      }
      console.log(informazioniRepartoPerNegozi)
      let totale = informazioniRepartoPerNegozi.reduce((previous, current) => { return parseFloat(previous) + parseFloat(current.Totale)},0)
      for (let i = 0; i < informazioniRepartoPerNegozi.length; i++) {
        informazioniRepartoPerNegozi[i].Incidenza = parseFloat(informazioniRepartoPerNegozi[i].Totale) / totale *100
      }
      console.log(totale);
      setDataRepartiNegozio(informazioniRepartoPerNegozi)
      
      totale = data.reduce((previous, current) => { return parseFloat(previous) + parseFloat(current.Totale)},0)
      for (let i = 0; i < data.length; i++) {
        
        data[i].Incidenza = parseFloat(data[i].Totale) / totale *100
      }
      console.log(props);
      setData(data);
      setShowSpinner(true);
      axios
      .post(`${server}/${idCliente}/negozi/aggregati/reparti/articoli`, getAllFilters())
      .then(function (response) {
        let articoli = [];
        for (const [index, informazioniArticoli] of Object.entries(response.data)){
          articoli.push(informazioniArticoli);
        }
        setShowSpinner(false);
        setDataRepartiNegozioArticoli(articoli);
      });
      }
    ,[]);
  
  const getAllFilters = () => {
    let filter = {};
    try {
      filter = {
        dataDa: props.dataDa,
        dataA: props.dataA,
        puntiVendita: props.puntiVendita,
        reparto: props.codiceReparto,
      };
    } catch {}

    console.log(filter);
    return filter;
  };

    
  const RepartoRendered = (data) => {
    return (
      <div className={classes.root}>
        <Tabs value={tab} onChange={handleTabChange} centered aria-label="icon label tabs example">
          <Tab icon={<InsertInvitationIcon />} label="Analisi per Giorno" />
          <Tab icon={<HouseSidingIcon />} label="Analisi per Punto Vendita" />
          <Tab icon={<PhoneIcon />} label="Analisi per Articolo" />
        </Tabs>
        <Grid
          container
          spacing={2}
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
          
        >
          <TabPanel value={tab} index={0}>
          <DataGrid
              className={classes.dataGrid}
              components={{
                Toolbar: CustomToolbar,
              }}
              getRowId={(row) => row.data}
              rows={data}
              columns={columnsPerGiorno}
              disableSelectionOnClick
              localeText={itIT.props.MuiDataGrid.localeText}
            />
          </TabPanel>
          <TabPanel value={tab} index={1}>
          <DataGrid
              className={classes.dataGrid}
              components={{
                Toolbar: CustomToolbar,
              }}
              getRowId={(row) => row.negozio}
              rows={dataRepartiNegozio}
              columns={columnsPerNegozio}
              disableSelectionOnClick
              localeText={itIT.props.MuiDataGrid.localeText}
            />
          </TabPanel>
          <TabPanel value={tab} index={2}>
          <DataGrid
              className={classes.dataGrid}
              components={{
                Toolbar: CustomToolbar,
              }}
              loading={showSpinner}
              getRowId={(row) => row.codiceArticolo}
              rows={dataRepartiNegozioArticoli}
              columns={columnsPerNegozioArticoli}
              disableSelectionOnClick
              localeText={itIT.props.MuiDataGrid.localeText}
            />
          </TabPanel>
        </Grid>
      </div>
    );
  };

  return <>{RepartoRendered(props.data)}</>;
}

export default DettaglioReparto;
