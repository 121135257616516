import Moment from 'moment';

export const columnsDettaglioReparto = [
    {
      field: 'data',
      headerName: 'Data',
      width: 200,
      editable: false,
      headerAlign: 'center',
      align: 'center',
      type:"date",
      valueFormatter: ({ value }) => Moment(value).format("DD/MM/YYYY"),
    },
    {
      field: 'Articoli',
      headerName: 'Articoli',
      width: 200,
      editable: false,
      type:"number",
      headerAlign: 'center',
      align: 'right'
    },
    {
      field: 'Pezzi',
      headerName: 'Pezzi',
      width: 200,
      editable: false,
      type:"number",
      headerAlign: 'center',
      align: 'right'
    },
    {
      field: 'Presenze',
      headerName: 'Presenze',
      width: 200,
      editable: false,
      type:"number",
      headerAlign: 'center',
      align: 'right'
    },
    {
      field: 'Totale',
      headerName: 'Totale',
      width: 200,
      editable: false,
      type:"number",
      headerAlign: 'center',
      align: 'right',
      valueFormatter: ({ value }) => Number(value).toLocaleString("it-IT", {minimumFractionDigits: 2}),
    },
    {
      field: 'Incidenza',
      headerName: 'Incidenza',
      width: 200,
      type:"number",
      editable: false,
      headerAlign: 'center',
      align: 'right',
      valueGetter: (params) => {
        return `${parseFloat(params.row.Incidenza).toFixed(2)}%`;
      }
    }
  ];

export const columnsDettaglioRepartoPerNegozio = [
    {
      field: 'negozio',
      headerName: 'Punto Vendita',
      width: 200,
      editable: false,
      headerAlign: 'center',
      align: 'center'
    },
    {
      field: 'Articoli',
      headerName: 'Articoli',
      width: 200,
      editable: false,
      type:"number",
      headerAlign: 'center',
      align: 'right'
    },
    {
      field: 'Pezzi',
      headerName: 'Pezzi',
      width: 200,
      editable: false,
      type:"number",
      headerAlign: 'center',
      align: 'right'
    },
    {
      field: 'Presenze',
      headerName: 'Presenze in Media',
      width: 200,
      editable: false,
      type:"number",
      headerAlign: 'center',
      align: 'right'
    },
    {
      field: 'Totale',
      headerName: 'Totale',
      width: 200,
      editable: false,
      type:"number",
      headerAlign: 'center',
      align: 'right',
      valueFormatter: ({ value }) => Number(value).toLocaleString("it-IT", {minimumFractionDigits: 2}),
    },
    {
      field: 'Incidenza',
      headerName: 'Incidenza',
      width: 200,
      type:"number",
      editable: false,
      headerAlign: 'center',
      align: 'right',
      valueGetter: (params) => {
        return `${parseFloat(params.row.Incidenza).toFixed(2)}%`;
      }
    }
  ];
  
export const columnsDettaglioRepartoPerNegozioArticoli = [
  {
    field: 'codiceArticolo',
    headerName: 'Articolo',
    width: 200,
    editable: false,
    headerAlign: 'center',
    align: 'center'
  },
  {
    field: 'descrizione',
    headerName: 'Descrizione',
    width: 200,
    editable: false,
    headerAlign: 'center',
    align: 'center'
  },
  {
    field: 'nr_articoli',
    headerName: 'Nr. Passaggi',
    width: 200,
    editable: false,
    type:"number",
    headerAlign: 'center',
    align: 'right'
  },
  {
    field: 'pezzi',
    headerName: 'Pezzi',
    width: 200,
    editable: false,
    type:"number",
    headerAlign: 'center',
    align: 'right'
  },
  {
    field: 'peso',
    headerName: 'Peso Tot. (Kg)',
    width: 200,
    editable: false,
    type:"number",
    headerAlign: 'center',
    align: 'right'
  },
  {
    field: 'totale',
    headerName: 'Totale (€)',
    width: 200,
    editable: false,
    type:"number",
    headerAlign: 'center',
    align: 'right',
    valueFormatter: ({ value }) => Number(value).toLocaleString("it-IT", {minimumFractionDigits: 2}),
  },
  {
    field: 'peso_medio',
    headerName: 'Peso Medio (Kg)',
    width: 200,
    editable: false,
    type:"number",
    headerAlign: 'center',
    align: 'right'
  },
  {
    field: 'incidenza',
    headerName: 'Incidenza',
    width: 200,
    type:"number",
    editable: false,
    headerAlign: 'center',
    align: 'right',
    valueGetter: (params) => {
      return `${parseFloat(params.row.incidenza).toFixed(2)}%`;
    }
  }
];

