import moment from "moment";

const columnsIncassoOperatore = [
    {
      field: 'data',
      headerName: 'Data',
      type:"date",
      width: 150,
      editable: false,
      headerAlign: 'center',
      align: 'center',
      valueFormatter: ({ value }) => moment(value).format("DD/MM/YYYY"),
    },
    {
        field: 'nr_scontrini',
        headerName: 'Scontrini',
        width: 150,
        type:"number",
        editable: false,
        headerAlign: 'center'
    },
    {
        field: 'scontrino_medio',
        headerName: 'Scontrino Medio',
        width: 200,
        type:"number",
        editable: false,
        headerAlign: 'center'
    },
    {
        field: 'primo_scontrino',
        headerName: 'Primo Scontrino Giornata',
        width: 250,
        type:"dateTime",
        valueFormatter: ({ value }) => moment(value).format("HH:mm"),
        editable: false,
        headerAlign: 'center',
        align: 'center'
    },
    {
        field: 'ultimo_scontrino',
        headerName: 'Ultimo Scontrino Giornata',
        width: 250,
        type:"dateTime",
        valueFormatter: ({ value }) => moment(value).format("HH:mm"),
        editable: false,
        headerAlign: 'center',
        align: 'center'
    },
    {
      field: 'turno_ore',
      headerName: 'Intervallo',
      width: 200,
      editable: false,
      type:"dateTime",
      valueFormatter: ({ value }) => moment(value).format("HH:mm"),
      headerAlign: 'center',
      align: 'center'
    },
    {
      field: 'pezzi_per_scontrino',
      headerName: 'Pezzi per scontrino',
      width: 200,
      type:"number",
      editable: false,
      headerAlign: 'center',
      align: 'right'
    },
    {
        field: 'numero_pezzi',
        headerName: 'Pezzi',
        width: 200,
        type:"number",
        editable: false,
        align: 'right',
        headerAlign: 'center'
      },
      {
        field: 'nr_scontrini_normali',
        headerName: 'Scontrini normali',
        width: 200,
        type:"number",
        editable: false,
        align: 'right',
        headerAlign: 'center'
      },
      {
        field: 'nr_scontrini_abort',
        headerName: 'Abort',
        width: 140,
        type:"number",
        align: 'right',
        editable: false,
        headerAlign: 'center'
      },
      {
        field: 'nr_scontrini_storno_reso',
        headerName: 'Storno/Reso',
        width: 160,
        type:"number",
        editable: false,
        align: 'right',
        headerAlign: 'center'
      },
      {
        field: 'nr_scontrini_resodascontrino',
        headerName: 'Resi da scontrino',
        width: 200,
        type:"number",
        editable: false,
        align: 'right',
        headerAlign: 'center'
      },
      {
        field: 'totale_vendite',
        headerName: 'Totale Vendite',
        width: 200,
        type:"number",
        editable: false,
        headerAlign: 'center',
        align: 'right',
        valueFormatter: ({ value }) => Number(value).toLocaleString("it-IT", {minimumFractionDigits: 2})
      }      
  ];



export default columnsIncassoOperatore