import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import React, { useState, useEffect } from "react";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import axios from "axios";

import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import DettaglioScontrino from "./DettaglioScontrino";

import { Pagination } from "@material-ui/lab";
import { useParams } from "react-router";

import CircularProgress from "@mui/material/CircularProgress";
import NavBar from "Components/NavBar";

import Typography from "@mui/material/Typography";

import Divider from "@mui/material/Divider";
import TextField from "@mui/material/TextField";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

import Slider from "@mui/material/Slider";
import moment from "moment";
import Box from "@mui/material/Box";
// Filtri
import DatePicker from "Components/DatePicker";
import TimePicker from "Components/TimePicker";

import Button from "@mui/material/Button";
import { itIT } from "@material-ui/data-grid";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
  gridClasses,
} from "@mui/x-data-grid";
import columnsScontrino from "Utils/Datagrid/Scontrino/Scontrino";
import { darken, lighten } from "@mui/material/styles";

import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import {CustomFooterComponent} from "Components/CustomFooterComponent.js";
import CustomToolbar from "Utils/Datagrid/CustomToolbar";

import HomeIcon from "@material-ui/icons/Home";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";

import configData from "CONFIGURAZIONE.json";
var server = configData.api_end_point;

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  container: {
    [theme.breakpoints.up("xl")]: {
      margin: theme.spacing(1),
      padding: theme.spacing(2),
      width: "100vw",
    },
  },
  paperTable: {
    padding: theme.spacing(1),
  },
  dataGrid: {
    height: "59vh",
    width: "80vw",
  },
}));

const getBackgroundColor = (color, mode) =>
  mode === "dark" ? darken(color, 0.6) : lighten(color, 0.6);

const getHoverBackgroundColor = (color, mode) =>
  mode === "dark" ? darken(color, 0.5) : lighten(color, 0.5);

function DettaglioFidelity(props) {
  // Stile
  const classes = useStyles();
  const theme = useTheme();
  
  // Media queries per renderizzazione condizionale
  const desktop = useMediaQuery(theme.breakpoints.up("lg"));
  const tablet = useMediaQuery(theme.breakpoints.up("sm"));
  const mobile = useMediaQuery(theme.breakpoints.up("xs"));

  // Stati per aggregazione informazioni in label riassuntiva
  const [totale, setTotale] = React.useState(0);
  const [nrScontrini, setNrScontrini] = React.useState(0);
  const [nrScontriniFidelity, setNrScontriniFidelity] = React.useState(0);
  const [nrMedioArticoliScontrino, setNrMedioArticoliScontrino] = React.useState(0);

  // Stato di verifica apertura dettaglio scontrino
  const [open, setOpen] = React.useState(false);


  
  // 2 Importo
  const [
    filtroIntervalloImportoScontrini,
    setFiltroIntervalloImportoScontrini,
  ] = React.useState([0, 5000]);
  const [IntervalloImportoScontrini, setIntervalloImportoScontrini] =
    React.useState([0, 5000]);
  const handleFiltroIntervalloImportoScontriniChange = (event, newValue) => {
    setFiltroIntervalloImportoScontrini(newValue);
  };

  // 3 Fidelity
  const [statoFiltroFidelity, setStatoFiltroFidelity] = useState(0);
  const [filtroFidelity, setFiltroFidelity] = useState(props.fidelity);

  // 4 Tipologia Scontrino
  const [filtroTipoScontrino, setFiltroTipoScontrino] = useState("Tutti");

  // 5 Numero Scontrino
  const [filtroNumeroScontrino, setFiltroNumeroScontrino] = useState("");

  // 6 Ean articolo
  const [filtroEanArticolo, setFiltroEanArticolo] = useState("");

  // 7 Cassa
  const [filtroNumeroCassa, setFiltroNumeroCassa] = useState("");

  // 8 Tipo Pagamento
  const [filtroTipoPagamento, setFiltroTipoPagamento] = useState("");

  // 9 Operatore
  const [filtroOperatore, setFiltroOperatore] = useState("");
  ////////////////////////////////////////////////////

  // Valorizzazione listbox di selezione negozi
  const [idNegozi, setIdNegozi] = useState([]);


  //////////////////////
  // Informazioni Scontrino
  const [dataScontrino, setDataScontrino] = useState("");
  const [idScontrino, setIdScontrino] = useState("");
  const [nrCassa, setNrCassa] = useState("");
  const [nrOperatore, setNrOperatore] = useState("");
  //////////////////////

  // Cattura evento di click su una righa della datagrid di visualizzazione scontrini
  const handleRowClick = (param) => {
    setNrCassa(param.row.nr_cassa);
    setIdScontrino(param.row.nr_scontrino);
    setDataScontrino(param.row.data_ora_vendita);
    setOpen(true);
  };
  
  // Contenitore scontrini
  const [scontrini, setScontrini] = React.useState([]);

  // Flag di caricamento degli scontrini
  const [loading, isLoading] = React.useState(false);

  // Ricerca scontrini
  useEffect(() => {
    setScontrini([]);
    isLoading(true);
    axios
      .post(
        `${server}/${props.idCliente}/negozi/${props.idNegozio}/scontrini/aggregati/cerca`,
        getAllFilters()
      )
      .then(function (response) {
        console.log(response.data);
        setScontrini(response.data);
        console.log(response.data);
        isLoading(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  },[]);

  const getAllFilters = () => {
    const filter = {
      dataDa: `${props.fromDataDettaglio}, 00:00:00`,
      dataA: `${props.toDataDettaglio}, 23:59:59`,
      fidelity: filtroFidelity,
      totDa: filtroIntervalloImportoScontrini[0],
      totA: filtroIntervalloImportoScontrini[1],
      eanArticolo: filtroEanArticolo,
      nrCassa: "Tutte",
      nrScontrino: filtroNumeroScontrino,
      tipoPagamento: "Tutti",
      tipoScontrino: "Tutti",
      operatore: filtroOperatore,
    };
    return filter;
  };

  const DettaglioFidelityRendered = () => {
    if (desktop | tablet)
      return (
        <div className={classes.root}>
          <Grid item xl={12} md={12}>
            <Paper elevation={6} className={classes.paper}>
              <Box className={classes.dataGrid}
                sx={{
                  "& .super-app-theme--2": {
                    bgcolor: (theme) =>
                      getBackgroundColor(
                        theme.palette.warning.main,
                        theme.palette.mode
                      ),
                    "&:hover": {
                      bgcolor: (theme) =>
                        getHoverBackgroundColor(
                          theme.palette.warning.main,
                          theme.palette.mode
                        ),
                    },
                  },
                  "& .super-app-theme--1": {
                    bgcolor: (theme) =>
                      getBackgroundColor(
                        theme.palette.error.main,
                        theme.palette.mode
                      ),
                    "&:hover": {
                      bgcolor: (theme) =>
                        getHoverBackgroundColor(
                          theme.palette.error.main,
                          theme.palette.mode
                        ),
                    },
                  },
                  "& .super-app-theme--w": {
                    bgcolor: (theme) =>
                      getBackgroundColor(
                        theme.palette.primary.main,
                        theme.palette.mode
                      ),
                    "&:hover": {
                      bgcolor: (theme) =>
                        getHoverBackgroundColor(
                          theme.palette.primary.main,
                          theme.palette.mode
                        ),
                    },
                  },
                }}
              >
                <DataGrid
                  style={{ flexGrow: 1, height: "53vh" }}
                  components={{
                    Toolbar: CustomToolbar,
                  }}
                  rows={scontrini}
                  getRowId={(row) =>
                    `${row.data_ora_vendita},${row.nr_cassa},${row.nr_scontrino}`
                  }
                  columns={columnsScontrino}
                  onRowDoubleClick={(row) => handleRowClick(row)}
                  localeText={itIT.props.MuiDataGrid.localeText}
                  loading={loading}
                  getRowClassName={(params) => {
                    if (params.row.tipo_scontrino === 0){
                      return params.row.fidelities[0] == ""? "" : "super-app-theme--w"
                    }
                    return `super-app-theme--${params.row.tipo_scontrino}`
                    }
                  }
                  onStateChange={(state) => {
                    if (state.isScrolling){
                      return 
                    }
                    let totale =0;
                    let nrScontriniFidelity = 0;
                    let nrScontrini=0;
                    let articoliPerScontrino = 0;
                    console.log(state)
                    if (Object.keys(state.visibleRows.visibleRowsLookup).length !== 0){
                      for (const [key, item] of Object.entries(state.rows.idRowsLookup)) {
                        if (state.visibleRows.visibleRows.includes(key)){
                          totale += item.importo_totale
                          nrScontrini += 1
                          if (item.fidelities[0] !== ""){
                            nrScontriniFidelity +=1
                          }
                          articoliPerScontrino += item.numero_articoli
                        }
                      }
                    }else{
                      for (const [key, item] of Object.entries(state.rows.idRowsLookup)) {
                        totale += item.importo_totale
                          nrScontrini += 1
                          if (item.fidelities[0] !== ""){
                            nrScontriniFidelity +=1
                          }
                          articoliPerScontrino += item.numero_articoli
                      }
                    }
                    setNrMedioArticoliScontrino(articoliPerScontrino/nrScontrini)
                    setTotale(totale)
                    setNrScontrini(nrScontrini)
                    setNrScontriniFidelity(nrScontriniFidelity)
                  }}
                />
              </Box>
            </Paper>
          </Grid>

          <React.Fragment>
            <Dialog open={open} onClose={() => setOpen(false)} maxWidth="xl">
              <DialogContent>
                <DettaglioScontrino
                  idNegozio={props.idNegozio}
                  dataScontrino={dataScontrino}
                  idCassa={nrCassa}
                  idScontrino={idScontrino}
                  idCliente={props.idCliente}
                ></DettaglioScontrino>
              </DialogContent>
            </Dialog>
          </React.Fragment>
        </div>
      );
  };

  return (
    <div>
        {DettaglioFidelityRendered()}
    </div>
  );
}

export default DettaglioFidelity;
