const columnsReparto = [
    
    {
      field: 'descrizione',
      headerName: 'Descrizione',
      width: 400,
      editable: false,
      type:"number",
      headerAlign: 'center',
      align: 'center'
    },
    {
      field: 'presenze',
      headerName: 'Presenze',
      width: 320,
      valueFormatter: ({ value }) => parseInt(value),
      editable: false,
      type:"number",
      headerAlign: 'center',
      align: 'right'
    }
  ];



export default columnsReparto