const columnsTenderPagamento = [
    
    {
      field: 'id',
      headerName: 'Codice Tender Pagamento',
      width: 400,
      editable: false,
      headerAlign: 'center',
      align: 'center'
    },
    {
      field: 'descrizione',
      headerName: 'Descrizione',
      width: 500,
      editable: false,
      headerAlign: 'center',
      align: 'center'
    },
    {
      field: 'Totale',
      headerName: 'Totale',
      width: 300,
      editable: false,
      type:"number",
      headerAlign: 'center',
      align: 'center',
      align: 'right',
      valueFormatter: ({ value }) => Number(value).toLocaleString("it-IT", {minimumFractionDigits: 2}),
    },
    {
      field: 'incidenza',
      headerName: 'Incidenza',
      width: 300,
      type:"number",
      editable: false,
      headerAlign: 'center',
      valueGetter: (params) => {
        return `${parseFloat(params.row.incidenza).toFixed(2)}%`;
      }
    }
  ];



export default columnsTenderPagamento