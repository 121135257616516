const columnsArticoloDettaglio = [ 
  {
    field: 'id_negozio',
    headerName: 'Negozio',
    width: 320,
    editable: false,
    headerAlign: 'center',
    align: 'center'
  },
  {
    field: 'pezzi_vendita',
    headerName: 'Quantità Vendute',
    width: 200,
    editable: false,
    type:"number",
    headerAlign: 'center',
    align: 'right',
    valueFormatter: ({ value }) => parseInt(value),
  },
  {
    field: 'peso_vendita',
    headerName: 'Peso Venduto (Kg)',
    width: 200,
    editable: false,
    type:"number",
    headerAlign: 'center',
    align: 'right',
  },
  {
    field: 'importo_totale',
    headerName: 'Importo Totale',
    width: 250,
    editable: false,
    type:"number",
    headerAlign: 'center',
    align: 'right',
    valueFormatter: ({ value }) => Number(value).toLocaleString("it-IT", {minimumFractionDigits: 2})
  },
  {
    field: 'importo_vendita_Fidelity',
    headerName: 'Importo Fidelity',
    width: 250,
    editable: false,
    type:"number",
    headerAlign: 'center',
    align: 'right',
    valueFormatter: ({ value }) => Number(value).toLocaleString("it-IT", {minimumFractionDigits: 2})
  },
  {
    field: 'importo_vendita_nonFidelity',
    headerName: 'Importo Non Fidelity',
    width: 250,
    editable: false,
    type:"number",
    headerAlign: 'center',
    align: 'right',
    valueFormatter: ({ value }) => Number(value).toLocaleString("it-IT", {minimumFractionDigits: 2})
  },
  {
    field: 'nr_Scontrini',
    headerName: 'Nr. Scontrini',
    width: 250,
    editable: false,
    type:"number",
    valueFormatter: ({ value }) => parseInt(value),
    headerAlign: 'center',
    align: 'right'
  },
  {
    field: 'nr_Scontrini_Fidelity',
    headerName: 'Nr. Scontrini Fidelity',
    width: 200,
    editable: false,
    type:"number",
    valueFormatter: ({ value }) => parseInt(value),
    headerAlign: 'center',
    align: 'right'
  },
  {
    field: 'nr_Scontrini_nonFidelity',
    headerName: 'Nr. Scontrini Non Fidelity',
    width: 300,
    editable: false,
    type:"number",
    valueFormatter: ({ value }) => parseInt(value),
    headerAlign: 'center',
    align: 'right'
  },
  {
    field: 'pezzi_storno',
    headerName: 'Quantità Stornate',
    width: 200,
    editable: false,
    type:"number",
    headerAlign: 'center',
    align: 'right',
    valueFormatter: ({ value }) => parseInt(value),
  },
  {
    field: 'peso_storno',
    headerName: 'Peso Stornato (Kg)',
    width: 200,
    editable: false,
    type:"number",
    headerAlign: 'center',
    align: 'right',
  },
  {
    field: 'importo_storno_Fidelity',
    headerName: 'Importo Storno Fidelity',
    width: 250,
    editable: false,
    type:"number",
    headerAlign: 'center',
    align: 'right',
    valueFormatter: ({ value }) => Number(value).toLocaleString("it-IT", {minimumFractionDigits: 2})
  },
  {
    field: 'importo_storno_nonFidelity',
    headerName: 'Importo Storno Non Fidelity',
    width: 250,
    editable: false,
    type:"number",
    headerAlign: 'center',
    align: 'right',
    valueFormatter: ({ value }) => Number(value).toLocaleString("it-IT", {minimumFractionDigits: 2})
  },
  
  {
    field: 'importo_reso_Fidelity',
    headerName: 'Importo Reso Fidelity',
    width: 250,
    editable: false,
    type:"number",
    headerAlign: 'center',
    align: 'right',
    valueFormatter: ({ value }) => Number(value).toLocaleString("it-IT", {minimumFractionDigits: 2})
  },
  {
    field: 'importo_reso_nonFidelity',
    headerName: 'Importo Reso Non Fidelity',
    width: 250,
    editable: false,
    type:"number",
    headerAlign: 'center',
    align: 'right',
    valueFormatter: ({ value }) => Number(value).toLocaleString("it-IT", {minimumFractionDigits: 2})
  },   
  
];
  


export default columnsArticoloDettaglio