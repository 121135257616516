import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import React, { useState, useEffect } from "react";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import axios from "axios";

import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import { useParams } from "react-router";

import NavBar from "Components/NavBar";
import DateRangePicker from "Components/DateRangePicker";
import Typography from "@mui/material/Typography";
import LoadingSpinner from "Components/LoadingSpinner";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Moment from "moment";
import { extendMoment } from "moment-range";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { itIT } from "@material-ui/data-grid";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
  gridClasses,
} from "@mui/x-data-grid";
import columnsIncassoOperatore from "Utils/Datagrid/Operatore/IncassoOperatore";
import Diversity3Icon from '@mui/icons-material/Diversity3';
// Filtri
import MultipleSelectCheckmarks from "Components/CheckmarkSelect";
import Button from "@mui/material/Button";
import configData from "CONFIGURAZIONE.json";

import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import PhoneIcon from "@material-ui/icons/Phone";
import FavoriteIcon from "@material-ui/icons/Favorite";
import PersonPinIcon from "@material-ui/icons/PersonPin";
import TocIcon from "@material-ui/icons/Toc";
import TimelineIcon from "@material-ui/icons/Timeline";
import { Line, Bar, Pie } from "react-chartjs-2";
import HomeIcon from "@mui/icons-material/Home";
import EuroIcon from "@material-ui/icons/Euro";
import PieChartIcon from "@material-ui/icons/PieChart";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { RestorePageOutlined } from "@material-ui/icons";
import { RouteSharp } from "@mui/icons-material";

import CustomToolbar from "Utils/Datagrid/CustomToolbar";
import { getColors } from "Utils/Funzioni/Grafici";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import TextField from "@mui/material/TextField";
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
ChartJS.register(ArcElement, Tooltip, Legend);

const BreadcrumbsReference = (idCliente) => {
  return [
    {
      label: "HOME",
      href: `/${idCliente}`,
      icon: <HomeIcon fontSize="small" />,
    },
    {
      label: "Operatore",
      href: `#`,
      icon: <PersonSearchIcon fontSize="small" />,
    },
  ];
};

var server = configData.api_end_point;

const moment = extendMoment(Moment);

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  container: {
    margin: theme.spacing(1),
    marginTop: "10vh",
  },
  paper: {
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(2),
    },
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(1),
    },
    flexGrow: 1,
  },
  filterExpansionBar: {
    marginBottom: theme.spacing(1),
  },
  searchbar: {
    padding: theme.spacing(1),
  },
  scontriniGrid: {
    height: "80vh",
  },
  card: {
    [theme.breakpoints.up("md")]: {
      width: "20%",
    },
    [theme.breakpoints.down("md")]: {
      width: "100vw",
      height: "100%",
    },
  },
}));

function IncassoOperatore() {
  const classes = useStyles();
  const theme = useTheme();
  const desktop = useMediaQuery(theme.breakpoints.up("lg"));
  const tablet = useMediaQuery(theme.breakpoints.up("sm"));
  const mobile = useMediaQuery(theme.breakpoints.up("xs"));

  const [open, setOpen] = React.useState(false);
  const firstIndex = 0;
  const [columns, setColumns] = React.useState(columnsIncassoOperatore);
  const [tabSelezionato, setTabSelezionato] = React.useState(0);
  const [data, setData] = React.useState([]);
  const [caricamentoDati, aggiornaCaricamento] = React.useState(false);
  let { idCliente } = useParams();

  const [tenderGiornalieri, setTenderGiornalieri] = React.useState([]);
  const [tenderSpecificoGiornalieri, setTenderSpecificoGiornalieri] =
    React.useState([]);
  ////////////////////////////////////////////////////
  // FILTRI

  // Negozio
  const [filtroOperatori, setFiltroOperatori] = useState();

  const [intervalloGiorni, setIntervalloGiorni] = useState([
    {
      startDate: new Date().setDate(new Date().getDate() - 1),
      endDate: new Date().setDate(new Date().getDate() - 1),
      key: "selection",
    },
  ]);

  ////////////////////////////////////////////////////

  const [idOperatori, setIdOperatori] = useState([]);

  useEffect(() => {
    axios.get(`${server}/${idCliente}/operatori`).then((response) => {
      let operatori = [];
      if (response.status == 200) {
        response.data.forEach((operatore) => {
          operatori.push({
            chiave: operatore.codice,
            valore: operatore.descrizione,
          });
        });
        setIdOperatori(operatori);
        setFiltroOperatori(operatori[0].codice);
      }
    });
  }, []);

  const [fidelityDettaglio, setFidelityDettaglio] = useState("");
  const [idNegozioDettaglio, setIdNegozioDettaglio] = useState("");
  const [fromDataDettaglio, setFromDataDettaglio] = useState("");
  const [toDataDettaglio, setToDataDettaglio] = useState("");
  
  // Cattura evento di click su una righa della datagrid di visualizzazione scontrini
  const handleRowClick = (param,idNegozio) => {
    setFidelityDettaglio(param.row.fidelity);
    setIdNegozioDettaglio(idNegozio);
    setFromDataDettaglio(param.row.data_vendita);
    setToDataDettaglio(param.row.data_vendita);
    setOpen(true);
  };

  //"${server}/scontrini/0255/cercaPerData",
  const requestDati = () => {
    aggiornaCaricamento(true);
    setData({});
    axios
      .post(`${server}/${idCliente}/operatori/vendite/aggregati`, getAllFilters())
      .then(function (response) {
        setData(response.data);
        aggiornaCaricamento(false);
      })
      .catch(function (error) {
        aggiornaCaricamento(false);
        console.log(error);
      });
  };

  const scaricaExcel = () => {
    aggiornaCaricamento(true);
    axios({
      url: `${server}/${idCliente}/operatori/vendite/aggregati/excel`, //your url
      method: "POST",
      responseType: "blob", // important
      data: getAllFilters(),
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      aggiornaCaricamento(false);
      link.setAttribute("download", "report_incassi_operatori.xlsx"); //or any other extension
      document.body.appendChild(link);
      link.click();
    }).catch(function (error) {
      aggiornaCaricamento(false);
      console.log(error);
    });
  };

  const getAllFilters = () => {
    let filter = {};
    try {
      filter = {
        dataDa: `${moment(intervalloGiorni[0].startDate).format(
          "DD/MM/YYYY"
        )}`,
        operatori:filtroOperatori,
        dataA: `${moment(intervalloGiorni[0].endDate).format("DD/MM/YYYY")}`
      };
    } catch {}

    console.log(filter);
    return filter;
  };

  const IncassoOperatoreRendered = () => {
    if (desktop || tablet)
      return (
        <div className={classes.root}>
          <NavBar
            idCliente={idCliente}
            breadcrumbsReference={BreadcrumbsReference(idCliente)}
          />
          <LoadingSpinner open={caricamentoDati} />
          <Container className={classes.container} maxWidth="xl">
            <Accordion style={{ marginTop: "4vh" }} defaultExpanded>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography variant="h6">Barra di Ricerca</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Paper
                  elevation={6}
                  style={{
                    padding: 11,
                    margin: "auto",
                    marginTop: 13,
                    marginBottom: 13,
                    width: "100%",
                  }}
                >
                  <Grid
                    container
                    direction="row"
                    className={classes.searchbar}
                    spacing={1}
                  >
                    <Grid item xl={4} md={4} direction="row">
                      <DateRangePicker
                        state={intervalloGiorni}
                        setState={setIntervalloGiorni}
                      />
                    </Grid>
                    <Grid container item xl={8} md={8}>
                      <Grid item container xl={12} md={12}>
                        <Grid item xl={6} md={6}>
                          <MultipleSelectCheckmarks
                            label={"Selezione Operatore"}
                            selezioneMultipla={true}
                            items={idOperatori}
                            setState={setFiltroOperatori}
                          />
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        item
                        xl={12}
                        md={12}
                        justifyContent="center"
                      >
                        <Button
                          fullWidth
                          variant="contained"
                          onClick={() => {
                            requestDati();
                          }}
                          style={{
                            margin: 5,
                          }}
                        >
                          Visualizza
                        </Button>
                        <Button
                          fullWidth
                          variant="contained"
                          onClick={() => {
                            scaricaExcel();
                          }}
                          style={{
                            margin: 5,
                          }}
                        >
                          Scarica Excel
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Paper>
              </AccordionDetails>
            </Accordion>
              {
                data.length != 0 ?
                
                Object.entries(data).map((operatore, index) => {
                  let dati_operatore = operatore[1];
                  if (!filtroOperatori.includes(operatore[0])){return (<div></div>)};
                  return (
                    <Accordion style={{ marginTop: "4vh" }} defaultExpanded>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography variant="h6">
                          {operatore[0]}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Paper
                          elevation={6}
                          style={{
                            padding: 11,
                            margin: "auto",
                            marginTop: 13,
                            marginBottom: 13,
                            width: "100%",
                          }}
                        >
                          <DataGrid
                            style={{ flexGrow: 1, height: "58vh" }}
                            components={{
                              Toolbar: CustomToolbar,
                            }}
                            getRowId={(row) => row.data}
                            rows={dati_operatore}
                            onRowDoubleClick={(row) => handleRowClick(row, operatore[0])}
                            columns={columns}
                            initialState={{
                              sorting: {
                                sortModel: [{ field: 'data_vendita', sort: 'asc' }],
                              },
                            }}
                            disableSelectionOnClick
                            localeText={itIT.props.MuiDataGrid.localeText}
                            loading={caricamentoDati}
                          />
                        </Paper>
                      </AccordionDetails>
                    </Accordion>
                  );
                })
                : <Paper
                elevation={6}
                style={{
                  padding: 11,
                  margin: "auto",
                  marginTop: 13,
                  marginBottom: 13,
                  width: "100%",
                  justifyItems: "center"
                }}
              ><Typography variant="h6" style={{margin:"auto"}}> Nessun record </Typography></Paper>
              }
          </Container>
        </div>
      );
    if (tablet || mobile) return <div className={classes.root}> </div>;
  };
  return <> {IncassoOperatoreRendered()} </>;
}

export default IncassoOperatore;
