import 'chartjs-plugin-zoom';

export let PresenzeRepartiGiornalieroData = {
    datasets: [],
  };

export let PresenzeRepartiGiornalieroOptions = {
  plugins: {
    zoom: {
      zoom: {
        wheel: {
          enabled: true // SET SCROOL ZOOM TO TRUE
        },
        mode: "y"
      },
      pan: {
        enabled: true,
        mode: "xy"
      }
    }
  },
  responsive: true, 
  maintainAspectRatio: false,  
    scales: {
        xAxes: [{
          stacked: true,
          ticks: {
            beginAtZero: true,
            maxRotation: 0,
            minRotation: 0
          }
        }],
        yAxes: [{
          stacked: true,
        }]
      },
}
