import 'chartjs-plugin-zoom';

export let PieIncassoAggregatoData = {
    labels: ["Completamento"],
    datasets: [
        {
            label: "Raggiunto",
            data: [100],
            backgroundColor: ['#1b5e20'],
        }
    ]
  };

export let PieIncassoAggregatoOptions = {
  responsive: true, 
  maintainAspectRatio: true,  
}

export let LineIncassoAggregatoData = {
    labels: [],
    datasets: []
  };

export let LineIncassoAggregatoOptions = {
  responsive: true, 
  maintainAspectRatio: true,  
}