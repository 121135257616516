const columnsDettaglioScontrino = [
    
    {
      field: 'ven_neg',
      headerName: 'Codice PdV',
      width: 200,
      editable: false,
      hide: true,
      headerAlign: 'center',
    },
    {
      field: 'ven_idx',
      headerName: 'Numero di Sequenza',
      width: 250,
      hide: true,
      editable: false,
      headerAlign: 'center',
      align: 'center'
    },
    {
      field: 'ven_dat',
      headerName: 'Data Movimento',
      width: 250,
      hide: true,
      type: 'date',
      editable: false,
      headerAlign: 'center',
      align: 'center'
    },
    {
      field: 'ven_npo',
      headerName: 'Numero Cassa',
      width: 250,
      editable: false,
      hide: true,
      headerAlign: 'center',
      align: 'center'
    },
    {
      field: 'ven_card',
      headerName: 'Codice Fidelity Card',
      width: 300,
      editable: false,
      hide: true,
      headerAlign: 'center',
      align: 'center'
    },
    {
      field: 'ven_ope',
      headerName: 'Codice Operatore',
      width: 160,
      hide: true,
      editable: false,
      headerAlign: 'center',
      align: 'left'
    },
    {
      field: 'ven_ean',
      headerName: 'Codice EAN',
      width: 160,
      editable: false,
      headerAlign: 'center',
      align: 'right'
    },
    {
      field: 'ven_dsc',
      headerName: 'Descrizione Articolo',
      width: 300,
      headerAlign: 'center',
      align: 'left'
    },
    {
      field: 'ven_cda',
      headerName: 'Codice Interno',
      width: 200,
      type: 'date',
      editable: false,
      hide: true,
      headerAlign: 'center',
      align: 'center'
    },
    {
      field: 'ven_dsl',
      headerName: 'Descrizione Articolo',
      width: 200,
      hide: true,
      editable: false,
      headerAlign: 'center',
      align: 'center'
    },
    {
      field: 'ven_uma',
      headerName: 'Unita` di Vendita',
      width: 200,
      editable: false,
      headerAlign: 'center',
      align: 'center'
    },
    {
      field: 'ven_tpa',
      headerName: 'Tipo Articolo',
      width: 200,
      editable: false,
      hide: true,
      headerAlign: 'center',
      align: 'center'
    },
    {
      field: 'ven_rep',
      headerName: 'Codice Reparto Cassa',
      width: 200,
      editable: false,
      headerAlign: 'center',
      align: 'center'
    },
    {
      field: 'ven_iva',
      headerName: 'Codice IVA',
      width: 200,
      hide: true,
      editable: false,
      headerAlign: 'center',
      align: 'center'
    },
    {
      field: 'ven_ali',
      headerName: 'Valore Aliquota IVA',
      width: 200,
      editable: false,
      headerAlign: 'center',
      align: 'right',
      valueGetter: (params) => {
        if (params.row.ven_ali !== 0){
          return parseInt(params.row.ven_ali * 100 -100)
        }
        return 0
        
      },
    },
    {
      field: 'ven_qta',
      headerName: 'Quantita`',
      width: 200,
      editable: false,
      headerAlign: 'center',
      align: 'right'
    },
    {
      field: 'ven_pes',
      headerName: 'Quantita` in peso',
      width: 200,
      editable: false,
      headerAlign: 'center',
      align: 'right'
    },
    {
      field: 'ven_pru',
      headerName: 'Prezzo Unitario',
      width: 200,
      hide: true,
      editable: false,
      headerAlign: 'center',
      align: 'right',
      valueFormatter: ({ value }) => Number(value).toLocaleString("it-IT", {minimumFractionDigits: 2}),
    },
    {
      field: 'ven_tot',
      headerName: 'Importo di Vendita',
      width: 200,
      editable: false,
      headerAlign: 'center',
      align: 'right',
      valueFormatter: ({ value }) => Number(value).toLocaleString("it-IT", {minimumFractionDigits: 2}),
    },
    {
      field: 'ven_cau',
      headerName: 'Causale del Movimento',
      width: 200,
      editable: false,
      hide: true,
      headerAlign: 'center',
      align: 'center'
    },
    {
      field: 'ven_sgn',
      headerName: 'Segno del Movimento',
      width: 200,
      hide: true,
      editable: false,
      headerAlign: 'center',
      align: 'center'
    },
    {
      field: 'ven_sta',
      headerName: 'Tipo del Movimento',
      width: 200,
      editable: false,
      hide: true,
      headerAlign: 'center',
      align: 'center'
    },
    {
      field: 'ven_cam',
      headerName: 'Codice Campagna',
      width: 200,
      editable: false,
      headerAlign: 'center',
      align: 'center',
      hide: true,
    },
    {
      field: 'ven_pro',
      headerName: 'Codice Promozione',
      width: 200,
      editable: false,
      headerAlign: 'center',
      align: 'center'
    },
    {
      field: 'ven_pnt',
      headerName: 'Numero Punti Erogati',
      width: 200,
      editable: false,
      headerAlign: 'center',
      align: 'right'
    },
    {
      field: 'ven_tps',
      headerName: 'Tipologia di Stampa',
      width: 200,
      editable: false,
      headerAlign: 'center',
      hide: true,
      align: 'center'
    },
    {
      field: 'ven_din',
      headerName: 'Data Inizio Ritiro Cash-back',
      width: 200,
      editable: false,
      headerAlign: 'center',
      hide: true,
      align: 'center',
      type: 'date'
    },
    {
      field: 'ven_dfi',
      headerName: 'Data Fine Ritiro Cash-back',
      width: 200,
      hide: true,
      editable: false,
      type: 'date',
      headerAlign: 'center',
      align: 'center'
    },
    {
      field: 'ven_cbu',
      headerName: 'Codice del Cash-Back',
      width: 200,
      editable: false,
      hide: true,
      headerAlign: 'center',
      align: 'center'
    },
    {
      field: 'ven_eab',
      headerName: 'Codice Ean Bilancia',
      width: 200,
      hide: true,
      editable: false,
      headerAlign: 'center',
      align: 'center'
    },
    {
      field: 'ven_nbi',
      headerName: 'Numero Scontrino Bilancia',
      width: 200,
      editable: false,
      hide: true,
      headerAlign: 'center',
      align: 'center'
    },
    {
      field: 'ven_dbi',
      headerName: 'Data scontrino bilancia',
      width: 200,
      hide: true,
      editable: false,
      headerAlign: 'center',
      align: 'center',
      type: 'date'
    },
    {
      field: 'ven_mat',
      headerName: 'Matricola del registratore fiscale',
      width: 200,
      editable: false,
      headerAlign: 'center',
      hide: true,
      align: 'center'
    },
    {
      field: 'ven_kau',
      hide: true,
      headerName: 'Codice Supervisore',
      width: 200,
      editable: false,
      headerAlign: 'center',
      align: 'center'
    },
    {
      field: 'ven_cma',
      headerName: 'Codice Marchio',
      width: 200,
      hide: true,
      editable: false,
      headerAlign: 'center',
      align: 'center'
    },
    {
      field: 'ven_com',
      headerName: 'Codice Commesso',
      width: 200,
      editable: false,
      hide: true,
      headerAlign: 'center',
      align: 'center'
    },
    {
      field: 'ven_cfi',
      headerName: 'P.IVA / CF',
      width: 200,
      hide: true,
      editable: false,
      headerAlign: 'center',
      align: 'center'
    },
    {
      field: 'ven_som',
      headerName: 'Codice non sommante',
      width: 200,
      editable: false,
      headerAlign: 'center',
      hide: true,
      align: 'center'
    },
    {
      field: 'ven_not',
      headerName: 'Note aggiuntive',
      width: 200,
      editable: false,
      headerAlign: 'center',
      hide: true,
      align: 'center'
    },
    {
      field: 'ven_tpv',
      headerName: 'Acquisizione Codice',
      width: 200,
      editable: false,
      headerAlign: 'center',
      hide: true,
      align: 'center'
    },
    {
      field: 'ven_ser',
      headerName: 'Dati aggiuntivi',
      width: 200,
      editable: false,
      headerAlign: 'center',
      align: 'center',
      hide: true
    },
    {
      field: 'ven_rfidx',
      headerName: 'Indice offerta fidelity',
      width: 200,
      editable: false,
      headerAlign: 'center',
      hide: true,
      align: 'center'
    },
    {
      field: 'ven_fid',
      headerName: 'Offerta fidelity',
      width: 200,
      editable: false,
      hide: true,
      headerAlign: 'center',
      align: 'center'
    },
    {
      field: 'ven_cod_cedi',
      headerName: 'Codice Fornitore/CEDI',
      width: 200,
      editable: false,
      headerAlign: 'center',
      align: 'center'
    },
    {
      field: 'ven_extra',
      headerName: 'Info',
      width: 200,
      hide: true,
      editable: false,
      headerAlign: 'center',
      align: 'center'
    },
    {
      field: 'ven_tim',
      headerName: 'Ora Movimento',
      width: 200,
      type: 'date',
      editable: false,
      headerAlign: 'center',
      align: 'right'
    }
  ];



export default columnsDettaglioScontrino