import Moment from "moment";

const columnsScontrino = [
        {
            field: 'data_ora_vendita',
            headerName: 'Data Scontrino',
            width: 350,
            editable: false,
            type:"dateTime",
            valueFormatter: ({ value }) => Moment(value).format("DD/MM/YYYY HH:mm"),
            headerAlign: 'center',
            align: 'center'
        },
        {
            field: 'nr_cassa',
            headerName: 'Numero Cassa',
            width: 300,
            editable: false,
            type:"number",
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'nr_scontrino',
            headerName: 'Numero Scontrino',
            width: 300,
            editable: false,
            headerAlign: 'center',
            align: 'center'
        },
        {
            field: 'numero_articoli',
            headerName: 'Numero Articoli',
            width: 350,
            editable: false,
            type:"number",
            headerAlign: 'center',
            align: 'right'
        },
        
        {
            field: 'importo_totale',
            headerName: 'Importo Totale',
            width: 300,
            editable: false,
            type:"number",
            headerAlign: 'center',
            align: 'right',
      valueFormatter: ({ value }) => Number(value).toLocaleString("it-IT", {minimumFractionDigits: 2}),
        },
        {
            field: 'tipo_scontrino',
            headerName: 'Tipo',
            width: 300,
            editable: false,
            headerAlign: 'center',
            align: 'center',
            hide: true,
        },
        {
            field: 'fidelities',
            headerName: 'Fidelity',
            width: 350,
            editable: false,
            type:"number",
            headerAlign: 'center',
            align: 'right'
        },
        {
            field: 'operatore',
            headerName: 'Operatore',
            width: 150,
            editable: false,
            type:"number",
            headerAlign: 'center',
            align: 'center'
        },
        
    ];
export default columnsScontrino