const columnsRepartoFasceOrario = [
    
    {
      field: 'inizio_intervallo',
      headerName: 'Intervallo',
      flex: 1,
      editable: false,
      headerAlign: 'center',
      align: 'center',
      valueGetter: (params) => {
        return `${params.row.inizio_intervallo} - ${params.row.fine_intervallo}`;
      },
    },
    {
      field: 'fine_intervallo',
      headerName: 'Fine Intervallo',
      flex: 1,
      editable: false,
      hide: true,
      type:"number",
      headerAlign: 'center',
      align: 'center'
    },
    {
      field: 'importo_totale_reparto',
      headerName: 'Importo Totale',
      flex: 1,
      editable: false,
      type:"number",
      headerAlign: 'center',
      align: 'right',
      valueFormatter: ({ value }) => Number(value).toLocaleString("it-IT", {minimumFractionDigits: 2}),
    },
    {
      field: 'importo_totale_giorno_reparto',
      headerName: 'Importo Medio Giornaliero', 
      flex: 1,
      editable: false,
      type:"number",
      headerAlign: 'center',
      align: 'right',
      valueFormatter: ({ value }) => Number(value).toLocaleString("it-IT", {minimumFractionDigits: 2}),
    },
    {
      field: 'nr_fidelity_reparto',
      headerName: 'Nr. Fidelity', // media
      flex: 1,
      editable: false,
      type:"number",
      headerAlign: 'center',
      align: 'right',
      valueFormatter: ({ value }) => parseInt(value),
    },
    {
      field: 'nr_fidelity_giorno_reparto',
      headerName: 'Nr. Fidelity Giornaliero', // media
      flex: 1,
      editable: false,
      type:"number",
      headerAlign: 'center',
      align: 'right',
      valueFormatter: ({ value }) => parseInt(value),
    },
    {
      field: 'nr_pezzi_reparto',
      headerName: 'Nr. Pezzi', // 
      flex: 1,
      type:"number",
      editable: false,
      headerAlign: 'center',
      align: 'right',
      valueFormatter: ({ value }) => parseInt(value),
    },
    {
      field: 'nr_pezzi_giorno_reparto',
      headerName: 'Nr. Pezzi Giornaliero', // 
      flex: 1,
      type:"number",
      editable: false,
      headerAlign: 'center',
      align: 'right',
      valueFormatter: ({ value }) => parseInt(value),
    },
    {
      field: 'nr_scontrini_reparto',
      headerName: 'Nr. Scontrini',
      flex: 1,
      type:"right",
      editable: false,
      headerAlign: 'center',
      align: 'center'
    },
    {
      field: 'nr_scontrini_giorno_reparto',
      headerName: 'Nr. Scontrini Giornaliero',
      flex: 1,
      type:"number",
      editable: false,
      headerAlign: 'center',
      align: 'right',
      valueFormatter: ({ value }) => parseInt(value),
    }
  ];



export default columnsRepartoFasceOrario