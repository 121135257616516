import React, { useEffect } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import { itIT } from "@material-ui/data-grid";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
  gridClasses,
} from "@mui/x-data-grid";
import axios from "axios";
import Grid from "@mui/material/Grid";
import "date-fns";
import CustomToolbar from "Utils/Datagrid/CustomToolbar";
import columnsDettaglioVenditeFasceOrario from "Utils/Datagrid/Fasce Orario/Vendite/DettaglioVenditeFasceOrario";
import NavBar from "Components/NavBar";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Container from "@material-ui/core/Container";
import { useParams } from "react-router";
import { Typography } from "@mui/material";
import { darken, lighten } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Moment from "moment";
import { extendMoment } from "moment-range";

import configData from "CONFIGURAZIONE.json";
var server = configData.api_end_point;
const moment = extendMoment(Moment);

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  container: {
    [theme.breakpoints.up("xl")]: {
      margin: theme.spacing(1),
      padding: theme.spacing(2),
      width: "100vw",
    },
  },
  paperTable: {
    padding: theme.spacing(1),
  },
  dataGrid: {
    height: "59vh",
    width: "80vw",
  },
}));

function DettaglioVenditeFasceOrario(props) {
  const classes = useStyles();
  const theme = useTheme();
  const desktop = useMediaQuery(theme.breakpoints.up("lg"));
  const tablet = useMediaQuery(theme.breakpoints.up("sm"));
  const mobile = useMediaQuery(theme.breakpoints.up("xs"));
  const [data, setData] = React.useState([]);
  const [columns] = React.useState(columnsDettaglioVenditeFasceOrario);
  const [caricamentoDati, aggiornaCaricamento] = React.useState(false);

  useEffect(()  => {
    aggiornaCaricamento(true);
    setData([]);
    console.log(props)
    const promises = props.negozi.map((negozio) => {
      return axios
          .post(`${server}/${props.idCliente}/negozi/${negozio}/scontrini/intervalli`,
          getAllFilters())
          .then((response) => {
              return response.data;
          }).catch((err) => {
              console.error(err)
          });
    });
    let rows = [];
    Promise.all(promises).then((risultati) => {
      for (const row of risultati){
        console.log(row[0])
        rows.push(row[0]);
      }
      setData(rows);
      aggiornaCaricamento(false);
    })
    
    }
    ,[]);

    const getAllFilters = () => {
      let filter = {}
      try{
        filter = {
          dataDa: `${moment(props.intervalloGiorni[0].startDate).format("DD/MM/YYYY")}`,
          dataA: `${moment(props.intervalloGiorni[0].endDate).format("DD/MM/YYYY")}`,
          oraDa: props.intervallo.inizio,
          oraA: props.intervallo.fine,
          durataIntervallo: props.durataIntervallo.chiave
        };
      }
      catch{
      
      }
      console.log(filter)
      return filter;
    };
  
  const DettaglioVenditeFasceOrarioRendered = () => {
    return (
      <div className={classes.root}>
        <Grid
          container
          spacing={2}
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
          
        >
            <DataGrid
              className={classes.dataGrid}
              components={{
                Toolbar: CustomToolbar,
              }}
              getRowId={(row) => row.id_negozio}
              rows={data}
              columns={columns}
              disableSelectionOnClick
              localeText={itIT.props.MuiDataGrid.localeText}
            />
        </Grid>
      </div>
    );
  };

  return <>{DettaglioVenditeFasceOrarioRendered()}</>;
}

export default DettaglioVenditeFasceOrario;
