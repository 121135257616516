export let IncassoMedioScontriniData = {
  labels: ["1", "2", "3", "4", "5", "6"],
  datasets: [
    {
      label: "Valore Medio Scontrino",
      data: [],
      borderColor: "#76d275",
      backgroundColor: "#43a047",
      yAxisID: "y",
    },
    {
      label: "Numero Scontrini",
      data: [],
      borderColor: "#7e57c2",
      backgroundColor: "#b085f5",
      yAxisID: "y1",
    },
  ],
};
var delayed;
export let IncassoMedioScontriniOptions = {
  responsive: true,
  maintainAspectRatio: false,
  interaction: {
    mode: "index",
    intersect: false,
  },
  stacked: false,
  scales: {
    y: {
      gridLines: {
        display: false,
      },
      ticks: {
        fontColor: "#7e57c2", // this here
      },
      type: "linear",
      display: true,
      position: "left",
    },
    y1: {
      gridLines: {
        color: "#7e57c2",
      },
      type: "linear",
      display: true,
      position: "left",

      // grid line settings
      grid: {
        drawOnChartArea: true, // only want the grid lines for one axis to show up
      },
    },
  },
  animation: {
    onComplete: () => {
      delayed = true;
    },
    delay: (context) => {
      let delay = 0;
      if (context.type === 'data' && context.mode === 'default' && !delayed) {
        delay = context.dataIndex * 100 + context.datasetIndex * 50;
      }
      return delay;
    },
  },
};
