import 'chartjs-plugin-zoom';

export let PieIncassoAggregatoData = {
    labels: [],
    datasets: [
        {
            label: "Incassi",
            data: [],
            backgroundColor: [],
        }
    ]
  };

export let PieIncassoAggregatoOptions = {
  responsive: true, 
  maintainAspectRatio: true,  
}

export let LineIncassoAggregatoData = {
    labels: [],
    datasets: []
  };

export let LineIncassoAggregatoOptions = {
  responsive: true, 
  maintainAspectRatio: true,  
}