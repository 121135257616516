import React, { createContext, useContext, useState } from 'react';

// Definisci il contesto "Licenza"
const LicenzaContext = createContext();

// Provider del contesto
function LicenzaProvider({ children }) {
  // Stato che conterrà il dizionario "licenza"
  const [licenza, setLicenza] = useState({
    id_cliente: 0,
    nome_cliente: "",
    id_licenza: 0,
    moduli: {"Incassi":false},
  });

  return (
    <LicenzaContext.Provider value={{ licenza, setLicenza }}>
      {children}
    </LicenzaContext.Provider>
  );
}

export { LicenzaContext, LicenzaProvider };