import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import React, { useState, useEffect } from "react";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import axios from "axios";

import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import { useParams } from "react-router";

import NavBar from "Components/NavBar";
import DateRangePicker from "Components/DateRangePicker";
import Typography from "@mui/material/Typography";
import LoadingSpinner from "Components/LoadingSpinner";
import Moment from "moment";
import { extendMoment } from "moment-range";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { itIT } from "@material-ui/data-grid";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
  gridClasses,
} from "@mui/x-data-grid";
import columnsArticoloVendutoGiornalieroOperatoreScontrino from "Utils/Datagrid/Articolo/VendutoGiornalieroOperatoreScontrino/Articolo";
import CustomToolbar from "Utils/Datagrid/CustomToolbar";
// Filtri
import MultipleSelectCheckmarks from "Components/CheckmarkSelect";
import Button from "@mui/material/Button";
import configData from "CONFIGURAZIONE.json";

import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import TocIcon from "@material-ui/icons/Toc";
import TimelineIcon from "@material-ui/icons/Timeline";
import { Pie } from "react-chartjs-2";
import { TextField } from "@material-ui/core";
import HomeIcon from "@mui/icons-material/Home";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import KitchenIcon from "@mui/icons-material/Kitchen";
import { PieIncassoAggregatoData } from "Utils/Grafici/Reparto/IncassoAggregato";
import { getColors } from "Utils/Funzioni/Grafici";
import { createContext, useContext } from 'react';
import { LicenzaContext } from 'Utils/LicenzaContext';

import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";

ChartJS.register(ArcElement, Tooltip, Legend);

const BreadcrumbsReference = (idCliente) => {
  return [
    {
      label: "HOME",
      href: `/${idCliente}`,
      icon: <HomeIcon fontSize="small" />,
    },
    {
      label: "Articoli",
      href: `/${idCliente}/Articoli`,
      icon: <KitchenIcon fontSize="small" />,
    },
    {
      label: "Scontrino",
      href: `/${idCliente}/Articoli`,
      icon: <KitchenIcon fontSize="small" />,
    },
  ];
};

var server = configData.api_end_point;
const moment = extendMoment(Moment);

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  container: {
    margin: theme.spacing(1),
    marginTop: "10vh",
  },
  paper: {
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(2),
    },
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(1),
    },
    flexGrow: 1,
  },
  filterExpansionBar: {
    marginBottom: theme.spacing(1),
  },
  searchbar: {
    padding: theme.spacing(1),
  },
  scontriniGrid: {
    height: "80vh",
  },
  card: {
    [theme.breakpoints.up("md")]: {
      width: "20%",
    },
    [theme.breakpoints.down("md")]: {
      width: "100vw",
      height: "100%",
    },
  },
}));

function VendutoGiornalieroOperatoreScontrino() {
  const classes = useStyles();
  const theme = useTheme();
  const desktop = useMediaQuery(theme.breakpoints.up("lg"));
  const tablet = useMediaQuery(theme.breakpoints.up("sm"));
  const mobile = useMediaQuery(theme.breakpoints.up("xs"));

  const firstIndex = 0;
  const [columns, setColumns] = React.useState(columnsArticoloVendutoGiornalieroOperatoreScontrino);
  const [tabSelezionato, setTabSelezionato] = React.useState(0);
  const [data, setData] = React.useState([]);
  const [dataDettaglio, setDataDettaglio] = React.useState([]);
  const [vendutoPerData, setVendutoPerData] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [caricamentoDati, aggiornaCaricamento] = React.useState(false);
  // Stati //
  const licenzaContext = useContext(LicenzaContext);
  // Storia
  let idCliente = licenzaContext.licenza.id_cliente;
  let utente = licenzaContext.licenza.utente;

  const [idOperatori, setIdOperatori] = useState([]);
  ////////////////////////////////////////////////////
  // FILTRI

  // Negozio
  const [filtroNegozi, setFiltroNegozi] = useState([]);

  const [filtroEan, setFiltroEan] = useState("");

  const [filtroCodiceArticolo, setFiltroCodiceArticolo] = useState("");

  const [filtroOperatori, setFiltroOperatori] = useState([]);

  const [intervalloGiorni, setIntervalloGiorni] = useState([
    {
      startDate: new Date().setDate(new Date().getDate() - 1),
      endDate: new Date().setDate(new Date().getDate() - 1),
      key: "selection",
    },
  ]);

  // Intervallo date
  const [filtroIntervalloData, setFiltroIntervalloData] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  ////////////////////////////////////////////////////

  const [idNegozi, setIdNegozi] = useState([]);
  useEffect(() => {
    axios.get(`${server}/${idCliente}/${utente}/negozi`).then((response) => {
      let negozi = [];
      if (response.status == 200) {
        response.data.forEach((negozio) => {
          negozi.push({
            chiave: negozio.id_negozio,
            valore: negozio.nome_negozio,
          });
        });
        setIdNegozi(negozi);
        setFiltroNegozi([negozi[0].id_negozio]);
      }
    });
  }, []);

  useEffect(() => {
    axios.get(`${server}/${idCliente}/operatori`).then((response) => {
      let operatori = [];
      if (response.status == 200) {
        response.data.forEach((operatore) => {
          operatori.push({
            chiave: operatore.codice,
            valore: operatore.descrizione,
          });
        });
        setIdOperatori(operatori);
        setFiltroOperatori(operatori[0].codice);
      }
    });
  }, []);

  //"${server}/scontrini/0255/cercaPerData",
  const requestDati = () => {
    aggiornaCaricamento(true);
    setData([]);
    axios
      .post(`${server}/${idCliente}/articoli/cerca/operatore/scontrino`, getAllFilters())
      .then(function (response) {
        let vendutoGiornaliero = [];
        for (const [index, giorno] of Object.entries(response.data)){
          vendutoGiornaliero.push(giorno);
        }
        console.log(vendutoGiornaliero);
        setData(vendutoGiornaliero);
        aggiornaCaricamento(false);
      })
      .catch(function (error) {
        aggiornaCaricamento(false);
        console.log(error);
      });
  };

  const scaricaExcel = () => {
    aggiornaCaricamento(true);
    axios({
      url: `${server}/${idCliente}/articoli/cerca/operatore/scontrino/xls`, //your url
      method: "POST",
      responseType: "blob", // important
      data: getAllFilters(),
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      aggiornaCaricamento(false);
      link.setAttribute("download", "report.xlsx"); //or any other extension
      document.body.appendChild(link);
      link.click();
    })
    .catch(function (error) {
      aggiornaCaricamento(false);
      console.log(error);
    });
  };

  const getAllFilters = () => {
    let filter = {};
    try {
      filter = {
        dataDa: `${moment(intervalloGiorni[0].startDate).format("DD/MM/YYYY")}`,
        dataA: `${moment(intervalloGiorni[0].endDate).format("DD/MM/YYYY")}`,
        puntiVendita: filtroNegozi,
        eanArticolo: filtroEan,
        codiceArticolo: filtroCodiceArticolo,
        operatore: filtroOperatori
      };
    } catch {}

    console.log(filter);
    return filter;
  };

  const VendutoGiornalieroOperatoreScontrinoRendered = () => {
    if (desktop || tablet)
      return (
        <div className={classes.root}>
          <NavBar
            idCliente={idCliente}
            breadcrumbsReference={BreadcrumbsReference(idCliente)}
          />
          <LoadingSpinner open={caricamentoDati}/>
          <Container className={classes.container} maxWidth="xl">
            <Accordion style={{ marginTop: "4vh" }} defaultExpanded>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography variant="h6">Barra di Ricerca</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Paper
                  elevation={6}
                  style={{
                    padding: 11,
                    margin: "auto",
                    marginTop: 13,
                    marginBottom: 13,
                    width: "100%",
                  }}
                >
                  <Grid
                    container
                    direction="row"
                    className={classes.searchbar}
                    spacing={1}
                  >
                    <Grid item xl={4} md={4} direction="row">
                      <DateRangePicker
                        state={intervalloGiorni}
                        setState={setIntervalloGiorni}
                      />
                    </Grid>
                    <Grid container item xl={8} md={8}>
                      <Grid item xl={6} md={6}>
                        <MultipleSelectCheckmarks
                          label={"Selezione Negozi"}
                          selezioneMultipla={true}
                          items={idNegozi}
                          setState={setFiltroNegozi}
                        />
                      </Grid>
                      <Grid item xl={3} md={3}>
                        <TextField
                          id="outlined-basic"
                          label="Codice Articolo"
                          variant="outlined"
                          value={filtroCodiceArticolo}
                          onChange={(event) => {
                            setFiltroCodiceArticolo(event.target.value);
                          }}
                        />
                      </Grid>
                      <Grid item xl={3} md={3}>
                        <TextField
                          id="outlined-basic"
                          label="EAN"
                          variant="outlined"
                          value={filtroEan}
                          onChange={(event) => {
                            setFiltroEan(event.target.value);
                          }}
                        />
                      </Grid>
                      <Grid item xl={6} md={6}>
                        <MultipleSelectCheckmarks
                            label={"Selezione Operatore"}
                            selezioneMultipla={false}
                            items={idOperatori}
                            setState={setFiltroOperatori}
                          />
                      </Grid>
                      <Grid
                        container
                        item
                        xl={12}
                        md={12}
                        justifyContent="center"
                      >
                        <Button
                          fullWidth
                          variant="contained"
                          onClick={() => {
                            requestDati();
                          }}
                          style={{
                            margin: 5,
                          }}
                        >
                          Visualizza
                        </Button>
                        <Button
                          fullWidth
                          variant="contained"
                          onClick={() => {
                            scaricaExcel();
                          }}
                          
                          style={{
                            margin: 5,
                          }}
                        >
                          Scarica Excel
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Paper>
              </AccordionDetails>
            </Accordion>

            <Paper>
              <DataGrid
                style={{ flexGrow: 1, height: "75vh" }}
                components={{
                  Toolbar: CustomToolbar,
                }}
                getRowId={(row) => `${row.id_negozio}${row.data}${row.ora}`}
                rows={data}
                columns={columns}
                disableSelectionOnClick
                localeText={itIT.props.MuiDataGrid.localeText}
                loading={caricamentoDati}
              />
            </Paper>
          </Container>
        </div>
      );
    if (tablet || mobile) return <div className={classes.root}> </div>;
  };
  return <> {VendutoGiornalieroOperatoreScontrinoRendered()} </>;
}

export default VendutoGiornalieroOperatoreScontrino;
