import Moment from 'moment';

const columnsDettaglioIva = [
    {
      field: 'data',
      headerName: 'Data',
      width: 200,
      editable: false,
      type:"date",
      valueFormatter: ({ value }) => Moment(value).format("DD/MM/YYYY"),
      headerAlign: 'center',
      align: 'center'
    },
    {
      field: 'Venduto',
      headerName: 'Valore di Vendita',
      width: 200,
      editable: false,
      type:"number",
      headerAlign: 'center',
      align: 'right',
      valueFormatter: ({ value }) => Number(value).toLocaleString("it-IT", {minimumFractionDigits: 2}),
    },
    {
      field: 'Imponibile',
      headerName: 'Imponibile',
      width: 200,
      editable: false,
      type:"number",
      headerAlign: 'center',
      align: 'right',
      valueFormatter: ({ value }) => Number(value).toLocaleString("it-IT", {minimumFractionDigits: 2}),
    },
    {
      field: 'Imposta',
      headerName: 'Imposta',
      width: 200,
      editable: false,
      type:"number",
      headerAlign: 'center',
      align: 'right',
      valueFormatter: ({ value }) => Number(value).toLocaleString("it-IT", {minimumFractionDigits: 2}),
    },
    {
      field: 'Incidenza',
      headerName: 'Incidenza',
      width: 200,
      type:"number",
      editable: false,
      headerAlign: 'center',
      align: 'right',
      valueGetter: (params) => {
        return `${parseFloat(params.row.Incidenza).toFixed(2)}%`;
      }
    }
  ];



export default columnsDettaglioIva