import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import React, { useState, useEffect } from "react";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import axios from "axios";
import { createContext, useContext } from 'react';
import { LicenzaContext } from 'Utils/LicenzaContext';

import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import DettaglioScontrino from "./PopUp/DettaglioScontrino";

import { Pagination } from "@material-ui/lab";
import { useParams } from "react-router";

import CircularProgress from "@mui/material/CircularProgress";
import NavBar from "Components/NavBar";

import Typography from "@mui/material/Typography";

import Divider from "@mui/material/Divider";
import TextField from "@mui/material/TextField";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

import Slider from "@mui/material/Slider";
import moment from "moment";
import Box from "@mui/material/Box";
// Filtri
import DatePicker from "Components/DatePicker";
import TimePicker from "Components/TimePicker";

import Button from "@mui/material/Button";
import { itIT } from "@material-ui/data-grid";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
  gridClasses,
} from "@mui/x-data-grid";
import columnsScontrino from "Utils/Datagrid/Scontrino/Scontrino";
import { darken, lighten } from "@mui/material/styles";

import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import {CustomFooterComponent} from "Components/CustomFooterComponent.js";
import CustomToolbar from "Utils/Datagrid/CustomToolbar";

import HomeIcon from "@material-ui/icons/Home";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";

import configData from "CONFIGURAZIONE.json";
var server = configData.api_end_point;

const BreadcrumbsReference = (idCliente) => {
  return [
    {
      label: "HOME",
      href: `/${idCliente}`,
      icon: <HomeIcon fontSize="small" />,
    },
    {
      label: "Scontrini",
      href: `/${idCliente}/Scontrini`,
      icon: <ReceiptLongIcon fontSize="small" />,
    }
  ];
};
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  container: {
    margin: theme.spacing(2),
  },
  paper: {
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(2),
    },
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(1),
    },
    flexGrow: 1,
  },
  searchbar: {
    padding: theme.spacing(1),
  },
}));

const getBackgroundColor = (color, mode) =>
  mode === "dark" ? darken(color, 0.6) : lighten(color, 0.6);

const getHoverBackgroundColor = (color, mode) =>
  mode === "dark" ? darken(color, 0.5) : lighten(color, 0.5);

function Scontrino() {
  // Stile
  const classes = useStyles();
  const theme = useTheme();
  
  // Media queries per renderizzazione condizionale
  const desktop = useMediaQuery(theme.breakpoints.up("lg"));
  const tablet = useMediaQuery(theme.breakpoints.up("sm"));
  const mobile = useMediaQuery(theme.breakpoints.up("xs"));

  // Stati per aggregazione informazioni in label riassuntiva
  const [totale, setTotale] = React.useState(0);
  const [nrScontrini, setNrScontrini] = React.useState(0);
  const [nrScontriniFidelity, setNrScontriniFidelity] = React.useState(0);
  const [nrMedioArticoliScontrino, setNrMedioArticoliScontrino] = React.useState(0);

  // Stato di verifica apertura dettaglio scontrino
  const [open, setOpen] = React.useState(false);

  // Id del cliente che ha richiesto visualizzazione
  // Stati //
  const licenzaContext = useContext(LicenzaContext);
  // Storia
  let idCliente = licenzaContext.licenza.id_cliente;
  let utente = licenzaContext.licenza.utente;

  ////////////////////////////////////////////////////
  // FILTRI

  // 0 Negozio
  const [filtroNegozio, setFiltroNegozio] = useState("");
  const handleFiltroNegozioChange = (value) => {
    setFiltroNegozio(value);
  };

  // 1 Temporali
  // Data Da
  const [filtroDataDa, setFiltroDataDa] = useState(
    moment(new Date()).subtract(1, "days")
  );

  const handleFiltroDataDaChange = (value) => {
    setFiltroDataDa(value);
  };

  // Ora Da
  const [filtroOraDa, setFiltroOraDa] = useState(
    new Date(new Date().setHours(0, 0, 0, 0))
  );

  const handleFiltroOraDaChange = (value) => {
    setFiltroOraDa(value);
  };

  // Data A
  const [filtroDataA, setFiltroDataA] = useState(
    moment(new Date()).subtract(1, "days")
  );
  const handleFiltroDataAChange = (value) => {
    setFiltroDataA(value);
  };

  // Ora Da
  const [filtroOraA, setFiltroOraA] = useState(
    new Date(new Date().setHours(23, 59))
  );
  const handleFiltroOraAChange = (value) => {
    setFiltroOraA(value);
  };

  // 2 Importo
  const [
    filtroIntervalloImportoScontrini,
    setFiltroIntervalloImportoScontrini,
  ] = React.useState([0, 5000]);
  const [IntervalloImportoScontrini, setIntervalloImportoScontrini] =
    React.useState([0, 5000]);
  const handleFiltroIntervalloImportoScontriniChange = (event, newValue) => {
    setFiltroIntervalloImportoScontrini(newValue);
  };

  // 3 Fidelity
  const [statoFiltroFidelity, setStatoFiltroFidelity] = useState(0);
  const [filtroFidelity, setFiltroFidelity] = useState("");

  // 4 Tipologia Scontrino
  const [filtroTipoScontrino, setFiltroTipoScontrino] = useState("Tutti");

  // 5 Numero Scontrino
  const [filtroNumeroScontrino, setFiltroNumeroScontrino] = useState("");

  // 6 Ean articolo
  const [filtroEanArticolo, setFiltroEanArticolo] = useState("");

  // 7 Cassa
  const [filtroNumeroCassa, setFiltroNumeroCassa] = useState("");

  // 8 Tipo Pagamento
  const [filtroTipoPagamento, setFiltroTipoPagamento] = useState("");

  // 9 Operatore
  const [filtroOperatore, setFiltroOperatore] = useState("");
  ////////////////////////////////////////////////////

  // Valorizzazione listbox di selezione negozi
  const [idNegozi, setIdNegozi] = useState([]);
  // Effetto di caricamento negozi in funzione dell idCliente
  useEffect(() => {
    axios.get(`${server}/${idCliente}/${utente}/negozi`).then((response) => {
      let negozi = [];
      if (response.status == 200) {
        response.data.forEach((negozio) => {
          negozi.push({"chiave":negozio.id_negozio, "valore":negozio.nome_negozio});
        });
        setIdNegozi(negozi);
        setFiltroNegozio(negozi[0].chiave);
      }
    });
  }, [idCliente]);

  // Valorizzazione listbox di selezione tipo scontrino
  const [tipiScontrino, setTipiScontrini] = useState([]);
  // Valorizzazione listbox di selezione cassa
  const [idCasse, setIdCasse] = useState([]);
  // Valorizzazione listbox di selezione tipo pagamento
  const [tipiPagamento, setTipiPagamento] = useState([]);

  //////////////////////
  // Informazioni Scontrino
  const [dataScontrino, setDataScontrino] = useState("");
  const [idScontrino, setIdScontrino] = useState("");
  const [nrCassa, setNrCassa] = useState("");
  const [nrOperatore, setNrOperatore] = useState("");
  //////////////////////

  // Cattura evento di click su una righa della datagrid di visualizzazione scontrini
  const handleRowClick = (param) => {
    setNrCassa(param.row.nr_cassa);
    setIdScontrino(param.row.nr_scontrino);
    setDataScontrino(param.row.data_ora_vendita);
    setOpen(true);
  };
  
  // Contenitore scontrini
  const [scontrini, setScontrini] = React.useState([]);

  // Flag di caricamento degli scontrini
  const [loading, isLoading] = React.useState(false);

  // Effetti che risponde al cambio di negozio e carica i nuovi valori per le listbox
  useEffect(() => {
    axios
      .get(`${server}/${idCliente}/negozi/${filtroNegozio}/casse`)
      .then((response) => {
        let casse = [];
        if (response.status == 200) {
          response.data.forEach((cassa) => {
            casse.push(cassa);
          });
          setIdCasse(casse);
          setFiltroNumeroCassa("Tutte");
        }
      });
    axios
      .get(`${server}/${idCliente}/negozi/${filtroNegozio}/tipologiaPagamenti`)
      .then((response) => {
        let pagamenti = [];
        if (response.status == 200) {
          response.data.forEach((pagamento) => {
            pagamenti.push(pagamento);
          });
          setTipiPagamento(pagamenti);
          setFiltroTipoPagamento("Tutti");
        }
      });
    axios
      .get(
        `${server}/${idCliente}/negozi/${filtroNegozio}/scontrini/importoMassimo`
      )
      .then((response) => {
        if (response.status == 200) {
          setIntervalloImportoScontrini([-1000, response.data.importo]);
        }
      });
    axios
      .get(`${server}/${idCliente}/negozi/${filtroNegozio}/tipologiaScontrini`)
      .then((response) => {
        if (response.status == 200) {
          setTipiScontrini(response.data);
        }
      });
  }, [filtroNegozio]);

  // Ricerca scontrini
  const requestScontrini = () => {
    setScontrini([]);
    isLoading(true);
    axios
      .post(
        `${server}/${idCliente}/negozi/${filtroNegozio}/scontrini/aggregati/cerca`,
        getAllFilters()
      )
      .then(function (response) {
        console.log(response.data);
        setScontrini(response.data);
        console.log(response.data);
        isLoading(false);
        let articoliPerScontrino = 0;
        let totale =0;
        let nrScontriniFidelity = 0;
        let nrScontrini=0
        for (const [key, item] of Object.entries(response.data)) {
          totale += item.importo_totale
            nrScontrini += 1
            if (item.fidelities[0] !== ""){
              nrScontriniFidelity +=1
            }
            articoliPerScontrino += item.numero_articoli
        }
        setNrMedioArticoliScontrino(articoliPerScontrino/nrScontrini)
        setTotale(totale)
        setNrScontrini(nrScontrini)
        setNrScontriniFidelity(nrScontriniFidelity)
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getAllFilters = () => {
    const filter = {
      dataDa: `${moment(filtroDataDa).format("YYYY-MM-DD")}, ${moment(
        filtroOraDa
      ).format("HH:mm:ss")}`,
      dataA: `${moment(filtroDataA).format("YYYY-MM-DD")}, ${moment(
        filtroOraA
      ).format("HH:mm:ss")}`,
      fidelity: filtroFidelity,
      totDa: filtroIntervalloImportoScontrini[0],
      totA: filtroIntervalloImportoScontrini[1],
      eanArticolo: filtroEanArticolo,
      nrCassa: filtroNumeroCassa,
      nrScontrino: filtroNumeroScontrino,
      tipoPagamento: filtroTipoPagamento,
      tipoScontrino: filtroTipoScontrino,
      operatore: filtroOperatore,
    };
    return filter;
  };

  const ScontriniRendered = () => {
    if (desktop | tablet)
      return (
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
          spacing={1}
          style={{ marginTop: "6vh", padding: "0.6%" }}
        >
          <Grid item xl={12} md={12}>
            <Paper elevation={6} className={classes.paper}>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={1}
                className={classes.searchbar}
              >
                <Grid
                  container
                  item
                  xl={4}
                  md={4}
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  spacing={1}
                >
                  <Grid
                    container
                    item
                    xl={12}
                    md={12}
                    spacing={1}
                    justifyContent="flex-start"
                    alignItems="center"
                  >
                    <Grid item xl={2} md={2}>
                      Da:
                    </Grid>
                    <Grid item xl={6} md={6}>
                      <DatePicker
                        data={filtroDataDa}
                        handleOnChange={handleFiltroDataDaChange}
                      />
                    </Grid>
                    <Grid item xl={4} md={4}>
                      <TimePicker
                        time={filtroOraDa}
                        handleOnChange={handleFiltroOraDaChange}
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    item
                    xl={12}
                    md={12}
                    spacing={1}
                    justifyContent="flex-start"
                    alignItems="center"
                  >
                    <Grid item xl={2} md={2}>
                      A:
                    </Grid>
                    <Grid item xl={6} md={6}>
                      <DatePicker
                        data={filtroDataA}
                        handleOnChange={handleFiltroDataAChange}
                      />
                    </Grid>
                    <Grid item xl={4} md={4}>
                      <TimePicker
                        time={filtroOraA}
                        handleOnChange={handleFiltroOraAChange}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid
                  container
                  item
                  xl={4}
                  md={4}
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  spacing={1}
                >
                  <Grid item xl={10} md={10}>
                    Intervallo importo scontrino: {`${filtroIntervalloImportoScontrini[0]}€ - ${filtroIntervalloImportoScontrini[1]}€ `}
                  </Grid>
                  <Grid item xl={10} md={10}>
                    <Slider
                      getAriaLabel={() => "Intervallo Totale"}
                      value={filtroIntervalloImportoScontrini}
                      onChange={handleFiltroIntervalloImportoScontriniChange}
                      min={IntervalloImportoScontrini[0]}
                      max={IntervalloImportoScontrini[1]}
                      valueLabelFormat={(value) => `${value}€`}
                      marks={[
                        {
                          value: IntervalloImportoScontrini[0],
                          label: `${IntervalloImportoScontrini[0]}€`,
                        },
                        {
                          value: IntervalloImportoScontrini[1],
                          label: `${IntervalloImportoScontrini[1]}€`,
                        },
                      ]}
                    />
                  </Grid>
                </Grid>

                <Grid
                  container
                  item
                  xl={4}
                  md={4}
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  spacing={1}
                >
                  <Grid
                    container
                    item
                    xl={12}
                    md={12}
                    justifyContent="center"
                    alignItems="center"
                    columnSpacing={6}
                  >
                    <Grid item xl={6} md={6}>
                      <Typography
                        variant="subtitle1"
                        style={{ textAlign: "center", margin: "auto" }}
                      >
                        Ricerca per cassa
                      </Typography>
                    </Grid>
                    <Grid item xl={6} md={6}>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          Cassa
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={filtroNumeroCassa}
                          label="Cassa"
                          onChange={(event) => {
                            setFiltroNumeroCassa(event.target.value);
                          }}
                        >
                          <MenuItem value={"Tutte"}>Tutte</MenuItem>
                          {idCasse.map((cassa) => {
                            return <MenuItem value={cassa}>{cassa}</MenuItem>;
                          })}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    item
                    xl={12}
                    md={12}
                    justifyContent="center"
                    alignItems="center"
                    columnSpacing={6}
                  >
                    <Grid item xl={6} md={6}>
                      <Typography
                        variant="subtitle1"
                        style={{ textAlign: "center", margin: "auto" }}
                      >
                        Ricerca per ean
                      </Typography>
                    </Grid>
                    <Grid item xl={6} md={6}>
                      <TextField
                        id="outlined-basic"
                        label="EAN"
                        variant="outlined"
                        inputProps={{ type: "number" }}
                        value={filtroEanArticolo}
                        onChange={(event) => {
                          setFiltroEanArticolo(event.target.value);
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <Grid
                container
                item
                xl={12}
                md={12}
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={1}
              >
                <Grid
                  container
                  item
                  xl={3}
                  md={3}
                  justifyContent="center"
                  alignItems="center"
                  columnSpacing={2}
                >
                  <Grid container xl={6} md={4}>
                    <Grid item xl={10} md={7}>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          Tipo Filtro Fidelity
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={statoFiltroFidelity}
                          label="Tipo Pagamento"
                          onChange={(event) => {
                            setStatoFiltroFidelity(event.target.value);
                            if (event.target.value == 1) {
                              setFiltroFidelity("TUTTE");
                            } else {
                              setFiltroFidelity("");
                            }
                          }}
                        >
                          <MenuItem default value={0}>
                            Disattivato
                          </MenuItem>
                          <MenuItem value={1}>Solo Fidelity</MenuItem>
                          <MenuItem value={2}>Fidelity Specifica</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid item xl={5} md={7}>
                    <TextField
                      id="outlined-basic"
                      label="Fidelity"
                      variant="outlined"
                      inputProps={{ type: "string" }}
                      value={filtroFidelity}
                      onChange={(event) => {
                        if (statoFiltroFidelity === 1) {
                          setFiltroFidelity("TUTTE");
                        }
                        if (statoFiltroFidelity === 2) {
                          setFiltroFidelity(event.target.value);
                        }
                        if (statoFiltroFidelity === 0) {
                          setFiltroFidelity("");
                        }
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  item
                  xl={3}
                  md={3}
                  justifyContent="center"
                  alignItems="center"
                  spacing={4}
                >
                  <Grid item xl={6} md={4}>
                    <Typography
                      variant="subtitle1"
                      style={{ textAlign: "center", margin: "auto" }}
                    >
                      Tipo scontrino
                    </Typography>
                  </Grid>
                  <Grid item xl={6} md={7}>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        Tipo Scontrino
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={filtroTipoScontrino}
                        label="Tipo Scontrino"
                        onChange={(event) => {
                          setFiltroTipoScontrino(event.target.value);
                        }}
                      >
                        <MenuItem value={"Tutti"}>Tutti</MenuItem>
                        {tipiScontrino.map((tipoScontrino) => {
                          return (
                            <MenuItem value={tipoScontrino.codice}>
                              {tipoScontrino.descrizione}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid
                  container
                  item
                  xl={3}
                  md={3}
                  justifyContent="center"
                  alignItems="center"
                  spacing={4}
                >
                  <Grid item xl={6} md={4}>
                    <Typography
                      variant="subtitle1"
                      style={{ textAlign: "center", margin: "auto" }}
                    >
                      Scontrino
                    </Typography>
                  </Grid>
                  <Grid item xl={6} md={7}>
                    <TextField
                      id="outlined-basic"
                      label="Nr. Scontrino"
                      variant="outlined"
                      inputProps={{ type: "number" }}
                      value={filtroNumeroScontrino}
                      onChange={(event) => {
                        setFiltroNumeroScontrino(event.target.value);
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  item
                  xl={3}
                  md={3}
                  justifyContent="center"
                  alignItems="center"
                  spacing={4}
                >
                  <Grid item xl={6} md={4}>
                    <Typography
                      variant="subtitle1"
                      style={{ textAlign: "center", margin: "auto" }}
                    >
                      Tipo Pagamento
                    </Typography>
                  </Grid>
                  <Grid item xl={6} md={7}>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        Tipo Pagamento
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={filtroTipoPagamento}
                        label="Tipo Pagamento"
                        onChange={(event) => {
                          setFiltroTipoPagamento(event.target.value);
                        }}
                      >
                        <MenuItem value={"Tutti"}>Tutti</MenuItem>
                        {tipiPagamento.map((pagamento) => {
                          return (
                            <MenuItem value={pagamento.codice}>
                              {pagamento.descrizione}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid container item xl={12} md={12} spacing={2}>
                  <Grid item xl={3} md={3}>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        Negozio
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={filtroNegozio}
                        label="Cassa"
                        onChange={(event) => {
                          setFiltroNegozio(event.target.value);
                        }}
                      >
                        {idNegozi.map((negozio) => {
                          return (
                            <MenuItem value={negozio.chiave}>
                              {negozio.valore}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid
                    container
                    item
                    xl={3}
                    md={3}
                    spacing={2}
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Grid item xl={6} md={4}>
                      <Typography
                        variant="subtitle1"
                        style={{ textAlign: "center", margin: "auto" }}
                      >
                        Operatore
                      </Typography>
                    </Grid>
                    <Grid item xl={6} md={7}>
                      <TextField
                        id="outlined-basic"
                        label="Operatore"
                        variant="outlined"
                        inputProps={{ type: "string" }}
                        value={filtroOperatore}
                        onChange={(event) => {
                          setFiltroOperatore(event.target.value);
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid container item xl={6} md={12} justifyContent="center">
                    <Button
                      fullWidth
                      variant="contained"
                      onClick={requestScontrini}
                    >
                      Ricerca
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <Grid item xl={12} md={12}>
            <Typography variant="h6">Totale {Math.round(totale * 100) / 100}€, Numero Scontrini {nrScontrini}, Numero Scontrini Fidelity {nrScontriniFidelity}, Numero Medio Articoli per Scontrino {Math.round(nrMedioArticoliScontrino * 100) / 100}</Typography>
          </Grid>
          <Grid item xl={12} md={12}>
            <Paper elevation={6} className={classes.paper}>
              <Box
                sx={{
                  "& .super-app-theme--3": {
                    bgcolor: (theme) =>
                      getBackgroundColor(
                        theme.palette.secondary.main,
                        theme.palette.mode
                      ),
                    "&:hover": {
                      bgcolor: (theme) =>
                        getHoverBackgroundColor(
                          theme.palette.secondary.main,
                        theme.palette.mode
                        ),
                    },
                  },
                  "& .super-app-theme--2": {
                    bgcolor: (theme) =>
                      getBackgroundColor(
                        theme.palette.warning.main,
                        theme.palette.mode
                      ),
                    "&:hover": {
                      bgcolor: (theme) =>
                        getHoverBackgroundColor(
                          theme.palette.warning.main,
                          theme.palette.mode
                        ),
                    },
                  },
                  "& .super-app-theme--1": {
                    bgcolor: (theme) =>
                      getBackgroundColor(
                        theme.palette.error.main,
                        theme.palette.mode
                      ),
                    "&:hover": {
                      bgcolor: (theme) =>
                        getHoverBackgroundColor(
                          theme.palette.error.main,
                          theme.palette.mode
                        ),
                    },
                  },
                  "& .super-app-theme--negativo": {
                    bgcolor: (theme) =>
                      getBackgroundColor("#ffee58"),
                    "&:hover": {
                      bgcolor: (theme) =>
                      getBackgroundColor("#ffee58"),
                    },
                  },
                  "& .super-app-theme--w": {
                    bgcolor: (theme) =>
                      getBackgroundColor(
                        theme.palette.primary.main,
                        theme.palette.mode
                      ),
                    "&:hover": {
                      bgcolor: (theme) =>
                        getHoverBackgroundColor(
                          theme.palette.primary.main,
                          theme.palette.mode
                        ),
                    },
                  },
                }}
              >
                <DataGrid
                  style={{ flexGrow: 1, height: "53vh" }}
                  components={{
                    Toolbar: CustomToolbar,
                  }}
                  rows={scontrini}
                  getRowId={(row) =>
                    `${row.data_ora_vendita},${row.nr_cassa},${row.nr_scontrino}`
                  }
                  columns={columnsScontrino}
                  onRowDoubleClick={(row) => handleRowClick(row)}
                  localeText={itIT.props.MuiDataGrid.localeText}
                  loading={loading}
                  getRowClassName={(params) => {
                    if (params.row.tipo_scontrino === 0){
                      return params.row.fidelities[0] == ""? "" : "super-app-theme--w"
                    }
                    return `super-app-theme--${params.row.tipo_scontrino}`
                    }
                  }
                  onStateChange={(state) => {
                    if (state.isScrolling){
                      return 
                    }
                    let totale =0;
                    let nrScontriniFidelity = 0;
                    let nrScontrini=0;
                    let articoliPerScontrino = 0;
                    console.log(state)
                    if (Object.keys(state.visibleRows.visibleRowsLookup).length !== 0){
                      for (const [key, item] of Object.entries(state.rows.idRowsLookup)) {
                        if (state.visibleRows.visibleRows.includes(key)){
                          totale += item.importo_totale
                          nrScontrini += 1
                          if (item.fidelities[0] !== ""){
                            nrScontriniFidelity +=1
                          }
                          articoliPerScontrino += item.numero_articoli
                        }
                      }
                    }else{
                      for (const [key, item] of Object.entries(state.rows.idRowsLookup)) {
                        totale += item.importo_totale
                          nrScontrini += 1
                          if (item.fidelities[0] !== ""){
                            nrScontriniFidelity +=1
                          }
                          articoliPerScontrino += item.numero_articoli
                      }
                    }
                    setNrMedioArticoliScontrino(articoliPerScontrino/nrScontrini)
                    setTotale(totale)
                    setNrScontrini(nrScontrini)
                    setNrScontriniFidelity(nrScontriniFidelity)
                  }}
                />
              </Box>
            </Paper>
          </Grid>

          <React.Fragment>
            <Dialog open={open} onClose={() => setOpen(false)} maxWidth="xl">
              <DialogContent>
                <DettaglioScontrino
                  idNegozio={filtroNegozio}
                  dataScontrino={dataScontrino}
                  idCassa={nrCassa}
                  idScontrino={idScontrino}
                  idCliente={idCliente}
                ></DettaglioScontrino>
              </DialogContent>
            </Dialog>
          </React.Fragment>
          <Grid item xl={12} md={12}>
            <Typography variant="h7">Normale</Typography> - 
            <Typography variant="h7" color={theme.palette.primary.main}>Fidelity</Typography> -
            <Typography variant="h7" color="error">Abort</Typography> - 
            <Typography variant="h7" color={theme.palette.warning.main}>Storno e Reso</Typography> - 
            <Typography variant="h7" color={theme.palette.secondary.main}>Reso da Scontrino</Typography> - 
          </Grid>
          
        </Grid>
      );
  };

  return (
    <main className={classes.content}>
      <div className={classes.toolbar} />
      <div className={classes.root}>
        <NavBar idCliente={idCliente} breadcrumbsReference={BreadcrumbsReference(idCliente)}/>
        {ScontriniRendered()}
      </div>
    </main>
  );
}

export default Scontrino;
