import React, { useEffect } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import { itIT } from "@material-ui/data-grid";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
  gridClasses,
} from "@mui/x-data-grid";
import axios from "axios";
import Grid from "@mui/material/Grid";
import "date-fns";
import CustomToolbar from "Utils/Datagrid/CustomToolbar";
import columnsDettaglioIncassoGiornaliero from "Utils/Datagrid/Incasso/DettaglioIncassoGiornaliero";
import NavBar from "Components/NavBar";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Container from "@material-ui/core/Container";
import { useParams } from "react-router";
import { Typography } from "@mui/material";
import { darken, lighten } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import PhoneIcon from '@mui/icons-material/Phone';
import InsertInvitationIcon from '@mui/icons-material/InsertInvitation';
import HouseSidingIcon from '@mui/icons-material/HouseSiding';



import configData from "CONFIGURAZIONE.json";
var server = configData.api_end_point;

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  container: {
    [theme.breakpoints.up("xl")]: {
      margin: theme.spacing(1),
      padding: theme.spacing(2),
      width: "100vw",
    },
  },
  paperTable: {
    padding: theme.spacing(1),
  },
  dataGrid: {
    height: "59vh",
    width: "70vw",
  },
}));

function DettaglioIncassoGiornaliero(props) {
  const classes = useStyles();
  const theme = useTheme();
  const [data, setData] = React.useState(props.data);
  const [columnsPerGiorno] = React.useState(columnsDettaglioIncassoGiornaliero);

  const DettaglioIncassoGiornalieroRendered = (data) => {
    return (
      <div className={classes.root}>
        <Grid
          container
          spacing={2}
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
          
        >
          <DataGrid
              className={classes.dataGrid}
              components={{
                Toolbar: CustomToolbar,
              }}
              getRowId={(row) => row.negozio}
              rows={data}
              columns={columnsPerGiorno}
              disableSelectionOnClick
              localeText={itIT.props.MuiDataGrid.localeText}
            />   
        </Grid>
      </div>
    );
  };

  return <>{DettaglioIncassoGiornalieroRendered(props.data)}</>;
}

export default DettaglioIncassoGiornaliero;
