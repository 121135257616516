import moment from "moment";

const columnsArticoloVendutoGiornalieroOperatoreScontrino = [
    {
      field: 'id_negozio',
      headerName: 'Negozio',
      width: 250,
      editable: false,
      headerAlign: 'center',
      align: 'center'
    },
    {
      field: 'data',
      headerName: 'Data',
      width: 250,
      editable: false,
      headerAlign: 'center',
      type:"date",
      valueFormatter: ({ value }) => moment(value).format("DD/MM/YYYY"),
      align: 'center'
    },
    {
      field: 'cassa',
      headerName: 'Cassa',
      width: 250,
      editable: false,
      headerAlign: 'center',
      align: 'center'
    },
    {
      field: 'nr_scontrino',
      headerName: 'Nr. Scontrino',
      width: 250,
      editable: false,
      type:"number",
      valueFormatter: ({ value }) => parseInt(value),
      headerAlign: 'center',
      align: 'right'
    },
    {
      field: 'ora',
      headerName: 'Ora Scontrino',
      width: 250,
      editable: false,
      headerAlign: 'center',
      align: 'center'
    },
    {
      field: 'numero_pezzi_totale',
      headerName: 'Quantità Vendute',
      width: 200,
      editable: false,
      type:"number",
      headerAlign: 'center',
      align: 'right',
      valueFormatter: ({ value }) => parseInt(value),
    },
    {
      field: 'numero_pezzi_resi',
      headerName: 'Quantità Rese',
      width: 200,
      editable: false,
      type:"number",
      headerAlign: 'center',
      align: 'right',
      valueFormatter: ({ value }) => parseInt(value),
    },
    {
      field: 'numero_pezzi_storni',
      headerName: 'Quantità Stornate',
      width: 200,
      editable: false,
      type:"number",
      headerAlign: 'center',
      align: 'right',
      valueFormatter: ({ value }) => parseInt(value),
    },
    {
      field: 'peso_totale',
      headerName: 'Peso Venduto (Kg)',
      width: 200,
      editable: false,
      type:"number",
      headerAlign: 'center',
      align: 'right',
    },
    {
      field: 'peso_storno',
      headerName: 'Peso Stornato (Kg)',
      width: 200,
      editable: false,
      type:"number",
      headerAlign: 'center',
      align: 'right',
    },
    {
      field: 'peso_reso',
      headerName: 'Peso Reso (Kg)',
      width: 200,
      editable: false,
      type:"number",
      headerAlign: 'center',
      align: 'right',
    },
    {
      field: 'importo',
      headerName: 'Importo',
      width: 250,
      editable: false,
      type:"number",
      headerAlign: 'center',
      align: 'right',
      valueFormatter: ({ value }) => Number(value).toLocaleString("it-IT", {minimumFractionDigits: 2})
    },
    {
      field: 'importo_resi',
      headerName: 'Importo Reso',
      width: 250,
      editable: false,
      type:"number",
      headerAlign: 'center',
      align: 'right',
      valueFormatter: ({ value }) => Number(value).toLocaleString("it-IT", {minimumFractionDigits: 2})
    },
    {
      field: 'importo_storni',
      headerName: 'Importo Storno',
      width: 250,
      editable: false,
      type:"number",
      headerAlign: 'center',
      align: 'right',
      valueFormatter: ({ value }) => Number(value).toLocaleString("it-IT", {minimumFractionDigits: 2})
    },
    {
      field: 'importo_totale_scontrino',
      headerName: 'Importo Scontrino',
      width: 250,
      editable: false,
      type:"number",
      headerAlign: 'center',
      align: 'right',
      valueFormatter: ({ value }) => Number(value).toLocaleString("it-IT", {minimumFractionDigits: 2})
    }
  ];



export default columnsArticoloVendutoGiornalieroOperatoreScontrino