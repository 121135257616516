const columnsCercaArticolo = [ 
  {
    field: 'ean',
    headerName: 'Ean',
    width: 320,
    editable: false,
    headerAlign: 'center',
    align: 'center'
  },
  {
    field: 'cda',
    headerName: 'Codice Articolo',
    width: 200,
    editable: false,
    headerAlign: 'center',
    align: 'center'
  },
  {
    field: 'descrizione',
    headerName: 'Descrizione',
    width: 500,
    editable: false,
    headerAlign: 'center',
    align: 'center'
  }
  
];
  


export default columnsCercaArticolo