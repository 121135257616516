export let ScontriniMediData = {
    labels: ['Acerra', 'Nola', 'Casaluce', 'Casapesenna', 'Caivano', 'San Marcellino', 'Aversa'],
    datasets: [
      {
        type: 'bar',
        stack: "Base",
        label: 'Numero Scontrini',
        backgroundColor: '#01579b',
        data: [30, 31, 32, 33, 34, 35, 36],
        borderColor: 'white'
      },
      {
        type: 'bar',
        stack: "Base",
        label: 'Numero Scontrini Fidelity',
        backgroundColor: 'rgb(75, 192, 192)',
        data: [14, 20, 16, 15, 20, 10, 20],
      },
    ],
  };
var delayed;
export let ScontriniMediOptions = {
  responsive: true, 
  maintainAspectRatio: false,  
    scales: {
        xAxes: [{
          stacked: true,
          ticks: {
            beginAtZero: true,
            maxRotation: 0,
            minRotation: 0
          }
        }],
        yAxes: [{
          stacked: true,
        }]
      },
      animation: {
        onComplete: () => {
          delayed = true;
        },
        delay: (context) => {
          let delay = 0;
          if (context.type === 'data' && context.mode === 'default' && !delayed) {
            delay = context.dataIndex * 100 + context.datasetIndex * 50;
          }
          return delay;
        },
      },
}