import moment from "moment";
var days = ['Domenica','Lunedi','Martedi','Mercoledi','Giovedi','Venerdi','Sabato'];
export let IncassoGiornalieroData = {
    labels: ['1', '2', '3', '4', '5', '6'],
    datasets: [
      {
        label: 'Incasso giornaliero in euro',
        data: [20,30,40,33,33],
        fill: false,
        backgroundColor: '#b3e5fc',
        borderColor: '#01579b',
      },
    ],
  };

const previousY = (ctx) => ctx.index === 0 ? ctx.chart.scales.y.getPixelForValue(75) : ctx.chart.getDatasetMeta(ctx.datasetIndex).data[ctx.index - 1].getProps(['y'], true).y;
const animation = {
  x: {
    type: 'number',
    easing: 'linear',
    duration: 75,
    from: NaN, // the point is initially skipped
    delay(ctx) {
      if (ctx.type !== 'data' || ctx.xStarted) {
        return 0;
      }
      ctx.xStarted = true;
      return ctx.index * 75;
    }
  },
  y: {
    type: 'number',
    easing: 'linear',
    duration: 75,
    from: previousY,
    delay(ctx) {
      if (ctx.type !== 'data' || ctx.yStarted) {
        return 0;
      }
      ctx.yStarted = true;
      return ctx.index * 75;
    }
  }
};

export let IncassoGiornalieroOptions = {
  responsive: true, 
  maintainAspectRatio: false,  
  scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
    
  plugins: {
    tooltip: {
      callbacks: {
        label: function (context) {
          let label = context.dataset.label || "";
          console.log(context)
          var dt = moment(context.label,"YYYY/MM/DD");
          console.log(dt.day())
          var day = days[ dt.day() ];
          if (label) {
            label += ` nel giorno ${day}, di: ${context.parsed.y} euro`;
          }
          
          return label;
        },
      },
    },
  },
  animation
  };